import {ADD_ERROR, REMOVE_ERROR} from '../../actionTypes';

export const addError = error => ({
	type: ADD_ERROR,
	error
});

export const removeError = () => ({
	type: REMOVE_ERROR
});

export const SA_ERROR = error => dispatch => {
	try {
		dispatch(addError(error.data.SpringAhead.ValidationMessage.Message._text));
	} catch(err) {
		try {
			dispatch(addError(error.data.SpringAhead.ErrorResponse.Message._text));
		} catch(err2) {
			try {
				dispatch(addError(error.data.SpringAhead.ErrorResponse.InnerMessage._text));
			} catch(err2) {
				dispatch(addError('Unknown SpringAhead Error. Contact Utilicast IT with a screenshot to help resolve your problem.'));
			}
		}
	}
	
}

export const DB_ERROR = error => dispatch => {
	try {
		dispatch(addError(error.data.originalError.info.message));
	} catch(err) {
		dispatch(addError("Unknown error. Please send a screenshot to the Utilicast IT team."));
	}
}