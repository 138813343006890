import flatten from './flatten';

export function searchList(text, list) {
	return list.filter(function(item) {
		let completed = false;
		let ret = false;
		flatten([item], function(flat) {
			toStr(flat[0], function(str) {
				if(str.toLowerCase().replace(/[^a-z0-9]/g, '').search(text.toLowerCase().replace(/[^a-z0-9]/g, '')) !== -1) ret = true;
				completed = true;
			});
		});
		if(completed) { if(ret) return item }; 
	});
}

function toStr(each, callback) {
	let str = "";
	let x = 0;
	for(var i in each) {
		x++;
		str += each[i] + " ";
	}
	if(Object.keys(each).length === x) callback(str);
}
