import {apiCall} from '../../../services/api';

import {DB_ERROR} from '../utils/errors';
import {hide_panel} from '../utils/panel';

import {GET_SPONSORS, UPDATE_SPONSOR, REMOVE_SPONSOR, GET_SPONSOR_OPTIONS, ADD_SPONSOR} from '../../actionTypes';

// ACTIONS BELOW

export const state_get_sponsors = payload => ({
	type: GET_SPONSORS,
	payload
});

export const state_add_sponsor = payload => ({
	type: ADD_SPONSOR,
	payload
});

export const state_update_sponsor = payload => ({
	type: UPDATE_SPONSOR,
	payload
});

export const state_remove_sponsor = payload => ({
	type: REMOVE_SPONSOR,
	payload
});

export const state_get_sponsor_options = payload => ({
	type: GET_SPONSOR_OPTIONS,
	payload
});

// GET

export const db_get_sponsors = () => dispatch => {
	return apiCall('get', '/api/sponsors')
	.then(function(res) {
		dispatch(state_get_sponsors(res));
	})
	.catch(function(err) {
		dispatch(DB_ERROR(err));
	});
}

export const db_get_am_sponsors = id => dispatch => {
	return apiCall('get', `/api/sponsors/am/${id}`)
	.then(function(res) {
		dispatch(state_get_sponsors(res));
	})
	.catch(function(err) {
		dispatch(DB_ERROR(err));
	});
}

export const db_get_sponsor_archives = () => dispatch =>  {
	return apiCall('get', '/api/sponsors/archives')
	.then(function(res) {
		dispatch(state_get_sponsors(res));
	})
	.catch(function(err) {
		dispatch(DB_ERROR(err));
	});
}

export const db_get_sponsor_dropdown = id => dispatch => {
	return apiCall('get', `/api/dropdowns/sponsors/${id}`)
	.then(function(res) {
		dispatch(state_get_sponsor_options(res));
		return {complete: true};
	})
	.catch(function(err) {
		return {complete: false, error: err.data.error};
	});
}

//PUT

export const db_update_sponsor = sponsor => dispatch => {
	const id = sponsor.ID;
	return apiCall('put', `/api/sponsors/${id}`, sponsor)
	.then(function(res) {
		dispatch(state_update_sponsor(res));
		return {complete: true};
	})
	.catch(function(err) {
		return {complete: false, error: err.data.error};
	});
}

//ADD

export const db_add_sponsor = sponsor => dispatch => {
	return apiCall('post', `/api/sponsors`, sponsor)
	.then(function(res) {
		dispatch(state_add_sponsor(res));
		dispatch(hide_panel());
		return {complete: true};
	})
	.catch(function(err) {
		return {complete: false, error: err.data.error};
	});
}

//ARCHIVES

export const db_archive_sponsor = sponsor => dispatch => {
	return apiCall('put', `/api/archives/sponsor`, sponsor)
	.then(function(res) {
		dispatch(state_remove_sponsor(res));
		return {complete: true};
	})
	.catch(function(err) {
		return {complete: false, error: err.data.error};
	});
}

export const db_delete_sponsor = sponsor => dispatch => {
	const id = sponsor.ID;
	return apiCall('delete', `/api/archives/sponsor/${id}`)
	.then(function(res) {
		dispatch(state_remove_sponsor(sponsor));
		return {complete: true};
	})
	.catch(function(err) {
		return {complete: false, error: err.data.error};
	})
}
