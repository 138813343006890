import {TOGGLE_PANEL, HIDE_PANEL} from '../../actionTypes';

export default (state = false, action) => {
	switch (action.type) {
		case TOGGLE_PANEL:
			return !state;
		case HIDE_PANEL:
			return false;
		default:
			return state;
	}
}