import React, {Component} from 'react';

class HorizontalSelectOption extends Component {
	constructor(props) {
		super(props);
		this.state = {
		}
	}

	render() {
		const {value, selected} = this.props;
		return (
			<div onClick={this.props.onSelect} className={selected === value ? "expenses-type-option selected" : "expenses-type-option"} id={value} style={{...this.props.style}}>{value}</div>
		);
	}
}

export default HorizontalSelectOption;