export const ADD_ERROR = "ADD_ERROR";
export const REMOVE_ERROR = "REMOVE_ERROR";

export const GET_CLIENTS = "GET_CLIENTS";
export const ADD_CLIENT = "ADD_CLIENT";
export const UPDATE_CLIENT = "STATE_UPDATE_CLIENT";
export const REMOVE_CLIENT = "REMOVE_CLIENT";

export const GET_KEYS = "GET_KEYS";
export const REMOVE_KEY = "REMOVE_KEY";

export const GET_CONSULTANTS = "GET_CONSULTANTS";

export const GET_PROFESSIONALS = "GET_PROFESSIONALS";
export const ADD_PROFESSIONAL = "ADD_PROFESSIONAL";
export const UPDATE_PROFESSIONAL = "UPDATE_PROFESSIONAL";
export const REMOVE_PROFESSIONAL = "REMOVE_PROFESSIONAL";

export const GET_PROJECTS = "GET_PROJECTS";
export const ADD_PROJECT = "ADD_PROJECT";
export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const REMOVE_PROJECT = "REMOVE_PROJECT";

export const GET_SPONSORS = "GET_SPONSORS";
export const ADD_SPONSOR = "ADD_SPONSOR";
export const UPDATE_SPONSOR = "UPDATE_SPONSOR";
export const REMOVE_SPONSOR = "REMOVE_SPONSOR";

export const GET_CLIENT_PROJECTS = "GET_CLIENT_PROJECTS";
export const ADD_CLIENT_PROJECT = "ADD_CLIENT_PROJECT";
export const UPDATE_CLIENT_PROJECT = "UPDATE_CLIENT_PROJECT";
export const REMOVE_CLIENT_PROJECT = "REMOVE_CLIENT_PROJECT";

export const TOGGLE_PANEL = "TOGGLE_PANEL";
export const HIDE_PANEL = "HIDE_PANEL";

export const SET_USER = "SET_USER";

export const SET_DB_DATA = "SET_DB_DATA";

export const CHANGE_PAGE = "CHANGE_PAGE";

export const SET_LOADING = "SET_LOADING";

export const GET_CLIENT_OPTIONS = "GET_CLIENT_OPTIONS";
export const GET_SPONSOR_OPTIONS = "GET_SPONSOR_OPTIONS";
export const GET_PROFESSIONAL_OPTIONS = "GET_PROFESSIONAL_OPTIONS";
export const GET_PROJECT_OPTIONS = "GET_PROJECT_OPTIONS";

export const GET_TERMS = "GET_TERMS";
