import {apiCall} from '../../../services/api';
import empty from 'is-empty';

import {UPDATE_CLIENT_PROJECT} from '../../actionTypes';

import {DB_ERROR} from '../utils/errors';
import {sa_add_rep, sa_add_task} from '../../../utils/saSchema';

import {db_return_consultant} from '../tables/consultants';

// ACTIONS BELOW

export const state_update_client_project = currentClientProject => ({
	type: UPDATE_CLIENT_PROJECT,
	currentClientProject
});

//PROJECT REPS

export const sa_get_project_reps = (id) => {
	return apiCall('get', `/api/springahead/project/${id}/reps`)
	.then(function(res) {
		return empty(res.ProjectReps) ? [] : Array.isArray(res.ProjectReps.ProjectRep) ? res.ProjectReps.ProjectRep.map(rep => ({Id: rep.Id._text, Name: rep.User.FirstName._text + " " + rep.User.LastName._text, UserId: rep.User.Id._text, Commission: rep.Commission._text, Type: rep.Type._text, ProjectId: id})) : [{Id: res.ProjectReps.ProjectRep.Id._text, Name: res.ProjectReps.ProjectRep.User.FirstName._text + " " + res.ProjectReps.ProjectRep.User.LastName._text, UserId: res.ProjectReps.ProjectRep.User.Id._text, Commission: res.ProjectReps.ProjectRep.Commission._text, Type: res.ProjectReps.ProjectRep.Type._text, ProjectId: id}];
	})
	.catch(function(err) {
		
	});
}

export const sa_add_project_rep = (data) => {
	return apiCall('put', `/api/springahead/project/${data.ProjectId}/rep`, sa_add_rep(data))
	.then(function(res) {
		return {complete: true, Id: res.ProjectRep.Id._text}
	})
	.catch(function(err) {
		return {complete: false}
	});
}

export const sa_delete_project_rep = (data) => {
	return apiCall('delete', `/api/springahead/project/${data.ProjectId}/rep/${data.Id}`)
	.then(function(res) {
		return {complete: true}
	})
	.catch(function(err) {
		return {complete: false}
	});
}

//PROJECT TASKS

export const sa_get_project_tasks = (id) => {
	return apiCall('get', `/api/springahead/project/${id}/tasks`)
	.then(function(res) {
		return empty(res.ProjectTasks.ProjectTasks) ? 
					[] : Array.isArray(res.ProjectTasks.ProjectTasks.ProjectTask) ? 
					res.ProjectTasks.ProjectTasks.ProjectTask.filter(task => task.Active._text === "true").map(rep => ({Id: rep.Id._text, Name: rep.Task.Name._text, BillRate: rep.Task.BillRate._text, PayRate: rep.Task.PayRate._text, InnerTaskId: rep.Task.Id._text, ...(empty(res.Internal.filter(item => rep.Id._text === item.ID)) ? 
					{} : res.Internal.filter(item => rep.Id._text === item.ID)[0])})) : res.ProjectTasks.ProjectTasks.ProjectTask.Active._text === "true" ? 
					[{Id: res.ProjectTasks.ProjectTasks.ProjectTask.Id._text, Name: res.ProjectTasks.ProjectTasks.ProjectTask.Task.Name._text, BillRate: res.ProjectTasks.ProjectTasks.ProjectTask.Task.BillRate._text, PayRate: res.ProjectTasks.ProjectTasks.ProjectTask.Task.PayRate._text, InnerTaskId: res.ProjectTasks.ProjectTasks.ProjectTask.Task.Id._text, ...(empty(res.Internal.filter(item => res.ProjectTasks.ProjectTasks.ProjectTask.Id._text === item.ID)) ? 
					{} : res.Internal.filter(item => res.ProjectTasks.ProjectTasks.ProjectTask.Id._text === item.ID)[0])}] : [];
	})
	.catch(function(err) {
		console.log(err)
		return [];
	});
}

export const sa_add_project_task = task => {
	return apiCall('put', `/api/springahead/project/${task.ProjectId}/task`, {springahead: sa_add_task(task), ...task})
	.then(function(res) {
		return {complete: true, Id: res.ProjectTask.Id._text, InnerTaskId: res.ProjectTask.Task.Id._text};
	})
	.catch(function(err) {
		try {
			return {complete: false, error: err.data.SpringAhead.ValidationMessage.Message._text};
		} catch(e) {
			return {complete: false, error: "Task not entered"};
		}
	});
}

export const sa_delete_project_task = task => {
	return apiCall('delete', `/api/springahead/project/${task.ProjectId}/task/${task.Id}/${task.InnerTaskId}`)
	.then(function(res) {
		return {complete: true}
	})
	.catch(function(err) {
		return {complete: false, error: "Unable to remove task."}
	});
}

export const sa_archive_project_tasks = tasks => {
	return apiCall('post', `/api/springahead/deactivate/tasks`, {TASKS: tasks})
	.then(function(res) {
		return {complete: true}
	})
	.catch(function(err) {
		return {complete: false, error: "Unable to remove tasks."}
	});
}

// PROJECT MANAGER

export const sa_get_project_manager = (id, callback) => {
	return apiCall('get', `/api/springahead/project/${id}`)
	.then(function(res) {
		callback(res.Project.Manager.Id._text);
	})
	.catch(function(err) {
		
	});
}

export const db_update_project_manager = data => dispatch => {
	return apiCall('put', `/api/client_projects/manager/${data.ID}`, data)
	.then(function(res) {
		dispatch(state_update_client_project({...data, MANAGER: dispatch(db_return_consultant(data.MANAGER_ID))}));
	})
	.catch(function(err) {
		dispatch(DB_ERROR(err));
	});
}

export const db_update_project_client = data => dispatch => {
	return apiCall('put', `/api/client_projects/client/${data.ID}`, data)
	.then(function(res) {
		dispatch(state_update_client_project(data));
	})
	.catch(function(err) {
		dispatch(DB_ERROR(err));
	});
}

//CANDIDATE PROJECTS

export const db_update_candidate_project_manager = data => dispatch => {
	return apiCall('put', `/api/candidate_projects/manager/${data.ID}`, data)
	.then(function(res) {
		dispatch(state_update_client_project(data));
	})
	.catch(function(err) {
		dispatch(DB_ERROR(err));
	});
}

export const db_update_candidate_project_client = data => dispatch => {
	return apiCall('put', `/api/candidate_projects/client/${data.ID}`, data)
	.then(function(res) {
		dispatch(state_update_client_project(data));
	})
	.catch(function(err) {
		dispatch(DB_ERROR(err));
	});
}

//CONFIRM NAMES AREN'T TAKEN

export const sa_confirm_client_name = name => {
	return apiCall('get', `/api/springahead/companies/filter/Name/${encodeURIComponent(name)}`)
	.then(function(res) {
		if(!empty(res.Companies.Company)) {
			if(Array.isArray(res.Companies.Company)) {
				return res.Companies.Company.some(function(client) {
					return client.Name._text.toLowerCase() === name.toLowerCase();
				})
			} else {
				return res.Companies.Company.Name._text.toLowerCase() === name.toLowerCase();
			}
		} else {
			return false;
		}
	})
	.catch(function(err) {
		return false;
	});
}

export const sa_confirm_project_name = (abbrev, name) => {
	return apiCall('get', `/api/springahead/projects/filter/Name/${encodeURIComponent(name)}`)
	.then(function(res) {
		if(!empty(res.Projects.Project)) {
			if(Array.isArray(res.Projects.Project)) {
				return res.Projects.Project.some(function(project) {
					return project.Name._text.toLowerCase() === `${abbrev.toLowerCase()}:${name.toLowerCase()}`;
				})
			} else {
				return res.Projects.Project.Name._text.toLowerCase() === `${abbrev.toLowerCase()}:${name.toLowerCase()}`;
			}
		} else {
			return false;
		}
	})
	.catch(function(err) {
		return false;
	});
}