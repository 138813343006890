import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {db_get_project} from '../../store/actions/tables/projects';
import {db_return_contract} from '../../store/actions/peripherals/contracts';

import ContractEdit from './ContractEdit';
import Loading from '../../components/Graphics/Loading';
import NoAccess from '../../components/Security/NoAccess';

class ContractData extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: {},
			loaded: false,
			allowed: ''
		}
	}

	async componentDidMount() {
		const projectResp = await this.props.db_get_project(this.props.match.params.id);
		if(projectResp.complete) {
			const allow = await this.isAllowed(projectResp.data);
			this.setState({...this.state, loaded: true, allowed: allow, data: projectResp.data});
		} else {
			this.props.history.goBack();
		}
	}

  isAllowed = project => {
  	const {user} = this.props;
		return user.role === "Admin" ? 'yes' : ((project.ACCOUNT_MANAGER.email === user.email) || (project.CONSULTANT.email === user.email)) ? 'yes' : 'no';
  }

	render() {
		return (
			<Fragment>
				{!this.state.loaded && (
					<Loading />
				)}
				{this.state.loaded && (this.state.allowed === "yes") && (
					<ContractEdit role={this.props.role} data={this.state.data} goBack={() => this.props.history.goBack()}/>
				)}
				{this.state.loaded && this.state.allowed === "no" && (
					<NoAccess />
				)}
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		user: state.user
	};
}

export default connect(mapStateToProps, { db_get_project })(withRouter(ContractData));

