import {apiCall} from '../../../services/api';

import {DB_ERROR} from '../utils/errors';

import {GET_KEYS, REMOVE_KEY} from '../../actionTypes';

// ACTIONS BELOW

export const state_get_keys = payload => ({
	type: GET_KEYS,
	payload
});

export const state_remove_key = payload => ({
	type: REMOVE_KEY,
	payload
});

//GET

export const db_get_keys = () => dispatch => {
	return apiCall('get', '/api/keys')
	.then(function(res) {
		dispatch(state_get_keys(res));
	})
	.catch(function(err) {
		dispatch(DB_ERROR(err));
	});
}

//ADD

export const db_add_key = (key) => dispatch => {
	return apiCall('post', `/api/keys`, key)
	.then(function(res) {
		dispatch(db_get_keys());
	})
	.catch(function(err) {
		dispatch(DB_ERROR(err));
	});
}

//DELETE

export const db_delete_key = key => dispatch => {
	return apiCall('delete', `/api/keys/${key.id}`)
	.then(function(res) {
		dispatch(state_remove_key(key));
	})
	.catch(function(err) {
		dispatch(DB_ERROR(err));
	})
}
