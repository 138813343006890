import React, {Component} from 'react';

class ServerDown extends Component {

	render() {
		return (
			<div style={{padding: "1.5rem", height: "100vh", maxWidth: "70rem", display: "flex", flexDirection: "column", margin: "auto", alignItems: "center"}}>
				<h3>The server seems to be down.</h3>
				<h5>It should be back up in a few minutes. If not, contact the Utilicast IT team.</h5>
			</div>
		);
	}
}

export default ServerDown;