import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {Switch} from 'react-router-dom';
import empty from 'is-empty';

import {AppBar, Toolbar, Typography, Button, Grid, InputBase, Hidden, Avatar, Divider, Drawer, MenuList, Popper, MenuItem, Paper, ClickAwayListener, withWidth} from '@material-ui/core';
import {Search, Menu, ExpandMore, ExitToApp, Add, GetApp} from '@material-ui/icons';

import {set_db_data} from '../store/actions/utils/db_data';
import {addBtn} from '../store/actions/utils/addbtn';
import {export_table, user_sa_export, project_sa_export, company_sa_export, documents_export} from '../store/actions/peripherals/exports';
import {removeError} from '../store/actions/utils/errors';

import {authContext} from '../services/auth';

import withToast from '../utils/withToast';

import logo from '../images/logo.jpg';

import ClientData from './Client/ClientData';
import ConsultantData from './Consultant/ConsultantData';
import SponsorData from './Sponsor/SponsorData';
import PayScheduleData from './PaySchedule/PayScheduleData';
import ProjectData from './Project/ProjectData';
import ClientArchives from './Archives/ClientArchives';
import SponsorArchives from './Archives/SponsorArchives';
import ProjectArchives from './Archives/ProjectArchives';
import AssignmentArchives from './Archives/AssignmentArchives';
import Client_ProjectData from './Client_Project/Client_ProjectData';
import KeyData from './Keys/KeyData';
import PrivateRoute from '../components/Security/PrivateRoute';
import ModalLoading from '../components/Graphics/ModalLoading';
import Sidebar from './Sidebar';
import Charts from './Charts/Charts';

class Homepage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dropdown: true,
			archives: false,
			filters: {
				filterConsultants: [],
				filterClients: [],
				filterSow: ''
			},
			logout: false,
			projects: true,
			springahead: false,
			drawer: false,
			error: false,
			loading: false
		}
		this.logoutRef = React.createRef();
	}

	onChange = e => {
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	handleAdd = e => {
		this.props.addBtn();
	}

	handleSearchChange = e => {
		e.preventDefault();
		this.props.set_db_data({...this.props.db_data, search: e.target.value});
	}

	handleArrowChange = e => {
		e.preventDefault();
		this.props.set_db_data({...this.props.db_data, asc: !this.props.db_data.asc});
	}

	handleExport = async () => {
		this.setState({...this.state, loading: true});
		const resp = await this.props.export_table(this.props.page, this.props.page + ' export');
		if(resp.complete) {
			this.props.addToast("Export successful.", {appearance: 'success', autoDismiss: true});
		} else {
			this.props.addToast(resp.error, {appearance: 'error', autoDismiss: true});
		}
		this.setState({...this.state, loading: false});
	}

	handleSpringAheadExport = async (table) => {
		this.setState({...this.state, loading: true});
		if(table === "User") {
			await this.props.user_sa_export();
		} else if(table === "Project") {
			await this.props.project_sa_export()
		} else if(table === "Company") {
			await this.props.company_sa_export()
		} else if(table === "Document") {
			await this.props.documents_export()
		}
		this.setState({...this.state, loading: false});
	}

	render() {
		const {width, user, db_data, page, errors} = this.props;
		const {drawer, dropdown, projects, springahead, archives, filters, logout, loading} = this.state;

		const small = width === "xs" || width === "sm";

		return (
			<Fragment>
				{loading && (<ModalLoading />)}
				<AppBar color="inherit" position="relative" style={{height: small ? "9rem" : "64px", padding: small ? "1rem 0.5rem" : ""}}>
					<Toolbar disableGutters>
						<Grid container direction="column" style={{padding: "0 1rem"}}>
							<Grid container item justify="space-between" alignItems="center">
								<Grid container item justify="space-between" alignItems="center" xs={3} sm={2}>
							    <a href="https://app.utilicast.com/"><img src={logo} alt="Utilicast" style={{maxWidth: "90%", cursor: "pointer", marginBottom: "0.5rem"}}/></a>
								</Grid>
								<Grid container item xs={9} sm={10} justify="space-between" alignItems="center">
									<Hidden xsDown><Typography variant="h5">{db_data.title}</Typography></Hidden>
									<Hidden smDown>
										<Grid container item md={4} alignItems="center" justify="space-between" style={{backgroundColor: "#ededed", borderRadius: "6px", margin: "auto"}}>
					            {db_data.search_btn && (<Fragment><Search style={{width: "7%"}}/>
				            	<InputBase
					              placeholder="Search…"
					              style={{width: "93%"}}
					              type="text"
					              name="search"
					              value={db_data.search}
					              onChange={this.handleSearchChange}
					            /></Fragment>)}
					          </Grid>
				          </Hidden>
									<Grid container item xs={12} sm={7} md={5} justify="flex-end" alignItems="center">
										<Hidden xsDown><Button style={{minWidth: "0", marginRight: "0.5rem"}}><a href="https://app.utilicast.com" title="Home" style={{color: "inherit"}}>Home</a></Button></Hidden>
										{db_data.export_btn && (<Button title="Export" variant="contained" color="primary" style={{minWidth: "0", width: "2rem", height: "2rem", padding: "0", marginRight: "1rem", color: "white", borderRadius: "50%"}} onClick={this.handleExport}>
											<GetApp />
										</Button>)}
										{db_data.sort_btn && (<Button title="Sort" variant="contained" color="primary" style={{minWidth: "0", width: "2rem", height: "2rem", padding: "0", marginRight: "1rem", color: "white", borderRadius: "50%"}} onClick={this.handleArrowChange}>
											<svg className={db_data.asc ? "db-sort-button sort-true" : "db-sort-button"} xmlns="http://www.w3.org/2000/svg" width="8.5" height="8.5" viewBox="0 0 8 8">
											  <path d="M2.97 0l-2.97 3h2v5h2v-5h2l-3.03-3z" transform="translate(1)" />
											</svg>
											<svg className={!db_data.asc ? "db-sort-button sort-true" : "db-sort-button"} xmlns="http://www.w3.org/2000/svg" width="8.5" height="8.5" viewBox="0 0 8 8">
											  <path d="M2 0v5h-2l3.03 3 2.97-3h-2v-5h-2z" transform="translate(1)" />
											</svg>
										</Button>)}
										{db_data.add_btn && (<Button title="Create" variant="contained" color="secondary" style={{minWidth: "0", width: "2rem", height: "2rem", padding: "0", marginRight: "1rem", color: "white", borderRadius: "50%"}} onClick={this.handleAdd}>
											<Add />
										</Button>)}
										<Divider orientation="vertical" flexItem style={{margin: "0 0.15rem"}}/>
										<Button color="inherit" style={{minWidth: "0", textTransform: "none"}}>
											<Grid container justify="space-between" alignItems="center" ref={this.logoutRef} aria-controls={logout ? 'menu-list-grow' : undefined} aria-haspopup="true" onClick={() => this.setState({...this.state, logout: !logout})}>
												<Avatar alt="Profile" src={user.pic} style={{height: "2.5rem", width: "2.5rem", objectFit: "cover", margin: "0 0.5rem 0 0.5rem"}}/>
												<Typography style={{padding: "0 0.5rem"}}>{user.name}</Typography>
												<ExpandMore/>
											</Grid>
										</Button>
										<Popper open={logout} anchorEl={this.logoutRef.current} role={undefined} transition disablePortal>
				              <Paper style={{width: "100%", marginTop: "1rem"}}>
				                <ClickAwayListener onClickAway={() => this.setState({...this.state, logout: false})}>
				                  <MenuList id="menu-list-grow" style={{padding: "0.25rem 0"}}>
				                    <MenuItem onClick={() => authContext.logOut()}><ExitToApp style={{marginRight: "0.5rem"}}/>Logout</MenuItem>
				                  </MenuList>
				                </ClickAwayListener>
				              </Paper>
						        </Popper>
									</Grid>
								</Grid>
							</Grid>
							<Hidden mdUp>
								<Grid container alignItems="center" justify="space-between" style={{marginTop: "0.5rem"}}>
									<Button color="inherit" xs={3} style={{minWidth: "0"}} onClick={() => this.setState({...this.state, drawer: !drawer})}><Menu /></Button>
									<Grid container item xs={8} sm={6} alignItems="center" justify="space-between" style={{backgroundColor: "#ededed", borderRadius: "6px", marginRight: "6px"}}>
				            {db_data.search_btn && (<Fragment><Search style={{width: "10%"}}/>
			            	<InputBase
				              placeholder="Search…"
				              style={{width: "90%"}}
				              type="text"
				              name="search"
				              value={db_data.search}
				              onChange={this.handleSearchChange}
				            /></Fragment>)}
				          </Grid>
								</Grid>
							</Hidden>
						</Grid>
				  </Toolbar>
				</AppBar>
				<Grid container item xs={12} style={{height: width === "xs" || width === "sm" ? "calc(100vh - 9rem)" : "calc(100vh - 64px)"}}>
					<div className="db-background-list"></div>
					<Hidden smDown>
						<Sidebar
							user={user}
							db_data={db_data}
							page={page}
							dropdown={dropdown}
							projects={projects}
							springahead={springahead}
							archives={archives}
							logout={logout}
							setDropdown={() => {this.setState({...this.state, dropdown: !dropdown})}}
							setProject={() => {this.setState({...this.state, projects: !projects})}}
							setArchive={() => {this.setState({...this.state, archives: !archives})}}
							setSpringahead={() => {this.setState({...this.state, springahead: !springahead})}}
							user_sa_export={() => this.handleSpringAheadExport("User")}
							project_sa_export={() => this.handleSpringAheadExport("Project")}
							company_sa_export={() => this.handleSpringAheadExport("Company")}
							documents_export={() => this.handleSpringAheadExport("Document")}
						/>
					</Hidden>
					<Hidden mdUp>
						<Drawer open={drawer} anchor="left" onClose={() => this.setState({...this.state, drawer: !drawer})}>
							<Sidebar
								user={user}
								db_data={db_data}
								page={page}
								dropdown={dropdown}
								projects={projects}
								springahead={springahead}
								archives={archives}
								logout={logout}
								setDropdown={() => {this.setState({...this.state, dropdown: !dropdown})}}
								setProject={() => {this.setState({...this.state, projects: !projects})}}
								setArchive={() => {this.setState({...this.state, archives: !archives})}}
								setSpringahead={() => {this.setState({...this.state, springahead: !springahead})}}
								user_sa_export={() => this.handleSpringAheadExport("User")}
								project_sa_export={() => this.handleSpringAheadExport("Project")}
								company_sa_export={() => this.handleSpringAheadExport("Company")}
								documents_export={() => this.handleSpringAheadExport("Document")}
							/>
						</Drawer>
					</Hidden>
					{!empty(errors) && (
						<Grid xs={12} md={10} container item justify="space-between" alignItems="center" style={{position: "absolute", background: "#d9534f", padding: "1rem 2rem", zIndex: "1000", width: "100%", right: "0"}}>
							<Grid xs={11} container item><Typography variant="h6" style={{color: "white", width: "100%", overflow: "hidden"}}>{errors}</Typography></Grid>
							<Grid xs={1} container item justify="flex-end"><Typography variant="h4" style={{color: "white", cursor: "pointer"}} onClick={() => this.props.removeError()}>x</Typography></Grid>
						</Grid>
					)}
					<Grid container item xs={12} md={10}>
						<Switch>
							<PrivateRoute exact path={`${this.props.match.url}/`} component={ProjectData} role="Consultant" filters={filters} allowedRole="Consultant"/>
							<PrivateRoute exact path={`${this.props.match.url}/clients`} component={ClientData} role="Consultant" filters={filters} allowedRole="Consultant"/>
							<PrivateRoute exact path={`${this.props.match.url}/sponsors`} component={SponsorData} role="Consultant" filters={filters} allowedRole="Consultant"/>
							<PrivateRoute exact path={`${this.props.match.url}/assignments`} component={ProjectData} role="Reports" filters={filters} allowedRole="Consultant"/>
							<PrivateRoute exact path={`${this.props.match.url}/projects/active`} component={Client_ProjectData} role="Consultant" filters={filters} allowedRole="Consultant"/>
							<PrivateRoute exact path={`${this.props.match.url}/projects/leads`} component={Client_ProjectData} role="Consultant" filters={filters} allowedRole="Consultant" candidate leads/>
							<PrivateRoute exact path={`${this.props.match.url}/projects/leads/submitted`} component={Client_ProjectData} role="Consultant" filters={filters} allowedRole="Consultant" candidate submittedleads/>
							<PrivateRoute exact path={`${this.props.match.url}/projects/candidate`} component={Client_ProjectData} role="Consultant" filters={filters} allowedRole="Consultant" candidate leads={false}/>
							<PrivateRoute exact path={`${this.props.match.url}/charts`} component={Charts} role="Consultant" allowedRole="Consultant"/>
							<PrivateRoute exact path={`${this.props.match.url}/admin/clients`} component={ClientData} role="Admin" allowedRole="Admin" />
							<PrivateRoute exact path={`${this.props.match.url}/admin/consultants`} component={ConsultantData} role="Admin" allowedRole="Admin"/>
							<PrivateRoute exact path={`${this.props.match.url}/admin/sponsors`} component={SponsorData} role="Admin" allowedRole="Admin" />
							<PrivateRoute exact path={`${this.props.match.url}/admin/payschedule`} component={PayScheduleData} role="Admin" allowedRole="Admin" />
							<PrivateRoute exact path={`${this.props.match.url}/admin/projects/candidate`} component={Client_ProjectData} role="Admin" filters={filters} allowedRole="Admin" candidate/>
							<PrivateRoute exact path={`${this.props.match.url}/admin/projects/active`} component={Client_ProjectData} role="Admin" filters={filters} allowedRole="Admin" />
							<PrivateRoute exact path={`${this.props.match.url}/admin/assignments`} component={ProjectData} role="Admin" filters={filters} allowedRole="Admin" />
							<PrivateRoute exact path={`${this.props.match.url}/admin/internalassignments`} component={ProjectData} role="Utilicast" filters={filters} allowedRole="Admin" />
							<PrivateRoute exact path={`${this.props.match.url}/admin/keys`} component={KeyData} role="Admin" allowedRole="Admin" />
							<PrivateRoute exact path={`${this.props.match.url}/admin/archives/clients`} component={ClientArchives} allowedRole="Admin" />
							<PrivateRoute exact path={`${this.props.match.url}/admin/archives/sponsors`} component={SponsorArchives} role="Admin" allowedRole="Admin" />
							<PrivateRoute exact path={`${this.props.match.url}/admin/archives/projects`} component={ProjectArchives} role="Admin" allowedRole="Admin" />
							<PrivateRoute exact path={`${this.props.match.url}/admin/archives/projects/candidate`} component={ProjectArchives} role="Admin" allowedRole="Admin" candidate/>
							<PrivateRoute exact path={`${this.props.match.url}/admin/archives/assignments`} component={AssignmentArchives} role="Admin" allowedRole="Admin" />
							<PrivateRoute exact path={`${this.props.match.url}/am/sponsors`} component={SponsorData} role="Client Manager" allowedRole="Client Manager" />
							<PrivateRoute exact path={`${this.props.match.url}/am/projects/candidate`} component={Client_ProjectData} role="Client Manager" filters={filters} allowedRole="Client Manager" candidate/>
							<PrivateRoute exact path={`${this.props.match.url}/am/projects/active`} component={Client_ProjectData} role="Client Manager" filters={filters} allowedRole="Client Manager" />
							<PrivateRoute exact path={`${this.props.match.url}/am/assignments`} component={ProjectData} role="Client Manager" filters={filters} allowedRole="Client Manager" />
						</Switch>
					</Grid>
				</Grid>
			</Fragment>
		)
	}
}

function mapStateToProps(state) {
	return {
		db_data: state.db_data,
		page: state.page,
		consultants: state.consultants,
		clients: state.clients,
		errors: state.errors
	};
}

export default connect(mapStateToProps,
	{
		set_db_data,
		addBtn,
		export_table,
		user_sa_export,
		project_sa_export,
		company_sa_export,
		documents_export,
		removeError
	})(withWidth()(withToast(Homepage)));
