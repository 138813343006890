import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const PrivateRoute = ({ component: Component, user, allowedRole, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      user.role === "Admin" ? (
        <Component {...rest} /> 
      ) : user.role === allowedRole || (user.role === "Client Manager" && allowedRole === "Consultant") ? (
        <Component {...rest} /> 
      ) : user.role === allowedRole ? (
        <Component {...rest} /> 
      ) : (
        <Redirect to="/dashboard/" />
      )
    }
  />
);
const mapStateToProps = state => ({
  user: state.user
});
export default connect(mapStateToProps)(PrivateRoute);