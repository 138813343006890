import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {confirmAlert} from 'react-confirm-alert';
import empty from 'is-empty';
import validator from 'validator';

import {TextField, Grid, MenuItem} from '@material-ui/core';

import {db_update_sponsor, db_add_sponsor, db_archive_sponsor} from '../../store/actions/tables/sponsors';
import {db_return_client} from '../../store/actions/tables/clients';

import withToast from '../../utils/withToast';

import SaveBtn from '../../components/Buttons/SaveBtn';
import DeleteBtn from '../../components/Buttons/DeleteBtn';
import AddressBlock from '../../components/Blocks/AddressBlock';
import PhoneInput from '../../components/Inputs/PhoneInput';
import ModalLoading from '../../components/Graphics/ModalLoading';

class SponsorDetail extends Component {

	constructor(props) {
		super(props);
		this.state = {
			selectedClient: null,
			data: {
				ID: '',
				CLIENT_ID: '',
				FIRST: '',
				LAST: '',
				TITLE: '',
				EMAIL: '',
				PHONE: '',
				ADDRESS: '',
				CITY: '',
				STATE: '',
				ZIP: '',
				COUNTRY: ''
			},
			clientOptions: [],
			attemptedSubmit: false
		}
	}

	componentDidMount() {
		this.setState({
			...this.state, 
			data: this.props.edit ? this.props.currentSponsor : this.state.data,
			selectedClient: this.props.edit ? {value: this.props.currentSponsor.CLIENT.SPRINGAHEAD_ID, label: `${this.props.currentSponsor.CLIENT.ABBREV} - ${this.props.currentSponsor.CLIENT.NAME}`} : null
		});
	}
	
	async componentDidUpdate(prevProps, prevState) {
		if(this.props.edit) {
			if(prevProps.currentSponsor.ID !== this.props.currentSponsor.ID) {
				this.setState({...this.state, data: this.props.currentSponsor});
			}
		}
		if(this.state.data.CLIENT_ID !== prevState.data.CLIENT_ID) {
			if(!empty(this.state.data.CLIENT_ID) && !this.props.edit) {
				const resp = await db_return_client(this.state.data.CLIENT_ID);
				const client = resp.data;
				this.setState({
					...this.state,
					data: {
						...this.state.data,
						ADDRESS: empty(client) ? this.state.data.ADDRESS : client.ADDRESS,
						CITY: empty(client) ? this.state.data.CITY : client.CITY,
						STATE: empty(client) ? this.state.data.STATE : client.STATE,
						ZIP: empty(client) ? this.state.data.ZIP : client.ZIP,
						COUNTRY: empty(client) ? this.state.data.COUNTRY : client.COUNTRY
					}
				})
			}
		}
	}

	handleChange = e => {
		this.setState({
			...this.state,
			data: {
				...this.state.data,
				[e.target.name]: e.target.value
			},
			attemptedSubmit: false
		});
	}

	confirmRemove = () => {
  	confirmAlert({
      title: 'Confirm Archive',
      message: 'Are you sure you want to archive this sponsor?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.handleRemove()
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  }

  handleAdd = async () => {
		this.setState({...this.state, loading: true});
		const resp = await this.props.db_add_sponsor(this.state.data);
		if(resp.complete) {
			this.props.addToast('Sponsor successfully added.', {appearance: 'success', autoDismiss: true});
		} else {
			this.props.addToast(resp.error, {appearance: 'error', autoDismiss: true});
		}
		this.setState({...this.state, loading: false});
	}

	handlePut = async () => {
		this.setState({...this.state, loading: true});
		const resp = await this.props.db_update_sponsor(this.state.data);
		if(resp.complete) {
			this.props.addToast('Sponsor successfully updated.', {appearance: 'success', autoDismiss: true});
		} else {
			this.props.addToast(resp.error, {appearance: 'error', autoDismiss: true});
		}
		this.setState({...this.state, loading: false});
	}

	handleRemove = async () => {
		this.setState({...this.state, loading: true});
		const resp = await this.props.db_archive_sponsor({...this.state.data, ARCHIVE: 1});
		if(resp.complete) {
			this.props.addToast('Record successfully archived.', {appearance: 'success', autoDismiss: true});
		} else {
			this.props.addToast(resp.error, {appearance: 'error', autoDismiss: true});
		}
		this.setState({...this.state, loading: false});
	}

	render() {
		const {edit, clientOptions} = this.props;
		const {data, attemptedSubmit, loading} = this.state;

		const required = !empty(data.CLIENT_ID) && !empty(data.FIRST) && !empty(data.LAST) && !empty(data.EMAIL);
		const phoneFormat = empty(data.PHONE) ? true : validator.isMobilePhone(data.PHONE, 'any');
		const validated = required && phoneFormat;

		return (
			<Fragment>
				{loading && (<ModalLoading />)}
				<div className="form">
					<Grid container item xs={12}>
						<Grid container item direction="column" xs={12} sm={6} style={{padding: "0 1rem"}}>
							<TextField
			          select
			          required
			          error={empty(data.CLIENT_ID) && attemptedSubmit} 
								helperText={(empty(data.CLIENT_ID) && attemptedSubmit) ? "This field is required." : ""}
			          label="Client"
			          margin="dense" 
			          value={data.CLIENT_ID}
			          onChange={this.handleChange}
			          name="CLIENT_ID"
			        >
			          {clientOptions.map((option) => (
			            <MenuItem key={option.value} value={option.value}>
			              {option.label}
			            </MenuItem>
			          ))}
			        </TextField>
							<TextField 
								label="First Name" 
								fullWidth 
								required 
								error={empty(data.FIRST) && attemptedSubmit} 
								helperText={(empty(data.FIRST) && attemptedSubmit) ? "This field is required." : ""}
								margin="dense"
								type="text" 
								value={data.FIRST} 
								name="FIRST" 
								onChange={this.handleChange}
							/>
							<TextField 
								label="Last Name" 
								fullWidth 
								required 
								error={empty(data.LAST) && attemptedSubmit} 
								helperText={(empty(data.LAST) && attemptedSubmit) ? "This field is required." : ""}
								margin="dense"
								type="text" 
								value={data.LAST} 
								name="LAST" 
								onChange={this.handleChange}
							/>
							<TextField 
								label="Title" 
								fullWidth
								margin="dense"
								type="text" 
								value={data.TITLE} 
								name="TITLE" 
								onChange={this.handleChange}
							/>
						</Grid>
						<Grid container item direction="column" xs={12} sm={6} style={{padding: "0 1rem"}}>
							<AddressBlock onChange={this.handleChange} data={data}/>
							<TextField 
								label="Email" 
								fullWidth
								required 
								error={empty(data.EMAIL) && attemptedSubmit} 
								helperText={(empty(data.EMAIL) && attemptedSubmit) ? "This field is required." : ""}
								margin="dense"
								type="text" 
								value={data.EMAIL} 
								name="EMAIL" 
								onChange={this.handleChange}
							/>
							<PhoneInput
								error={!phoneFormat && attemptedSubmit} 
								helperText={!phoneFormat && attemptedSubmit ? "The phone number is not formatted correctly. Please follow the convention: XXX-XXX-XXXX" : ""}
								value={data.PHONE} 
								name="PHONE" 
								onChange={this.handleChange}
							/>
						</Grid>
						<div className="col-xs-12"></div>
						<div className="form-commands">
							<div className="btn-group data-form-commands">
								<SaveBtn onClick={!validated ? () => this.setState({...this.state, attemptedSubmit: true}) : edit ? this.handlePut : this.handleAdd} text="Save" disabled={!validated}/>
								{this.props.role === "Admin" && (<DeleteBtn onClick={edit ? this.confirmRemove : this.props.handleCancel} text={edit ? "Archive" : "Cancel"} delete={edit ? true : false} style={{marginLeft: "1.5rem"}}/>)}
							</div>
						</div>
					</Grid>
				</div>
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		errors: state.errors,
		user: state.user,
		clientOptions: state.clientOptions
	};
}

export default connect(mapStateToProps, {db_update_sponsor, db_add_sponsor, db_archive_sponsor})(withToast(SponsorDetail));


