import React, {Component, Fragment} from 'react';
import moment from 'moment';

import expenses from '../../images/expenses.png';
import defaultPic from '../../images/defaultClient.png';

class NoProject extends Component {

	render() {
		return (
			<Fragment>
				<div className="assignment">
					<div className="client-header">
						<div className="client-logo"><img className="client-logo-src" src={defaultPic} alt=""/></div>
						<div className="client-name"><span className="client-name-text">{this.props.user.name}</span></div>
					</div>
					<div className="assignment-header">
						<div className="title-client-container">
							<h1 className="assignment-title">You have no projects</h1>
							<p className="project-range">{moment().format("MMM D, YYYY")}</p>
						</div>
					</div>
					<div className="assignment-details">
						<div className="hour-rate-description">
							<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.0" x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 100 100" xmlSpace="preserve" className="hour-rate-icon"><path d="M48.333,41.667V23.333h-6.666V45c0,1.843,1.49,3.333,3.333,3.333h18.333v-6.666H48.333z"/><circle cx="45" cy="63.333" r="3.333"/><circle cx="26.667" cy="45" r="3.333"/><path d="M80,62.5h-6.667C71.491,62.5,70,61.009,70,59.167h-0.007L70,59.163c0-1.842,1.491-3.33,3.333-3.33v-0.006v0.006H80  c1.843,0,3.33,1.491,3.33,3.334H90c0-5.521-4.482-10-10-10.004v-7.496h-6.667v7.5v-0.004c-5.521,0-10,4.477-10,10v0.004  c0,5.521,4.479,10,10,10H80c1.843,0,3.33,1.49,3.33,3.333h-0.003c0,1.843-1.491,3.333-3.334,3.333H73.33  c-1.839,0-3.33-1.49-3.33-3.333h-6.667c0,5.521,4.479,10,10,10V90H80v-7.5c5.518-0.007,9.997-4.479,9.997-10H90  C90,66.979,85.518,62.5,80,62.5z"/><path d="M71.175,34.16C66.917,23.894,56.807,16.667,45,16.667c-15.651,0-28.333,12.682-28.333,28.333  c0,15.647,12.682,28.333,28.333,28.333c3.841,0,7.5-0.771,10.843-2.158l2.553,6.159C54.268,79.046,49.746,80,45,80  c-19.329,0-35-15.671-35-35s15.671-35,35-35c14.583,0,27.073,8.926,32.334,21.608L71.175,34.16z"/></svg>
							<p className="hour-line"></p>
						</div>
						<div className="hour-rate-description">
							<img className="hour-rate-icon" src={expenses} alt=""/>
							<p className="hour-line"></p>
						</div>
					</div>
					<div className="assignment-btn-container">
						<div className="btn-group">
							<p className="project-contract-none">Your contract links will go here.</p>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default NoProject;