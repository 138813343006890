import React, {Component, Fragment} from 'react';
import empty from 'is-empty';

import {db_get_manager_clients} from '../../store/actions/tables/clients';

class ConsultantRow extends Component {
	constructor(props){
    super(props);
    this.state = {
    	clicked: false,
    	loaded: false,
    	role: ''
    };
  }

  async componentDidMount() {
  	const {consultant} = this.props;
  	const client = await db_get_manager_clients(consultant.user_id);
		const role = consultant.is_admin ? "Admin" : !empty(client.count) ? "Client Manager" : "Consultant";
		this.setState({...this.state, loaded: true, role: role})
  }

	render() {
		const {consultant} = this.props;
		return (
			<Fragment>
				{this.state.loaded && (<tr className="row table-row read" id="consultant-row">
					<td data-container="body" className="table-data">{consultant.first_name}</td>
					<td data-container="body" className="table-data">{consultant.last_name}</td>
					<td data-container="body" className="table-data">{consultant.email}</td>
					<td data-container="body" className="table-data">{consultant.time_tracker_id}</td>
					<td data-container="body" className="table-data">{this.state.role}</td>
				</tr>)}
			</Fragment>
		);
	}
}

export default ConsultantRow;