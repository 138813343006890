import React, {Component} from 'react';

import {TextField, Grid, MenuItem} from '@material-ui/core';

import CurrencyInput from '../Inputs/CurrencyInput';

import empty from 'is-empty';

class BillingBlock extends Component {
	constructor(props) {
		super(props);
		this.state = {
			percent: '',
			pay: String(props.data.PAY || 0)
		}
	}

	componentDidMount() {
		this.props.onChangePay(this.props.data.PAY);
	}

	componentDidUpdate(prevProps, prevState) {
		if(prevProps.data.TYPE !== this.props.data.TYPE) {
			if(this.props.data.TYPE === "Fixed Price") {
				this.setState({
					...this.state,
					pay: '100'
				});
			} else {
				this.setState({
					...this.state,
					pay: '0'
				});
			}
		}
	}

	handleRadioChange = e => {
		if(e.target.name === "percent") {
			if(e.target.value === "other") {
				this.setState({
					...this.state,
					percent: 'other',
					pay: 0
				});
			} else {
				this.setState({
					...this.state,
					percent: e.target.id,
					pay: e.target.value
				});
			}
			this.props.onChangePay(e.target.value);
		} else if(e.target.name === "pay") {
			this.setState({
				...this.state,
				pay: e.target.value
			});
			this.props.onChangePay(e.target.value);
		}
	}

	onChange = val => {
		this.props.onChange(val);
		this.setState({
			...this.state,
			pay: "escape"
		})
	}

	render(){
		const {professionalOptions, handleChange, handleProfessionalChange, data, newBill, contractDownloadDisplay, disabled, attemptedSubmit} = this.props;
		const {pay} = this.state;

    const handleBlur = e => {
      if(e.target.value) {
        return;
      }

      if(e.target.name === 'pay') {
        this.setState({
          ...this.state,
          pay: 0,
        });
      } else if(e.target.name === 'BILL') {
        e.target.value = 0;
        handleChange({ target: e.target });
      }
    }

		return (
			<div>
				<Grid container item xs={12}>
					<Grid container item direction="column" xs={12} sm={6}>
						<TextField
		          select
		          fullWidth
		          required
		          error={empty(data.PROFESSIONAL) && attemptedSubmit}
							helperText={(empty(data.PROFESSIONAL) && attemptedSubmit) ? "This field is required." : ""}
		          label="Professional Distinction"
		          margin="dense"
		          value={data.PROFESSIONAL}
		          onChange={handleProfessionalChange}
		          name="PROFESSIONAL"
		          disabled={disabled || contractDownloadDisplay || data.TYPE === "Fixed Price"}
		        >
		          {professionalOptions.map((option) => (
		            <MenuItem key={option.value} value={option.value}>
		              {option.label}
		            </MenuItem>
		          ))}
		        </TextField>
					</Grid>
					{(!empty(data.PROFESSIONAL) || !empty(data.BILL)) && (<Grid container item direction="column" xs={12} sm={6} style={{padding: "0 1rem"}}>
						<CurrencyInput value={data.BILL} label="Bill Rate" name="BILL" onChange={handleChange} onBlur={handleBlur} disabled={contractDownloadDisplay || data.TYPE === "Fixed Price"}/>
					</Grid>)}
				</Grid>
					{(!empty(data.PROFESSIONAL) || !empty(data.PAY))  && (
						<Grid container item xs={12}>
							{data.TYPE !== "Fixed Price" && (<Grid container item direction="column" xs={12} sm={6}>
								<div className="sales-commission-item">
									<label htmlFor="PERCENTAGE">&nbsp;</label><br/>
									<div className="radio-div">
										<div className="radio-item">
											<input disabled={contractDownloadDisplay} checked={pay === String(newBill._90) ? true : false} type="radio" name="percent" id="_90" value={newBill._90} onChange={this.handleRadioChange}/><label className="radio-inline radio-label">90%</label>
										</div>
										<div className="radio-item">
											<input disabled={contractDownloadDisplay} checked={pay === String(newBill._85) ? true : false} type="radio" name="percent" id="_85" value={newBill._85} onChange={this.handleRadioChange}/><label className="radio-inline radio-label">85%</label>
										</div>
										<div className="radio-item">
											<input disabled={contractDownloadDisplay} checked={pay === String(newBill._80) ? true : false} type="radio" name="percent" id="_80" value={newBill._80} onChange={this.handleRadioChange}/><label className="radio-inline radio-label">80%</label>
										</div>
										<div className="radio-item">
											<input disabled={contractDownloadDisplay} checked={pay !== String(newBill._90) && pay !== String(newBill._85) && pay !== String(newBill._80) && pay !== "escape" ? true : false} type="radio" name="percent" id="other" value="other" onChange={this.handleRadioChange}/><label className="radio-inline radio-label">other</label>
										</div>
									</div>
								</div>
							</Grid>)}
							<Grid container item direction="column" xs={12} sm={6} style={{padding: data.TYPE !== "Fixed Price" ? "0 1rem" : ""}}>
								{(pay === String(newBill._90) || pay === String(newBill._85) || pay === String(newBill._80) || pay === "escape") && (
									<CurrencyInput label="Pay Rate" value={Number(data.PAY).toFixed(2)} name="pay" disabled={true} onBlur={handleBlur}/>
								)}
								{pay !== String(newBill._90) && pay !== String(newBill._85) && pay !== String(newBill._80) && pay !== "escape" && (
									<CurrencyInput value={pay} label="Pay Rate" name="pay" onChange={this.handleRadioChange} onBlur={handleBlur} disabled={contractDownloadDisplay || data.TYPE === "Fixed Price"}/>
								)}
							</Grid>
						</Grid>
					)}
			</div>
		);
	}
}

export default BillingBlock;
