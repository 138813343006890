import {apiCall} from '../../../services/api';
import XlsxPopulate from 'xlsx-populate';

import {db_get_clients} from '../tables/clients';
import {db_get_client_projects} from '../tables/client_projects';

import {SA_ERROR, addError} from '../utils/errors';
import {sa_user_export, sa_project_export, sa_company_export} from '../../../utils/saSchema';
import {project_status, assignment_status} from '../../../utils/status';
import letters from '../../../utils/letters';
import flatten from '../../../utils/flatten';

import empty from 'is-empty';
import moment from 'moment';

const exc_export = (data1, file_name, cb) => {
	flatten(data1, function(data) {
		const heads = Object.keys(data[0]);

		XlsxPopulate.fromBlankAsync()
	  .then(async function(workbook) {

	    for(let i = 0; i < heads.length; i++) {
	      workbook.sheet("Sheet1").cell(`${letters[i]}1`).value(heads[i]);
	    }
	    for(let i = 2; i < data.length + 2; i++) {
	      for(let x = 0; x < heads.length; x++) {
	        const val = data[i - 2][heads[x]];
	        const stringVal = JSON.stringify(val);
	        workbook.sheet("Sheet1").cell(`${letters[x]}${i}`).value(typeof stringVal === "string" ? stringVal.replace(/\"/g, "") : stringVal);
	      }
	    }

	    // Write to file.
	    workbook.outputAsync().then(function (blob) {
	        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
	            // If IE, you must uses a different method.
	            window.navigator.msSaveOrOpenBlob(blob, file_name + '.xlsx');
	        } else {
	            var url = window.URL.createObjectURL(blob);
	            var a = document.createElement("a");
	            document.body.appendChild(a);
	            a.href = url;
	            a.download = file_name + '.xlsx';
	            a.click();
	            window.URL.revokeObjectURL(url);
	            document.body.removeChild(a);
	        }
	        cb();
	    });
	  }).catch(err => {
	    console.log(err);
	    cb();
	  });
	});
}

export const export_table = (table, file_name) => async dispatch => {

	const data1 = await dispatch(get_export_data(table));

	if(empty(data1)) {
    return {complete: false, error: "No records to export!"};
	} else {
		return exc_export(data1, file_name, () => ({complete: true}));
	}
}

// SPRINGAHEAD EXPORTS



export const user_sa_export = () => dispatch => {
	return apiCall('get', '/api/springahead/users/page/0/1000')
	.then(async function(res) {
		const data = await res.Users.User.map(function(user) {
			return sa_user_export(user);
		});
		exc_export(data, "SpringAhead User Export", () => ({complete: true}));
	})
	.catch(function(err) {
		return {complete: false, error: err.data.error};
	});
}

export const project_sa_export = () => dispatch => {
	return apiCall('get', '/api/springahead/projects/page/0/2000')
	.then(async function(res) {
		const data = await res.Projects.Project.map(function(project) {
			return sa_project_export(project);
		});
		exc_export(data, "SpringAhead Project Export", () => ({complete: true}));
	})
	.catch(function(err) {
		return {complete: false, error: err.data.error};
	});
}

export const company_sa_export = () => dispatch => {
	return apiCall('get', '/api/springahead/companies/page/0/500')
	.then(async function(res) {
		const data = await res.Companies.Company.map(function(company) {
			return sa_company_export(company);
		});
		exc_export(data, "SpringAhead Company Export", () => ({complete: true}));
	})
	.catch(function(err) {
    return {complete: false, error: err.data.error};
	});
}

// DOCUMENTS EXPORT

export const documents_export = () => async (dispatch, getState) => {
  await dispatch(db_get_clients());
  await dispatch(db_get_client_projects());
  const clients = getState().clients;
  const projects = getState().client_projects;
  return apiCall('get', '/api/exports/documents')
  .then(async function(res) {
    const data = await res.map(function(record) {
      const client = clients.filter(c => record.id === c.ID);
      const project = projects.filter(p => record.id === p.ID);
      const name = record.type === "client" ? empty(client) ? "" : client[0].ABBREV : empty(project) ? "" : `(${project[0].CLIENT.ABBREV}) ${project[0].name}`;
      return {name: name, ...record};
    });
    exc_export(data, "All Documents Export", () => () => ({complete: true}));
  })
  .catch(function(err) {
    return {complete: false, error: err.data.error};
  });
}

const get_export_data = (table) => (dispatch, getState) => {
	switch(table) {
    case "clients":
      return (getState().clients.map(function(client) {
        const manager = {
          consultant_id: client.MANAGER.consultant_id,
          consultant_status: client.MANAGER.consultant_status,
          last_name: client.MANAGER.last_name,
          first_name: client.MANAGER.first_name,
          email: client.MANAGER.email,
          phone_number: client.MANAGER.phone_number
        }
        return {
          ...client,
          MANAGER: manager
        }
      }));
    case "consultants":
      return (getState().consultants.map(function(consultant) {
        return {
          ID: consultant.consultant_id,
          FIRST_NAME: consultant.first_name,
          LAST_NAME: consultant.last_name,
          PHONE: consultant.phone_number,
          ...consultant
        }
      }));
    case "sponsors":
      return (getState().sponsors);
    case "professional":
      return (getState().professionals);
    case "candidate-projects":
      return (getState().client_projects.map(function(project) {
        const manager = {
          email: project.MANAGER.email,
          last_name: project.MANAGER.last_name,
          first_name: project.MANAGER.first_name,
          phone_number: project.MANAGER.phone_number
        }
        const am = {
          email: project.ACCOUNT_MANAGER.email,
          last_name: project.ACCOUNT_MANAGER.last_name,
          first_name: project.ACCOUNT_MANAGER.first_name,
          phone_number: project.ACCOUNT_MANAGER.phone_number
        }
        return {
          ...project,
          MANAGER: manager,
          ACCOUNT_MANAGER: am,
          STATUS: project_status(moment.utc(project.START_DATE), empty(project.END_DATE) ? null : moment.utc(project.END_DATE), true)
        }
      }));
    case "admin-projects":
      return (getState().client_projects.map(function(project) {
        const manager = {
          email: project.MANAGER.email,
          last_name: project.MANAGER.last_name,
          first_name: project.MANAGER.first_name,
          phone_number: project.MANAGER.phone_number
        }
        const am = {
          email: project.ACCOUNT_MANAGER.email,
          last_name: project.ACCOUNT_MANAGER.last_name,
          first_name: project.ACCOUNT_MANAGER.first_name,
          phone_number: project.ACCOUNT_MANAGER.phone_number
        }
        return {
          ...project,
          MANAGER: manager,
          ACCOUNT_MANAGER: am,
          STATUS: project_status(moment.utc(project.START_DATE), empty(project.END_DATE) ? null : moment.utc(project.END_DATE), false)
        }
      }));
    case "admin-assignments":
      return (getState().projects.map(assignment => ({
        ...assignment,
        STATUS: assignment_status(moment.utc(assignment.START_DATE), empty(assignment.END_DATE) ? null : moment.utc(assignment.END_DATE), assignment.SOW)
      })));
    case "utilicast-assignments":
      return (getState().projects.map(assignment => ({
        ...assignment,
        STATUS: assignment_status(moment.utc(assignment.START_DATE), empty(assignment.END_DATE) ? null : moment.utc(assignment.END_DATE), assignment.SOW)
      })));
    case "client-archives":
      return (getState().clients);
    case "sponsor-archives":
      return (getState().sponsors);
    case "project-archives":
      return (getState().client_projects);
    case "candidate-project-archives":
      return (getState().client_projects);
    case "assignment-archives":
      return (getState().projects);
    case "am-sponsors":
      return (getState().sponsors);
    case "am-candidate-projects":
      return (getState().client_projects.map(function(project) {
        const am = {
          email: project.ACCOUNT_MANAGER.email,
          last_name: project.ACCOUNT_MANAGER.last_name,
          first_name: project.ACCOUNT_MANAGER.first_name,
          phone_number: project.ACCOUNT_MANAGER.phone_number
        }
        const lead = {
          email: project.LEAD.email,
          last_name: project.LEAD.last_name,
          first_name: project.LEAD.first_name,
          phone_number: project.LEAD.phone_number
        }
        delete project.MANAGER;
        return {
          ...project,
          LEAD: lead,
          ACCOUNT_MANAGER: am,
          STATUS: project_status(moment.utc(project.START_DATE), empty(project.END_DATE) ? null : moment.utc(project.END_DATE), true)
        }
      }));
    case "am-projects":
      return (getState().client_projects.map(function(project) {
        const am = {
          email: project.ACCOUNT_MANAGER.email,
          last_name: project.ACCOUNT_MANAGER.last_name,
          first_name: project.ACCOUNT_MANAGER.first_name,
          phone_number: project.ACCOUNT_MANAGER.phone_number
        }
        const manager = {
          email: project.MANAGER.email,
          last_name: project.MANAGER.last_name,
          first_name: project.MANAGER.first_name,
          phone_number: project.MANAGER.phone_number
        }
        return {
          ...project,
          MANAGER: manager,
          ACCOUNT_MANAGER: am,
          STATUS: project_status(moment.utc(project.START_DATE), empty(project.END_DATE) ? null : moment.utc(project.END_DATE), false)
        }
      }));
    case "am-assignments":
      return (getState().projects.map(function(assignment) {
        const am = {
          email: assignment.ACCOUNT_MANAGER.email,
          last_name: assignment.ACCOUNT_MANAGER.last_name,
          first_name: assignment.ACCOUNT_MANAGER.first_name,
          phone_number: assignment.ACCOUNT_MANAGER.phone_number
        }
        const manager = {
          email: assignment.PROJECT_MANAGER.email,
          last_name: assignment.PROJECT_MANAGER.last_name,
          first_name: assignment.PROJECT_MANAGER.first_name,
          phone_number: assignment.PROJECT_MANAGER.phone_number
        }
        const consultant = {
          consultant_id: assignment.CONSULTANT.consultant_id,
          email: assignment.CONSULTANT.email,
          last_name: assignment.CONSULTANT.last_name,
          first_name: assignment.CONSULTANT.first_name,
          phone_number: assignment.CONSULTANT.phone_number,
          consultant_type: assignment.CONSULTANT.consultant_type,
          contractor_company_name: assignment.CONSULTANT.contractor_company_name
        }
        const project = {
          NAME: assignment.PROJECT.NAME,
          DESCRIPTION: assignment.PROJECT.DESCRIPTION,
          TYPE: assignment.PROJECT.TYPE,
          MANAGER_ID: assignment.PROJECT.MANAGER_ID,
          START_DATE: assignment.PROJECT.START_DATE,
          END_DATE: assignment.PROJECT.END_DATE,
          VALUE: assignment.PROJECT.VALUE,
          PRACTICE_AREAS: assignment.PROJECT.PRACTICE_AREAS,
          SPONSORS: assignment.PROJECT.SPONSORS,
          TERM_ID: assignment.PROJECT.TERM_ID,
          PO_REQUIRED: assignment.PROJECT.PO_REQUIRED,
        }
        const client = {
          NAME: assignment.CLIENT.NAME,
          ABBREV: assignment.CLIENT.ABBREV,
          ADDRESS: assignment.CLIENT.ADDRESS,
          ENTITY: assignment.CLIENT.ENTITY,
          DELIVERABLES_LINK: assignment.CLIENT.DELIVERABLES_LINK,
          CITY: assignment.CLIENT.CITY,
          STATE: assignment.CLIENT.STATE,
          COUNTRY: assignment.CLIENT.COUNTRY,
          TERMS_LINK: assignment.CLIENT.TERMS_LINK,
          TERM_ID: assignment.CLIENT.TERM_ID,
          PO_REQUIRED: assignment.CLIENT.PO_REQUIRED,
        }
        delete assignment.ARCHIVE;
        delete assignment.SPRINGAHEAD_ID;
        delete assignment.PROFESSIONAL;
        delete assignment.CONSULTANT_ID;
        delete assignment.CLIENT_ID;
        delete assignment.ID;
        return {
          ...assignment,
          PROJECT_MANAGER: manager,
          ACCOUNT_MANAGER: am,
          CONSULTANT: consultant,
          PROJECT: project,
          CLIENT: client,
          STATUS: assignment_status(moment.utc(assignment.START_DATE), empty(assignment.END_DATE) ? null : moment.utc(assignment.END_DATE), assignment.SOW)
        }
      }));
    case "consultant-assignments":
      return (getState().projects.map(assignment => ({
        ...assignment,
        STATUS: assignment_status(moment.utc(assignment.START_DATE), empty(assignment.END_DATE) ? null : moment.utc(assignment.END_DATE), assignment.SOW)
      })));
    case "consultant-leads":
      return (getState().client_projects.map(data => (
        {
          CLIENT: empty(data.CLIENT) ? '' : data.CLIENT.ABBREV,
          PROJECT_NAME: data.NAME,
          CLIENT_MANAGER: empty(data.ACCOUNT_MANAGER) ? '' : data.ACCOUNT_MANAGER.first_name + ' ' + data.ACCOUNT_MANAGER.last_name,
          ENTERED_BY: empty(data.LEAD) ? '' : data.LEAD.first_name + ' ' + data.LEAD.last_name,
          START_DATE: data.START_DATE,
          END_DATE: data.END_DATE,
          VALUE: data.VALUE,
          TYPE: data.TYPE
        })
      ));
    case "consultant-submitted-leads":
      return (getState().client_projects.map(data => (
        {
          CLIENT: empty(data.CLIENT) ? '' : data.CLIENT.ABBREV,
          PROJECT_NAME: data.NAME,
          CLIENT_MANAGER: empty(data.ACCOUNT_MANAGER) ? '' : data.ACCOUNT_MANAGER.first_name + ' ' + data.ACCOUNT_MANAGER.last_name,
          ENTERED_BY: empty(data.LEAD) ? '' : data.LEAD.first_name + ' ' + data.LEAD.last_name,
          START_DATE: data.START_DATE,
          END_DATE: data.END_DATE,
          VALUE: data.VALUE,
          TYPE: data.TYPE
        })
      ));
    case "consultant-candidate-projects":
      return (getState().client_projects.map(data => (
      	{
      		CLIENT: empty(data.CLIENT) ? '' : data.CLIENT.ABBREV,
      		PROJECT_NAME: data.NAME,
      		CLIENT_MANAGER: empty(data.ACCOUNT_MANAGER) ? '' : data.ACCOUNT_MANAGER.first_name + ' ' + data.ACCOUNT_MANAGER.last_name,
      		ENTERED_BY: empty(data.LEAD) ? '' : data.LEAD.first_name + ' ' + data.LEAD.last_name,
      		START_DATE: data.START_DATE,
      		END_DATE: data.END_DATE,
      		VALUE: data.VALUE,
      		TYPE: data.TYPE
      	})
      ));
    case "consultant-projects":
      return (getState().client_projects.map(data => (
      	{
      		CLIENT: empty(data.CLIENT) ? '' : data.CLIENT.ABBREV,
      		PROJECT_NAME: data.NAME,
      		CLIENT_MANAGER: empty(data.ACCOUNT_MANAGER) ? '' : data.ACCOUNT_MANAGER.first_name + ' ' + data.ACCOUNT_MANAGER.last_name,
      		PROJECT_MANAGER: empty(data.MANAGER) ? '' : data.MANAGER.first_name + ' ' + data.MANAGER.last_name,
      		START_DATE: data.START_DATE,
      		END_DATE: data.END_DATE,
      		VALUE: data.VALUE,
      		TYPE: data.TYPE
      	})
      ));
    case "company-assignments":
      return (getState().projects.map(data => (
      	{
      		CLIENT: empty(data.CLIENT) ? '' : data.CLIENT.ABBREV,
      		PROJECT_NAME: empty(data.PROJECT) ? '' : data.PROJECT.NAME,
      		CONSULTANT: empty(data.CONSULTANT) ? '' : data.CONSULTANT.first_name + ' ' + data.CONSULTANT.last_name,
      		START_DATE: data.START_DATE,
      		END_DATE: data.END_DATE,
      		HOURS: data.HOURS,
      		UTILIZATION: moment.utc(data.START_DATE).isSame(moment.utc(data.END_DATE)) ? (data.HOURS/((1/365)*20)).toFixed(0) + "%" : (data.HOURS/(((((moment.duration(moment.utc(data.END_DATE).diff(moment.utc(data.START_DATE)))).asDays()) + 1)/365)*20)).toFixed(0) + "%"
      	})
      ));
    case "consultant-clients":
      return (getState().clients.map(data => (
      	{
      		NAME: data.NAME,
      		ABBREVIATION: data.ABBREV,
      		MANAGER: empty(data.MANAGER) ? '' : data.MANAGER.first_name + ' ' + data.MANAGER.last_name,
      		ADDRESS: `${data.ADDRESS}, ${data.CITY}, ${data.STATE} ${data.ZIP}, ${data.COUNTRY}`
      	})
      ));
    case "consultant-sponsors":
      return (getState().sponsors.map(data => (
      	{
      		CLIENT: empty(data.CLIENT) ? '' : data.CLIENT.NAME,
      		FIRST: data.FIRST,
      		LAST: data.LAST,
      		TITLE: data.TITLE,
      		EMAIL: data.EMAIL,
      		PHONE: data.PHONE,
      		ADDRESS: `${data.ADDRESS}, ${data.CITY}, ${data.STATE} ${data.ZIP}, ${data.COUNTRY}`
      	})
      ));
    default:
      return ([]);
  }
}
