import React, {Component, Fragment} from 'react';
import empty from 'is-empty';

import SaveBtn from '../Buttons/SaveBtn';
import DeleteBtn from '../Buttons/DeleteBtn';

class AddKeyPopup extends Component {

	constructor(props) {
		super(props);
		this.state = {
			name: ''
		}
	}

	onChange = e => {
		this.setState({
			...this.state,
			[e.target.name]: e.target.value
		});
	}

	handleSave = e => {
		e.preventDefault();
		this.props.onSave({NAME: this.state.name});
		this.props.onHide();
	}

  render() {
  	const {name} = this.state;
  	return(
  		<Fragment>
  			<div className={this.props.sidebar ? "loading-div-container closed" : "loading-div-container"}>
  				<h5 className="loading-div-text" style={{paddingBottom: "2rem", fontSize: "1.5rem"}}>{this.props.text}</h5>
	  			<div style={{width: "85%", fontSize: "1.2rem"}}>
	  				<input type="text" className="form-control" name="name" onChange={this.onChange} value={name}/>
	  			</div>
					<div className="btn-group" style={{paddingBottom: "2rem", paddingTop: "1.5rem"}}>
						<SaveBtn onClick={empty(name) ? () => {} : this.handleSave} text="Add" disabled={empty(name)}/>
						<DeleteBtn onClick={this.props.onHide} text={"Cancel"} delete={false} style={{marginLeft: "1.5rem"}}/>
					</div>
				</div>
	  		<div className="backdrop"></div>
  		</Fragment>
  	);
  }
}
 
export default AddKeyPopup;

