import {apiCall} from '../../../services/api';
import empty from 'is-empty';

import {DB_ERROR} from '../utils/errors';
import {hide_panel} from '../utils/panel';
import {db_return_consultant} from './consultants';
import {db_return_term} from '../tables/terms';
import {db_add_return_client, db_get_client_dropdown} from '../tables/clients';

import {GET_CLIENT_PROJECTS, ADD_CLIENT_PROJECT, UPDATE_CLIENT_PROJECT, REMOVE_CLIENT_PROJECT, GET_PROJECT_OPTIONS} from '../../actionTypes';

import {nullConsultant} from '../../../utils/nulls';

// ACTIONS BELOW

export const state_get_client_projects = payload => ({
	type: GET_CLIENT_PROJECTS,
	payload
});

export const state_add_client_project = payload => ({
	type: ADD_CLIENT_PROJECT,
	payload
});

export const state_update_client_project = payload => ({
	type: UPDATE_CLIENT_PROJECT,
	payload
});

export const state_remove_client_project = payload => ({
	type: REMOVE_CLIENT_PROJECT,
	payload
});

export const state_get_project_options = payload => ({
	type: GET_PROJECT_OPTIONS,
	payload
});

//GET

export const db_get_client_projects = () => dispatch => {
	return apiCall('get', '/api/client_projects')
	.then(function(res) {
		dispatch(state_get_client_projects(res.map(project => (
			{
				...project,
				VALUE: empty(project.VALUE) ? null : Number.parseFloat(project.VALUE),
				LEAD: dispatch(db_return_consultant(project.LEAD_ID)),
				MANAGER: dispatch(db_return_consultant(project.MANAGER_ID)),
				ACCOUNT_MANAGER: empty(project.CLIENT) ? nullConsultant : dispatch(db_return_consultant(project.CLIENT.MANAGER_ID)),
        TERM: dispatch(db_return_term(project.TERM_ID))
			}
		))));
	})
	.catch(function(err) {
		dispatch(DB_ERROR(err));
	});
}

export const db_get_am_client_projects = id => dispatch => {
	return apiCall('get', `/api/client_projects/am/${id}`)
	.then(function(res) {
		dispatch(state_get_client_projects(res.map(project => (
			{
				...project,
				VALUE: empty(project.VALUE) ? null : Number.parseFloat(project.VALUE),
				LEAD: dispatch(db_return_consultant(project.LEAD_ID)),
				MANAGER: dispatch(db_return_consultant(project.MANAGER_ID)),
				ACCOUNT_MANAGER: empty(project.CLIENT) ? nullConsultant : dispatch(db_return_consultant(project.CLIENT.MANAGER_ID)),
        TERM: dispatch(db_return_term(project.TERM_ID))
			}
		))));
	})
	.catch(function(err) {
		dispatch(DB_ERROR(err));
	});
}

export const db_get_consultant_client_projects = (id, submitted) => dispatch => {
	return apiCall('get', `/api/client_projects/candidate/consultant/${id}?submitted=${submitted}`)
	.then(function(res) {
		dispatch(state_get_client_projects(res.map(project => (
			{
				...project,
				VALUE: empty(project.VALUE) ? null : Number.parseFloat(project.VALUE),
				LEAD: dispatch(db_return_consultant(project.LEAD_ID)),
				MANAGER: dispatch(db_return_consultant(project.MANAGER_ID)),
				ACCOUNT_MANAGER: empty(project.CLIENT) ? nullConsultant : dispatch(db_return_consultant(project.CLIENT.MANAGER_ID)),
        TERM: dispatch(db_return_term(project.TERM_ID))
			}
		))));
	})
	.catch(function(err) {
		dispatch(DB_ERROR(err));
	});
}

export const db_return_client_project = id => {
	return apiCall('get', `/api/client_projects/id/${id}`)
	.then(function(res) {
		return {complete: true, data: res[0]};
	})
	.catch(function(err) {
		return {complete: false}
	});
}

export const db_get_project_dropdown = id => dispatch => {
	return apiCall('get', `/api/dropdowns/projects/${id}`)
	.then(function(res) {
		dispatch(state_get_project_options(res));
	})
	.catch(function(err) {
		console.log(err);
	});
}

export const db_get_project_amendments = id => {
	return apiCall('get', `/api/client_projects/amendments/${id}`)
	.then(function(res) {
		return res.blobs;
	})
	.catch(function(err) {
		console.log(err);
	});
}

//UPDATE

export const db_update_client_project = client_project => dispatch => {
	return apiCall('put', `/api/client_projects/${client_project.ID}`, client_project)
	.then(function(res) {
		dispatch(state_update_client_project({
			...res,
			VALUE: empty(res.VALUE) ? null : Number.parseFloat(res.VALUE),
			LEAD: dispatch(db_return_consultant(res.LEAD_ID)),
			MANAGER: dispatch(db_return_consultant(res.MANAGER_ID)),
			ACCOUNT_MANAGER: empty(res.CLIENT) ? nullConsultant : dispatch(db_return_consultant(res.CLIENT.MANAGER_ID)),
      TERM: dispatch(db_return_term(res.TERM_ID))
		}));
		return {complete: true};
	})
	.catch(function(err) {
		return {complete: false, error: err.data.error};
	});
}

export const db_update_task = task => {
	return apiCall('put', `/api/client_projects/task/${task.Id}`, task)
	.then(function(res) {
		return {complete: true};
	})
	.catch(function(err) {
		return {complete: false, error: err.data.error};
	});
}

export const db_update_invoicing = project => dispatch => {
	return apiCall('put', `/api/client_projects/invoicing/${project.ID}`, project)
	.then(function(res) {
		if(!empty(res)) dispatch(state_update_client_project(res[0]));
		return {complete: true};
	})
	.catch(function(err) {
		return {complete: false, error: err.data.error};
	});
}

//ADD

export const db_add_client_project = (client_project) => async dispatch => {
	const newClientResp = !empty(client_project.NEW_CLIENT) ? await dispatch(db_add_return_client(client_project.NEW_CLIENT)) : {complete: true};
	if(newClientResp.complete) {
		return apiCall('post', `/api/client_projects`, empty(client_project.NEW_CLIENT) ? client_project : {...client_project, CLIENT_ID: newClientResp.data.SPRINGAHEAD_ID})
		.then(function(res) {
			dispatch(state_add_client_project({
				...res,
				VALUE: empty(res.VALUE) ? null : Number.parseFloat(res.VALUE),
				LEAD: dispatch(db_return_consultant(res.LEAD_ID)),
				MANAGER: dispatch(db_return_consultant(res.MANAGER_ID)),
				ACCOUNT_MANAGER: empty(res.CLIENT) ? nullConsultant : dispatch(db_return_consultant(res.CLIENT.MANAGER_ID)),
        TERM: dispatch(db_return_term(res.TERM_ID))
			}));
			dispatch(hide_panel());
			return {complete: true};
		})
		.catch(function(err) {
			return {complete: false, error: err.data.error};
		});
	} else {
		return {complete: false, error: newClientResp.error};
	}
}

export const db_add_project_amendments = data => {
	return apiCall('post', `/api/client_projects/amendments`, data)
	.then(function(res) {
		return {complete: true};
	})
	.catch(function(err) {
		return {complete: false, error: err.data.error};
	});
}

//DELETE

export const db_remove_client_project = client_project => dispatch => {
	return apiCall('delete', `/api/archives/client_project/${client_project.ID}`)
	.then(function(res) {
		dispatch(state_remove_client_project(client_project));
		return {complete: true};
	})
	.catch(function(err) {
		return {complete: false, error: err.data.error};
	})
}

//ARCHIVES

export const db_get_client_project_archives = () => dispatch => {
	return apiCall('get', '/api/client_projects/archives')
	.then(function(res) {
		dispatch(state_get_client_projects(res.map(project => ({...project, LEAD: dispatch(db_return_consultant(project.LEAD_ID)), MANAGER: dispatch(db_return_consultant(project.MANAGER_ID)), ACCOUNT_MANAGER: empty(project.CLIENT) ? nullConsultant : dispatch(db_return_consultant(project.CLIENT.MANAGER_ID))}))));
	})
	.catch(function(err) {
		dispatch(DB_ERROR(err));
	});
}

export const db_archive_client_project = client_project => async dispatch => {
	return apiCall('put', `/api/archives/client_project`, client_project)
	.then(function(res) {
		dispatch(state_remove_client_project(client_project));
		return {complete: true};
	})
	.catch(function(err) {
		return {complete: false, error: err.data.error};
	});
}

export const db_delete_project_amendments = data => {
	return apiCall('post', `/api/client_projects/amendments/delete`, data)
	.then(function(res) {
		return {complete: true};
	})
	.catch(function(err) {
		return {complete: false, error: err.data.error};
	});
}

// CANDIDATE PROJECTS

export const db_get_candidate_projects = () => dispatch => {
	return apiCall('get', '/api/client_projects/candidate')
	.then(function(res) {
		dispatch(state_get_client_projects(res.map(project => ({...project, VALUE: empty(project.VALUE) ? null : Number.parseFloat(project.VALUE), LEAD: dispatch(db_return_consultant(project.LEAD_ID)), MANAGER: dispatch(db_return_consultant(project.MANAGER_ID)), ACCOUNT_MANAGER: empty(project.CLIENT) ? nullConsultant : dispatch(db_return_consultant(project.CLIENT.MANAGER_ID))}))));
	})
	.catch(function(err) {
		dispatch(DB_ERROR(err));
	});
}

export const db_get_am_candidate_projects = id => dispatch => {
	return apiCall('get', `/api/client_projects/candidate/am/${id}`)
	.then(function(res) {
		dispatch(state_get_client_projects(res.map(project => ({...project, VALUE: empty(project.VALUE) ? null : Number.parseFloat(project.VALUE), LEAD: dispatch(db_return_consultant(project.LEAD_ID)), MANAGER: dispatch(db_return_consultant(project.MANAGER_ID)), ACCOUNT_MANAGER: empty(project.CLIENT) ? nullConsultant : dispatch(db_return_consultant(project.CLIENT.MANAGER_ID))}))));
	})
	.catch(function(err) {
		dispatch(DB_ERROR(err));
	});
}

export const db_get_candidate_project_archives = () => dispatch => {
	return apiCall('get', '/api/client_projects/candidate/archives')
	.then(function(res) {
		dispatch(state_get_client_projects(res.map(project => ({...project, VALUE: empty(project.VALUE) ? null : Number.parseFloat(project.VALUE), LEAD: dispatch(db_return_consultant(project.LEAD_ID)), MANAGER: dispatch(db_return_consultant(project.MANAGER_ID)), ACCOUNT_MANAGER: empty(project.CLIENT) ? nullConsultant : dispatch(db_return_consultant(project.CLIENT.MANAGER_ID))}))));
	})
	.catch(function(err) {
		dispatch(DB_ERROR(err));
	});
}

export const db_activate_candidate_project = client_project => async dispatch => {
	return apiCall('put', `/api/client_projects/activate`, client_project)
	.then(function(res) {
		dispatch(state_remove_client_project(client_project));
		dispatch(hide_panel());
		return {complete: true};
	})
	.catch(function(err) {
		return {complete: false, error: err.data.error};
	});
}
