import React, {Component} from 'react';

import ArchiveBtns from '../Buttons/ArchiveBtns';

class SponsorRow extends Component {
	constructor(props){
    super(props);
    this.state = {
    	clicked: false
    };
  }

  onRecover = e => {
  	e.preventDefault();
  	this.props.onRecover(this.props.sponsor);
  }
  onDelete = e => {
  	e.preventDefault();
  	this.props.onDelete(this.props.sponsor);
  }

	render() {
		const {sponsor, handleRowClicked, id, role, user} = this.props;
		const clicked = id === sponsor.ID ? "current-overhead-row" : "";
		const rowClasses = `row table-row ${clicked}${role === "Consultant" ? "read" : ""}`;
		const client = sponsor.CLIENT;
		return (
			<tr className={rowClasses} onClick={handleRowClicked}>
				<td data-container="body" className="table-data"><div className="img-row"><div className="row-img"><img className="row-img-src" src={`https://utilicastclientpictures.blob.core.windows.net/${client.ID}client/profile${user.sas}`} alt=""/></div>{client.NAME}</div></td>
				<td data-container="body" className="table-data">{sponsor.FIRST}</td>
				<td data-container="body" className="table-data">{sponsor.LAST}</td>
				<td data-container="body" className="table-data">{sponsor.TITLE}</td>
				<td data-container="body" className="table-data">{sponsor.EMAIL}</td>
				<td data-container="body" className="table-data">{sponsor.PHONE}</td>
				<td data-container="body" className="table-data">{`${sponsor.ADDRESS}, ${sponsor.CITY}, ${sponsor.STATE} ${sponsor.ZIP}, ${sponsor.COUNTRY}`}</td>
				{this.props.archive && (<ArchiveBtns onRecover={this.onRecover} onDelete={this.onDelete}/>)}
			</tr>
		);
	}
}

export default SponsorRow;