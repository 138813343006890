import {GET_TERMS} from '../../actionTypes';

const terms = (state = [], action) => {
	switch(action.type) {
		case GET_TERMS:
			return [...action.terms];
		default:
			return state;
	}
}

export default terms;
