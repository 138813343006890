import React, {Component} from 'react';
import empty from 'is-empty';
import moment from 'moment';
import MomentUtils from '@date-io/moment';

import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';

class DatePicker extends Component {

	render() {
		return (
			<MuiPickersUtilsProvider utils={MomentUtils}>
				<KeyboardDatePicker
	        disableToolbar
	        fullWidth
	        required={this.props.required}
	        variant="inline"
	        format="YYYY-MM-DD"
	        margin="dense"
	        label={this.props.label}
	        name={this.props.name}
	        value={empty(this.props.value) ? null : moment.utc(this.props.value).format('YYYY-MM-DD')}
	        onChange={this.props.onChange}
	        onBlur={this.props.onBlur}
	        disabled={this.props.disabled}
	        error={this.props.error}
	        helperText={this.props.error ? this.props.errorMessage : ""}
	        placeholder="YYYY-MM-DD"
	      />
      </MuiPickersUtilsProvider>
		)
	}
}

export default DatePicker;