import {apiCall} from '../../../services/api';
import empty from 'is-empty';

import {db_return_consultant} from '../tables/consultants';
import {DB_ERROR} from '../utils/errors';
import {GET_PROJECTS, ADD_PROJECT, UPDATE_PROJECT, REMOVE_PROJECT} from '../../actionTypes';

import {nullConsultant} from '../../../utils/nulls';

// ACTIONS BELOW

export const state_get_projects = payload => ({
	type: GET_PROJECTS,
	payload
});

export const state_add_project = payload => ({
	type: ADD_PROJECT,
	payload
});

export const state_update_project = payload => ({
	type: UPDATE_PROJECT,
	payload
});

export const state_remove_project = payload => ({
	type: REMOVE_PROJECT,
	payload
});

//GET

export const db_get_projects = () => dispatch => {
	return apiCall('get', '/api/projects')
	.then(function(res) {
		dispatch(state_get_projects(res.map(project => (
			{
				...project,
				CONSULTANT: dispatch(db_return_consultant(project.CONSULTANT_ID)),
				ACCOUNT_MANAGER: empty(project.CLIENT) ? nullConsultant : dispatch(db_return_consultant(project.CLIENT.MANAGER_ID)),
				PROJECT_MANAGER: empty(project.PROJECT) ? nullConsultant : dispatch(db_return_consultant(project.PROJECT.MANAGER_ID))
			}
		))));
	})
	.catch(function(err) {
		dispatch(DB_ERROR(err));
	});
}

export const db_get_am_projects = id => dispatch => {
	return apiCall('get', `/api/projects/am/${id}`)
	.then(function(res) {
		dispatch(state_get_projects(res.map(project => (
			{
				...project,
				CONSULTANT: dispatch(db_return_consultant(project.CONSULTANT_ID)),
				ACCOUNT_MANAGER: empty(project.CLIENT) ? nullConsultant : dispatch(db_return_consultant(project.CLIENT.MANAGER_ID)),
				PROJECT_MANAGER: empty(project.PROJECT) ? nullConsultant : dispatch(db_return_consultant(project.PROJECT.MANAGER_ID))
			}
		))));
	})
	.catch(function(err) {
		dispatch(DB_ERROR(err));
	});
}

export const db_get_utilicast_projects = id => dispatch => {
	return apiCall('get', `/api/projects/utilicast`)
	.then(function(res) {
		dispatch(state_get_projects(res.map(project => (
			{
				...project,
				CONSULTANT: dispatch(db_return_consultant(project.CONSULTANT_ID)),
				ACCOUNT_MANAGER: empty(project.CLIENT) ? nullConsultant : dispatch(db_return_consultant(project.CLIENT.MANAGER_ID)),
				PROJECT_MANAGER: empty(project.PROJECT) ? nullConsultant : dispatch(db_return_consultant(project.PROJECT.MANAGER_ID))
			}
		))));
	})
	.catch(function(err) {
		dispatch(DB_ERROR(err));
	});
}

export const db_get_consultant_projects = id => dispatch => {
	return apiCall('get', `/api/projects/consultant/${id}`)
	.then(function(res) {
		dispatch(state_get_projects(res.map(project => (
			{
				...project,
				CONSULTANT: dispatch(db_return_consultant(project.CONSULTANT_ID)),
				ACCOUNT_MANAGER: empty(project.CLIENT) ? nullConsultant : dispatch(db_return_consultant(project.CLIENT.MANAGER_ID)),
				PROJECT_MANAGER: empty(project.PROJECT) ? nullConsultant : dispatch(db_return_consultant(project.PROJECT.MANAGER_ID))
			}
		))));
	})
	.catch(function(err) {
		dispatch(DB_ERROR(err));
	});
}

export const db_get_project_consultant_projects = (project_id, consultant_id) => dispatch => {
	return apiCall('get', `/api/projects/${project_id}/consultant/${consultant_id}`)
	.then(function(res) {
    return {
      complete: true,
      data: res.map(project => ({
        ...project,
        CONSULTANT: dispatch(db_return_consultant(project.CONSULTANT_ID)),
        ACCOUNT_MANAGER: empty(project.CLIENT) ? nullConsultant : dispatch(db_return_consultant(project.CLIENT.MANAGER_ID)),
        PROJECT_MANAGER: empty(project.PROJECT) ? nullConsultant : dispatch(db_return_consultant(project.PROJECT.MANAGER_ID))
      })),
    };
	})
	.catch(function(err) {
    console.log(err.data.error)
		return {complete: false, error: err.data.error};
	});
}

export const db_get_project = id => dispatch => {
	return apiCall('get', `/api/projects/id/${id}`)
	.then(function(res) {
		return {
			complete: true,
			data: {
				...res,
				CONSULTANT: dispatch(db_return_consultant(res.CONSULTANT_ID)),
				ACCOUNT_MANAGER: empty(res.CLIENT) ? nullConsultant : dispatch(db_return_consultant(res.CLIENT.MANAGER_ID)),
				PROJECT_MANAGER: empty(res.PROJECT) ? nullConsultant : dispatch(db_return_consultant(res.PROJECT.MANAGER_ID))
			}
		};
	})
	.catch(function(err) {
		console.log(err.data.error)
		return {complete: false, error: err.data.error};
	});
}

//EDIT

export const db_update_project = project => async dispatch => {
	return apiCall('put', `/api/projects/${project.ID}`, project)
	.then(function(res) {
		dispatch(state_update_project({
			...res,
			CONSULTANT: dispatch(db_return_consultant(res.CONSULTANT_ID)),
			ACCOUNT_MANAGER: empty(res.CLIENT) ? nullConsultant : dispatch(db_return_consultant(res.CLIENT.MANAGER_ID)),
			PROJECT_MANAGER: empty(res.PROJECT) ? nullConsultant : dispatch(db_return_consultant(res.PROJECT.MANAGER_ID))
		}));
		return {complete: true};
	})
	.catch(function(err) {
		return {complete: false, error: err.data.error};
	});
}

//ADD

export const db_add_project = (project) => async dispatch => {
	return apiCall('post', `/api/projects`, project)
	.then(function(res) {
		dispatch(state_add_project({
			...res,
			CONSULTANT: dispatch(db_return_consultant(res.CONSULTANT_ID)),
			ACCOUNT_MANAGER: empty(res.CLIENT) ? nullConsultant : dispatch(db_return_consultant(res.CLIENT.MANAGER_ID)),
			PROJECT_MANAGER: empty(res.PROJECT) ? nullConsultant : dispatch(db_return_consultant(res.PROJECT.MANAGER_ID))
		}));
    return {complete: true, ID: res.ID};
	})
	.catch(function(err) {
		return {complete: false, error: err.data.error};
	});
}

//DELETE

export const db_remove_project = project => dispatch => {
	return apiCall('post', `/api/projects/delete`, project)
	.then(function(res) {
		dispatch(state_remove_project(project));
		return {complete: true};
	})
	.catch(function(err) {
		return {complete: false, error: err.data.error};
	});
}

//ARCHIVES BELOW

export const db_archive_project = project => dispatch => {
	return apiCall('put', `/api/archives/project`, project)
	.then(function(res) {
		dispatch(state_remove_project(project));
		return {complete: true};
	})
	.catch(function(err) {
		return {complete: false, error: err.data.error};
	});
}

export const db_get_project_archives = () => dispatch => {
	return apiCall('get', '/api/projects/archives')
	.then(function(res) {
		dispatch(state_get_projects(res.map(project => ({...project, CONSULTANT: dispatch(db_return_consultant(project.CONSULTANT_ID)), ACCOUNT_MANAGER: empty(project.CLIENT) ? nullConsultant : dispatch(db_return_consultant(project.CLIENT.MANAGER_ID)), PROJECT_MANAGER: empty(project.PROJECT) ? nullConsultant : dispatch(db_return_consultant(project.PROJECT.MANAGER_ID))}))));
	})
	.catch(function(err) {
		dispatch(DB_ERROR(err));
	});
}
