import moment from 'moment';
import empty from 'is-empty';

export function sortList(field, asc, list) {
	return list.sort(function(a, b) {
		let itemA, itemB;

		switch(field) {
			case "MANAGER_ID":
				itemA = empty(a.MANAGER) ? "" : a.MANAGER.first_name.toLowerCase() + a.MANAGER.last_name.toLowerCase();
				itemB = empty(b.MANAGER) ? "" : b.MANAGER.first_name.toLowerCase() + b.MANAGER.last_name.toLowerCase();
				break;
			case "ACCOUNT_MANAGER":
				itemA = empty(a.ACCOUNT_MANAGER) ? "" : a.ACCOUNT_MANAGER.first_name.toLowerCase() + a.ACCOUNT_MANAGER.last_name.toLowerCase();
				itemB = empty(b.ACCOUNT_MANAGER) ? "" : b.ACCOUNT_MANAGER.first_name.toLowerCase() + b.ACCOUNT_MANAGER.last_name.toLowerCase();
				break;
			case "LEAD_ID":
				itemA = empty(a.LEAD) ? "" : a.LEAD.first_name.toLowerCase() + a.LEAD.last_name.toLowerCase();
				itemB = empty(b.LEAD) ? "" : b.LEAD.first_name.toLowerCase() + b.LEAD.last_name.toLowerCase();
				break;
			case "CONSULTANT_ID":
				itemA = empty(a.CONSULTANT) ? "" : a.CONSULTANT.first_name.toLowerCase() + a.CONSULTANT.last_name.toLowerCase();
				itemB = empty(b.CONSULTANT) ? "" : b.CONSULTANT.first_name.toLowerCase() + b.CONSULTANT.last_name.toLowerCase();
				break;
			case "CLIENT_ID":
				itemA = empty(a.CLIENT) ? "" : a.CLIENT.ABBREV.toLowerCase();
				itemB = empty(b.CLIENT) ? "" : b.CLIENT.ABBREV.toLowerCase();
				break;
			case "PROJECT_ID":
				itemA = empty(a.PROJECT) ? "" : a.PROJECT.NAME.toLowerCase();
				itemB = empty(b.PROJECT) ? "" : b.PROJECT.NAME.toLowerCase();
				break;
			case "START_DATE":
				itemA = empty(a.START_DATE) ? moment.utc('3000-01-01') : moment.utc(a.START_DATE);
				itemB = empty(b.START_DATE) ? moment.utc('3000-01-01') : moment.utc(b.START_DATE);
				break;
			case "END_DATE":
				itemA = empty(a.END_DATE) ? moment.utc('3000-01-01') : moment.utc(a.END_DATE);
				itemB = empty(b.END_DATE) ? moment.utc('3000-01-01') : moment.utc(b.END_DATE);
				break;
			default:
				itemA = typeof a[field] === "string" ? (a[field]).toLowerCase() : a[field];
				itemB = typeof b[field] === "string" ? (b[field]).toLowerCase() : b[field];
		}

		if(asc) {
			if(itemA < itemB) { return -1; }
			if(itemA > itemB) { return 1; }
			return 0;
		} else {
			if(itemA < itemB) { return 1; }
			if(itemA > itemB) { return -1; }
			return 0;
		}
	});
}