import React, {Component} from 'react';
import InputMask from 'react-input-mask';

import {TextField} from '@material-ui/core';

class PhoneInput extends Component {

	onChange = e => {
		e.preventDefault();
		this.props.onChange({target: {name: this.props.name, value: e.target.value.replace(/\D+/g, '')}});
	}

	render() {
		return (
			<InputMask
				mask="(999) 999-9999"
			  value={this.props.value}
			  onChange={this.onChange}
			  maskChar="_"
			>
				{() =>
					<TextField 
						label="Phone" 
						fullWidth
						error={this.props.error} 
						helperText={this.props.helperText}
						margin="dense"
						type="text"
						style={this.props.style}
					/>
				}
			</InputMask>
		)
	}
}

export default PhoneInput;
