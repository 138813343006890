import React, {Component} from 'react';
import {Delete, PieChart} from '@material-ui/icons';

class ArchiveBtns extends Component {
  render() {

  	return(
			<td data-container="body" className="table-data" >
				<div className='archive-btns-outer-div'>
					<div className='archive-btns-div'>
						<div className='archive-row-btn' style={{background: '#5cb85c'}} onClick={this.props.onRecover}><PieChart style={{color: "white", fontSize: "1.25rem", marginLeft: "0.1rem"}}/></div>
						<div className='archive-row-btn' style={{background: '#d9534f'}} onClick={this.props.onDelete}><Delete style={{color: "white", fontSize: "1.25rem", marginLeft: "0.1rem"}}/></div>
					</div>
				</div>
			</td>
  	);
  }
}
 
export default ArchiveBtns;