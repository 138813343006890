import React, {Component} from 'react';

import {Checkbox, FormControlLabel, Modal, Button} from "@material-ui/core";

class InvoicePopup extends Component {

  render() {
  	const {project} = this.props;

  	return(
  		<Modal open onClose={this.props.onHide} style={{display: "flex", height: "100vh", width: "100vw", justifyContent: "center", alignItems: "center"}}>
  			<div style={{background: "white", fontSize: "1.2rem", display: "flex", flexDirection: "column", borderRadius: "6px", minWidth: "30rem", padding: "1.5rem"}}>
  				<span style={{fontSize: "1.5rem", fontWeight: 400}}>Select supporting information that is required <br/>with the invoice.</span>
					<div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
			      <FormControlLabel
			        control={<Checkbox color="primary" checked={project.TIME_DETAIL} onChange={(e) => this.props.onUpdate({...project, TIME_DETAIL: e.target.checked})} />}
			        label="Time Detail"
			      />
			      <FormControlLabel
			        control={<Checkbox color="primary" checked={project.EXPENSE_DETAIL} onChange={(e) => this.props.onUpdate({...project, EXPENSE_DETAIL: e.target.checked})} />}
			        label="Expense Detail"
			      />
			      <FormControlLabel
			        control={<Checkbox color="primary" checked={project.BILLING_HISTORY} onChange={(e) => this.props.onUpdate({...project, BILLING_HISTORY: e.target.checked})} />}
			        label="Billing History"
			      />
					</div>
					<div style={{width: "100%", display: "flex", justifyContent: "flex-end"}}>
						<Button onClick={this.props.onHide} variant="contained" style={{backgroundColor: "red", color: "white"}}>Close</Button>
					</div>
  			</div>
  		</Modal>
  	);
  }
}

export default InvoicePopup;
