import {GET_PROJECT_OPTIONS} from '../../actionTypes';

const projectOptions = (state = [], action) => {
	switch(action.type) {
		case GET_PROJECT_OPTIONS:
			return [...action.payload];
		default:
			return state;
	}
}

export default projectOptions;