import {combineReducers} from 'redux';
import errors from './utils/errors';
import clients from './tables/clients';
import consultants from './tables/consultants';
import terms from './tables/terms';
import professionals from './tables/professionals';
import projects from './tables/projects';
import sponsors from './tables/sponsors';
import panel from './utils/panel';
import user from './utils/user';
import client_projects from './tables/client_projects';
import db_data from './utils/db_data';
import page from './utils/page';
import keys from './tables/keys';
import clientOptions from './options/clientOptions';
import sponsorOptions from './options/sponsorOptions';
import professionalOptions from './options/professionalOptions';
import projectOptions from './options/projectOptions';

const rootReducer = combineReducers({
	errors,
	clients,
	consultants,
	professionals,
	projects,
	panel,
	keys,
	user,
	client_projects,
	db_data,
	page,
	sponsors,
  terms,
	clientOptions,
	sponsorOptions,
	professionalOptions,
	projectOptions
});

export default rootReducer;
