import {apiCall} from '../../../services/api';

import {GET_PROFESSIONALS, ADD_PROFESSIONAL, UPDATE_PROFESSIONAL, REMOVE_PROFESSIONAL, GET_PROFESSIONAL_OPTIONS} from '../../actionTypes';

import {DB_ERROR} from '../utils/errors';
import {hide_panel} from '../utils/panel';

export const state_get_professionals = payload => ({
	type: GET_PROFESSIONALS,
	payload
});

export const state_add_professional = payload => ({
	type: ADD_PROFESSIONAL,
	payload
});

export const state_update_professional = payload => ({
	type: UPDATE_PROFESSIONAL,
	payload
});

export const state_remove_professional = payload => ({
	type: REMOVE_PROFESSIONAL,
	payload
});

export const state_get_professional_options = payload => ({
	type: GET_PROFESSIONAL_OPTIONS,
	payload
});

export const db_get_yearly_professionals = year => dispatch => {
	return apiCall('get', `/api/professionals/year/${year}`)
	.then(function(res) {
		dispatch(state_get_professionals(res));
	})
	.catch(function(err) {
		dispatch(DB_ERROR(err));
	});
}

export const db_return_professional = id => {
	return apiCall('get', `/api/professionals/${id}`)
	.then(function(res) {
		return {complete: true, data: res};
	})
	.catch(function(err) {
		return {complete: false};
	});
}

export const db_get_professional_dropdown = year => dispatch => {
	return apiCall('get', `/api/dropdowns/professionals/${year}`)
	.then(function(res) {
		dispatch(state_get_professional_options(res));
	})
	.catch(function(err) {
		dispatch(DB_ERROR(err));
	});
}

export const db_update_professional = professional => dispatch => {
	const id = professional.ID;
	return apiCall('put', `/api/professionals/${id}`, professional)
	.then(function(res) {
		dispatch(state_update_professional(res));
		return {complete: true};
	})
	.catch(function(err) {
		return {complete: false, error: err.data.error};
	});
}

export const db_remove_professional = professional => dispatch => {
	const id = professional.ID;
	return apiCall('delete', `/api/professionals/${id}`)
	.then(function(res) {
		dispatch(state_remove_professional(professional));
		return {complete: true};
	})
	.catch(function(err) {
		return {complete: false, error: err.data.error};
	})
}

export const db_add_professional = (professional, currentYear) => dispatch => {
	return apiCall('post', `/api/professionals`, professional)
	.then(function(res) {
		if(currentYear === professional.YEAR) {
			dispatch(state_add_professional(res));
		}
		dispatch(hide_panel());
		return {complete: true};
	})
	.catch(function(err) {
		return {complete: false, error: err.data.error};
	});
}