import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';

import {set_page} from '../../store/actions/utils/page';
import {set_db_data} from '../../store/actions/utils/db_data';

import MultiSeriesBar from './MultiSeriesBar';
import Loading from '../../components/Graphics/Loading';

class Charts extends Component {
	constructor(props){
		super(props);
		this.state = {
			loaded: false,
			sort: 'NAME'
		}
	}

	async componentDidMount() {
		await this.props.set_page("consultant-charts");
		await this.props.set_db_data({...this.props.db_data, title: "Charts", sort_btn: false, add_btn: false, search_btn: false, export_btn: false, filter_btn: false});
		this.setState({loaded: true});
	}

	getData = async () => {
		this.setState({loaded: false});
	}

	render() {
		const {loaded} = this.state;

		return (
			<Fragment>
				{!loaded && (
					<Loading />
				)}
				{loaded && (
					<div className="container1" style={{padding: "1rem"}}>
						<MultiSeriesBar />
					</div>
				)}
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		db_data: state.db_data
	};
}

export default connect(mapStateToProps, {set_db_data, set_page})(Charts);



