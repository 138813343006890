import React, {Component, Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import moment from 'moment';
import empty from 'is-empty';
import {confirmAlert} from 'react-confirm-alert';
import withToast from '../../utils/withToast';

import {TextField, Grid, MenuItem} from '@material-ui/core';

import {db_update_project, db_remove_project, db_archive_project, db_add_project} from '../../store/actions/tables/projects';
import {get_contract} from '../../store/actions/peripherals/documents';
import {db_return_professional, db_get_professional_dropdown} from '../../store/actions/tables/professionals';
import {db_update_docusign_id, db_add_contract} from '../../store/actions/peripherals/contracts';
import {db_return_consultant} from '../../store/actions/tables/consultants';
import {db_return_client_project, db_get_project_dropdown} from '../../store/actions/tables/client_projects';
import {hide_panel} from '../../store/actions/utils/panel';

import {consultant_options} from '../../utils/options';
import {stringifyNumber} from '../../utils/stringifyNumber';

import DatePicker from "../../components/Inputs/DatePicker";
import BillingBlock from '../../components/Blocks/BillingBlock';
import ExpensesBlock from '../../components/Blocks/ExpensesBlock';
import DownloadBtn from '../../components/Buttons/DownloadBtn';
import SOWBtn from '../../components/Buttons/SOWBtn';
import SaveBtn from '../../components/Buttons/SaveBtn';
import DeleteBtn from '../../components/Buttons/DeleteBtn';
import ContractError from '../../components/Graphics/ContractError';
import ModalLoading from '../../components/Graphics/ModalLoading';
import DownloadAmendmentPopup from '../../components/Popup/DownloadAmendmentPopup';

class ProjectDetail extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loaded: false,
			loading: false,
			selectedProject: props.edit ? {value: props.currentProject.PROJECT_ID, label: props.currentProject.PROJECT.NAME, START_DATE: props.currentProject.PROJECT.START_DATE, END_DATE: props.currentProject.PROJECT.END_DATE} : null,
			data: {
				ID: null,
				CONSULTANT_ID: null,
				CLIENT_ID: null,
				PROJECT_ID: null,
				START_DATE: null,
				END_DATE: null,
				HOURS: '',
				PAY: '',
				BILL: '',
				NOTES: '',
				SOW: '',
				TNE: '',
				TYPE: '',
				EXPENSES_TYPE: '',
				EXPENSES_DESC: '',
				EXPENSES_AMOUNT: '',
				PROFESSIONAL: '',
				SPRINGAHEAD_ID: '',
				DOCUSIGN_ID: ''
			},
			newBill: {
				ID: '',
				NAME: '',
				BILL: '',
				_90: '',
				_85: '',
				_80: ''
			},
			validation: {
				dates: true,
				projectStartDate: true,
				projectEndDate: true
			},
			toContract: false,
			consultantOptions: consultant_options(props.consultants),
			downloadPopup: false,
			amendmentOptions: [],
			attemptedSubmit: false,
			concurrentAssignment: false,
		}
	}

	async componentDidUpdate(prevProps, prevState) {
		if(this.state.data.CLIENT_ID !== prevState.data.CLIENT_ID) {
			this.props.db_get_project_dropdown(this.state.data.CLIENT_ID);
		}
		if(!this.props.edit && ((this.state.data.PROJECT_ID !== prevState.data.PROJECT_ID) || (this.state.data.CONSULTANT_ID !== prevState.data.CONSULTANT_ID) || (this.state.data.START_DATE !== prevState.data.START_DATE))) {
			const s = this.state;
			const concurrents = this.props.projects.filter(function(p){
				return ((p.PROJECT_ID == s.data.PROJECT_ID) && (p.CONSULTANT_ID == s.data.CONSULTANT_ID) && (moment.utc(p.END_DATE).isAfter(moment.utc(s.data.START_DATE))))
			});

			this.setState({
				...this.state,
				concurrentAssignment: !empty(concurrents)
			});
		}
		if(this.state.data.PROJECT_ID !== prevState.data.PROJECT_ID) {
			const project = await db_return_client_project(this.state.data.PROJECT_ID);
			this.setState({...this.state, selectedProject: project.complete ? project.data : {}, data: {...this.state.data, TYPE: !this.props.edit ? project.complete ? project.data.TYPE : "" : this.state.data.TYPE, START_DATE: !this.props.edit ? project.complete ? project.data.START_DATE : "" : this.state.data.START_DATE, END_DATE: !this.props.edit ? project.complete ? project.data.END_DATE : "" : this.state.data.END_DATE}});
			if(project.complete && !this.props.edit) {
				if(!empty(project.data.START_DATE)) moment.utc(project.data.START_DATE).format('YYYY') < 2019 ? this.props.db_get_professional_dropdown('2019') : this.props.db_get_professional_dropdown(moment.utc(project.data.START_DATE).format('YYYY'));
			}
		}
		if(!this.props.edit && (this.state.data.TNE !== prevState.data.TNE)) {
			this.setState({...this.state, data: {...this.state.data, SOW: this.state.data.TNE === "Expenses Only"}});
		}
		if(!this.props.edit && (prevState.data.TYPE !== this.state.data.TYPE)) {
			if(this.state.data.TYPE === "Fixed Price") {
				const op = this.props.professionalOptions.filter(option => option.label === "Fixed Price: $0");
				this.setState({
					...this.state,
					data: {
						...this.state.data,
						PROFESSIONAL: empty(op) ? null : op[0].value,
						BILL: 0,
						PAY: 100
					}
				});
			} else {
				this.setState({
					...this.state,
					data: {
						...this.state.data,
						PROFESSIONAL: null,
						BILL: '',
						PAY: ''
					}
				});
			}
		}
	}

	async componentDidMount() {
		if(this.props.edit) {
			const {currentProject} = this.props;
			moment.utc(currentProject.START_DATE).format('YYYY') < 2019 ? await this.props.db_get_professional_dropdown('2019') : await this.props.db_get_professional_dropdown(moment.utc(this.props.currentProject.START_DATE).format('YYYY'));
			const proResp = await db_return_professional(currentProject.PROFESSIONAL);
			const pro = proResp.complete ? proResp.data : this.state.newBill;
			this.setState({
				...this.state,
				data: currentProject,
				newBill: pro,
				validation: {
					...this.state.validation,
					required: !empty(currentProject.CLIENT_ID) && !empty(currentProject.PROJECT_ID) && !empty(currentProject.CONSULTANT_ID) && !empty(currentProject.START_DATE) && !empty(currentProject.PROFESSIONAL)
				},
				amendmentOptions: empty(currentProject.AMENDMENTS) ? [] : currentProject.AMENDMENTS.map((amendment, i) => ({value: amendment.DOCUSIGN_ID, label: stringifyNumber(i + 1) + " Amendment"})),
				loaded: true
			});
		} else if(this.props.role === "Utilicast") {
			this.setState({
				...this.state,
				data: {
					...this.state.data,
					CLIENT_ID: '156944'
				},
				loaded: true
			});
		} else {
			this.setState({
				...this.state,
				loaded: true
			});
		}
	}

	handleChange = async e => {
		await this.setState({
			...this.state,
			data: {
				...this.state.data,
				[e.target.name]: e.target.value
			}
		});
	}

	handleProfessionalChange = async e => {
		if(!empty(e.target.value)) {
			const resp = await db_return_professional(e.target.value);
			const pro = resp.complete ? resp.data : this.state.newBill;
			this.setState({
				...this.state,
				newBill: pro,
				data: {
					...this.state.data,
					PROFESSIONAL: e.target.value,
					BILL: pro.BILL,
					PAY: ''
				}
			})
		} else {
			this.setState({
				...this.state,
				data: {
					...this.state.data,
					PROFESSIONAL: ''
				}
			})
		}
	}

	handleExpensesChange = values => {
		this.setState({
			...this.state,
			data: {
				...this.state.data,
				...values
			}
		});
	}

	handlePayChange = pay => {
		if(pay !== null) {
			this.setState({
				...this.state,
				data: {
					...this.state.data,
					PAY: Number(pay)
				}
			})
		} else {
			this.setState({
				...this.state,
				data: {
					...this.state.data,
					PAY: null
				}
			})
		}
	}

	handleStartDateChange = (value: any) => {
		const afterProjectStart = empty(value) || empty(this.state.selectedProject.START_DATE) || (moment.utc(value).format('YYYY-MM-DD') === moment.utc(this.state.selectedProject.START_DATE).format('YYYY-MM-DD') || moment.utc(value).isAfter(moment.utc(this.state.selectedProject.START_DATE))) ? true : false;
    this.setState({
    	...this.state,
    	data: {
				...this.state.data,
				START_DATE: empty(value) ? '' : moment.utc(value).format("YYYY-MM-DD")
			},
			validation: {
				...this.state.validation,
				projectStartDate: afterProjectStart
			}
  	});
  	if(!empty(value)) {
  		moment.utc(value).format('YYYY') < 2019 ? this.props.db_get_professional_dropdown('2019') : this.props.db_get_professional_dropdown(moment.utc(value).format('YYYY'));
  	}
  };

  handleEndDateChange = (value: any) => {
    this.setState({
    	...this.state,
    	data: {
				...this.state.data,
				END_DATE: empty(value) ? '' : moment.utc(value).format("YYYY-MM-DD")
			},
			validation: {
				...this.state.validation,
				dates: empty(value) || moment.utc(value).isAfter(moment.utc(this.state.data.START_DATE)) ? true : false,
				projectEndDate: empty(value) || empty(this.state.selectedProject.END_DATE) || (moment.utc(value).format('YYYY-MM-DD') === moment.utc(this.state.selectedProject.END_DATE).format('YYYY-MM-DD') || moment.utc(value).isBefore(moment.utc(this.state.selectedProject.END_DATE))) ? true : false
			}
  	});
  };

  downloadContract = async () => {
  	this.setState({...this.state, loading: true});
  	await this.props.get_contract(this.props.currentProject.CONTRACT.DOCUSIGN_ID);
  	this.setState({...this.state, loading: false});
  }

	handlePut = async () => {
		this.setState({...this.state, loading: true});
		const resp = await this.props.db_update_project(this.state.data);
		if(resp.complete) {
			this.props.addToast("Assignment successfully updated.", {appearance: "success", autoDismiss: true});
			this.props.onHide();
		} else {
			this.props.addToast(resp.error, {appearance: "error", autoDismiss: true});
		}
		this.setState({...this.state, loading: false});
	}

	handleAdd = async () => {
		this.setState({...this.state, loading: true});
		const resp = await this.props.db_add_project(this.state.data);
		if(resp.complete) {
			this.props.addToast("Assignment successfully added.", {appearance: "success", autoDismiss: true});
			confirmAlert({
	      title: 'Next',
	      message: 'Do you want to create the statement of work now?',
	      buttons: [
	        {
	          label: 'Draft SOW',
	          onClick: () => this.props.history.push(`/assignments/${this.props.role === "Admin" ? "admin" : "am"}/${resp.ID}`)
	        },
	        {
	          label: 'Close',
	          onClick: () => {
	          	this.props.hide_panel();
	          }
	        }
	      ]
	    });
		} else {
			this.props.addToast(resp.error, {appearance: "error", autoDismiss: true});
		}
		this.setState({...this.state, loading: false});
	}

	handleRemove = async () => {
		this.setState({...this.state, loading: true});
		const resp = await this.props.db_archive_project({...this.state.data, ARCHIVE: 1});
		if(resp.complete) {
			this.props.addToast("Assignment successfully removed.", {appearance: "success", autoDismiss: true});
		} else {
			this.props.addToast(resp.error, {appearance: "error", autoDismiss: true});
		}
		this.setState({...this.state, loading: false});
	}

	handleToContract = async () => {
		if(!empty(this.props.currentContract)) {if(this.props.currentContract.DOCUSIGN_ID !== this.state.data.DOCUSIGN_ID) await this.props.db_update_docusign_id({ID: this.state.data.SPRINGAHEAD_ID, DOCUSIGN_ID: this.state.data.DOCUSIGN_ID})};
		if(!empty(this.state.data.SPRINGAHEAD_ID)) await this.props.db_update_project(this.state.data);
		this.setState({...this.state, toContract: true});
	}

	confirmRemove = () => {
  	confirmAlert({
      title: 'Confirm Archive',
      message: 'Are you sure you want to archive this assignment?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.handleRemove()
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  }

  confirmRatesChange = () => {
  	confirmAlert({
      title: 'Confirm Rates Change',
      message: 'You have modified the bill or pay rate which can have unintended consequences in SpringAhead. Are you sure you want to do this?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.handlePut()
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  }

	render() {
		const {currentProject, role, projectOptions, clientOptions, professionalOptions, edit} = this.props;
		const {data, selectedProject, consultantOptions, validation, attemptedSubmit, concurrentAssignment, loaded, loading} = this.state;

		const contractDownloadDisplay = edit ? !empty(currentProject.CONTRACT) ? !empty(currentProject.CONTRACT.DOCUSIGN_ID) : false : false;
		const amendmentDownloadDisplay = edit ? !empty(currentProject.AMENDMENTS) : false;
		const route = role === "Admin" || role === "Utilicast" ? '/assignments/admin/' : '/assignments/am/';
		const amendRoute = role === "Admin" ? '/amendment/admin/' : '/amendment/am/';

		const projectCheck = empty(selectedProject) ? false : !empty(selectedProject.CLIENT_ID) && !empty(selectedProject.DESCRIPTION) && !empty(selectedProject.START_DATE) && !empty(selectedProject.END_DATE) && !empty(selectedProject.MANAGER_ID) && !empty(selectedProject.PRACTICE_AREAS) && !empty(selectedProject.SPONSORS) && !empty(selectedProject.TYPE) && !empty(selectedProject.VALUE);

		const required = !empty(data.CLIENT_ID) && !empty(data.PROJECT_ID) && !empty(data.CONSULTANT_ID) && !empty(data.START_DATE) && (!empty(data.PROFESSIONAL) || data.TNE === "Expenses Only");
		const validated = required && validation.dates && validation.projectStartDate && validation.projectEndDate;

		const contractValidation = !empty(data.CLIENT_ID) && !empty(data.PROJECT_ID) && !empty(data.CONSULTANT_ID) && !empty(data.START_DATE) && !empty(data.PROFESSIONAL) && !empty(data.END_DATE) && !empty(data.HOURS) && !empty(data.PAY) && !empty(data.BILL);

		return (
			<Fragment>
				{this.state.downloadPopup && (<DownloadAmendmentPopup text={"Choose Amendment"} onHide={() => this.setState({...this.state, downloadPopup: false})} onDownload={this.props.get_contract} options={this.state.amendmentOptions} setState={(body) => this.setState({...this.state, ...body})}/>)}
				{loading && (<ModalLoading />)}
				{loaded && (<div className="form">
					{concurrentAssignment && (<div style={{background: "red", margin: "0 1rem", padding: "1rem", borderRadius: "6px", color: "white", fontSize: "1.1rem"}}>This consultant can not be assigned to the same project during overlapping periods.</div>)}
					<form autoComplete="off">
						<ContractError show={this.state.contractMessage} />
						<Grid container item xs={12}>
							<Grid container item direction="column" xs={12} sm={6} style={{padding: "0 1rem"}}>
								<TextField
				          select
				          fullWidth
				          required
				          error={empty(data.CLIENT_ID) && attemptedSubmit}
									helperText={(empty(data.CLIENT_ID) && attemptedSubmit) ? "This field is required." : ""}
				          label="Client"
				          margin="dense"
				          value={data.CLIENT_ID}
				          onChange={this.handleChange}
				          name="CLIENT_ID"
				          disabled={edit}
				        >
				          {clientOptions.map((option) => (
				            <MenuItem key={option.value} value={option.value}>
				              {option.label}
				            </MenuItem>
				          ))}
				        </TextField>
								<TextField
				          select
				          fullWidth
				          required
				          error={empty(data.PROJECT_ID) && attemptedSubmit}
									helperText={(empty(data.PROJECT_ID) && attemptedSubmit) ? "This field is required." : ""}
				          label="Project Name"
				          margin="dense"
				          value={data.PROJECT_ID}
				          onChange={this.handleChange}
				          name="PROJECT_ID"
				          disabled={edit || projectOptions.length === 0}
				        >
				          {projectOptions.map((option) => (
				            <MenuItem key={option.value} value={option.value}>
				              {option.label}
				            </MenuItem>
				          ))}
				        </TextField>
								<TextField
				          select
				          required
				          fullWidth
				          error={empty(data.CONSULTANT_ID) && attemptedSubmit}
									helperText={(empty(data.CONSULTANT_ID) && attemptedSubmit) ? "This field is required." : ""}
				          label="Consultant"
				          margin="dense"
				          value={data.CONSULTANT_ID}
				          onChange={this.handleChange}
				          name="CONSULTANT_ID"
				          disabled={edit}
				        >
				          {consultantOptions.map((option) => (
				            <MenuItem key={option.value} value={option.value}>
				              {option.label}
				            </MenuItem>
				          ))}
				        </TextField>
								<DatePicker value={data.START_DATE} label={empty(data.PROJECT_ID) || empty(selectedProject) ? "Start Date" : empty(selectedProject.START_DATE) ? "Start Date" : `Start Date (after ${moment.utc(selectedProject.START_DATE).format("YYYY-MM-DD")})`} name="START_DATE" onChange={this.handleStartDateChange} disabled={empty(selectedProject) || contractDownloadDisplay} required error={(empty(data.START_DATE) && attemptedSubmit) || !validation.projectStartDate} errorMessage={(empty(data.START_DATE) && attemptedSubmit) ? "This field is required." : "The start date must be after the project start date."}/>
								<DatePicker value={data.END_DATE} label={empty(data.PROJECT_ID) || empty(selectedProject) ? "End Date" : empty(selectedProject.END_DATE) ? "End Date" : `End Date (before ${moment.utc(selectedProject.END_DATE).format("YYYY-MM-DD")})`} name="END_DATE" onChange={this.handleEndDateChange} disabled={empty(selectedProject) || contractDownloadDisplay} error={!validation.dates || !validation.projectEndDate} errorMessage={!validation.dates ? "The end date must be after the start date." : "The end date must be before the project end date."}/>
								<TextField
				          select
				          fullWidth
				          label="Type"
				          margin="dense"
				          value={data.TYPE}
				          onChange={this.handleChange}
				          name="TYPE"
				        >
				          {[{value: "Fixed Price", label: "Fixed Price"}, {value: "T&M", label: "T&M"}].map((option) => (
				            <MenuItem key={option.value} value={option.value}>
				              {option.label}
				            </MenuItem>
				          ))}
				        </TextField>
							</Grid>
							<Grid container item direction="column" xs={12} sm={6} style={{padding: "0 1rem"}}>
								<TextField
				          select
				          fullWidth
				          label={`${data.TYPE === "Fixed Price" ? "Units" : "Time"} / Expenses`}
				          margin="dense"
				          value={data.TNE}
				          onChange={this.handleChange}
				          name="TNE"
				        >
				          {[{value: "Time and Expenses", label: `${data.TYPE === "Fixed Price" ? "Units" : "Time"} and Expenses`}, {value: "Time Only", label: `${data.TYPE === "Fixed Price" ? "Units" : "Time"} Only`}, {value: "Expenses Only", label: "Expenses Only"}].map((option) => (
				            <MenuItem key={option.value} value={option.value}>
				              {option.label}
				            </MenuItem>
				          ))}
				        </TextField>
								{(data.TNE === "Time and Expenses" || data.TNE === "Time Only") && (<Fragment>
									<TextField
										label={data.TYPE === "Fixed Price" ? "Units" : "Hours"}
										fullWidth
										margin="dense"
										type="number"
										value={data.HOURS}
										disabled={role !== "Admin" && contractDownloadDisplay}
										name="HOURS"
										onChange={this.handleChange}
									/>
									<BillingBlock
										contractDownloadDisplay={role !== "Admin" && contractDownloadDisplay}
										disabled={empty(data.START_DATE) ? true : false}
										professionalOptions={professionalOptions}
										handleProfessionalChange={this.handleProfessionalChange}
										onChangePay={this.handlePayChange}
										data={data}
										handleChange={this.handleChange}
										newBill={this.state.newBill}
										attemptedSubmit={attemptedSubmit}
									/>
								</Fragment>)}
								{(data.TNE === "Time and Expenses" || data.TNE === "Expenses Only") && (
									<ExpensesBlock
										contractDownloadDisplay={role !== "Admin" && contractDownloadDisplay}
										onDescriptionChange={this.handleDescriptionChange}
										onChange={this.handleExpensesChange}
										edit={edit}
										data={data}
									/>
								)}
								<TextField
									label="Notes"
									fullWidth
									multiline
									rows="6"
									rowsMax="10"
									type="text"
									value={data.NOTES}
									name="NOTES"
									onChange={this.handleChange}
								/>
							</Grid>
						</Grid>
						<div className="col-xs-12"></div>
						{edit && !projectCheck && (<div style={{width: "100%", display: "flex", justifyContent: "flex-end", marginTop: "0.5rem", padding: "0 1rem"}}>
							<span style={{fontSize: "1rem", color: "red"}}>You must have a complete project record for {empty(selectedProject) ? "" : selectedProject.NAME} before drafting a Statement of Work.</span>
						</div>)}
						<div className="form-commands">
							<div className="btn-group">
								<SaveBtn onClick={!validated ? () => this.setState({...this.state, attemptedSubmit: true}) : edit ? (this.props.currentProject.PAY != data.PAY || this.props.currentProject.BILL != data.BILL) ? this.confirmRatesChange : this.handlePut : this.handleAdd} text="Save" disabled={!validated || concurrentAssignment}/>
								{role === 'Admin' && (<DeleteBtn onClick={edit ? this.confirmRemove : this.props.handleCancel} text={edit ? "Archive" : "Cancel"} delete={edit ? true : false} style={{marginLeft: "1.5rem"}}/>)}

								{!data.SOW && edit && role === "Admin" && (<div style={{marginLeft: "1.5rem"}}><SaveBtn onClick={e => {e.preventDefault(); this.props.db_update_project({...data, SOW: 1}); this.setState({...this.state, data: {...data, SOW: true}})}} text="SOW to YES"/></div>)}
							</div>
							{edit && !data.SOW && !contractDownloadDisplay && (<Fragment>
								<SOWBtn text="Draft Statement of Work" redirect={this.state.toContract} onSave={this.handleToContract} route={{pathname: route + currentProject.ID}} color="#01579B" disabled={!contractValidation || !projectCheck} onClick={() => {this.setState({...this.state, contractMessage: true})}}/>
							</Fragment>)}
							{edit && contractDownloadDisplay && (<div className="btn-group">
								{data.SOW && (<SOWBtn text="Amend SOW" redirect={this.state.toContract} onSave={this.handleToContract} route={{pathname: amendRoute + currentProject.ID}} color="#F6B924"/>)}
								{amendmentDownloadDisplay && (<DownloadBtn text="Amended SOW" color="#01579B" onClick={() => this.setState({...this.state, downloadPopup: true})} style={{marginLeft: "1.5rem"}}/>)}
								{contractDownloadDisplay && (<DownloadBtn text="Statement of Work" color="#01579B" onClick={this.downloadContract} style={{marginLeft: "1.5rem"}}/>)}
							</div>)}
						</div>
            {/* <div className="text-muted heading2 text-right">
              Contracts prior to x/xx/xxxx will not be accessible. Contact <a href="mailto:legal@utilicast.com">legal@utilicast.com</a> to obtain a copy.
            </div> */}
					</form>
				</div>)}
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		errors: state.errors,
		consultants: state.consultants,
		clientOptions: state.clientOptions,
		professionalOptions: state.professionalOptions,
		projectOptions: state.projectOptions,
		projects: state.projects
	};
}

export default connect(mapStateToProps, {
	db_return_consultant,
	db_update_docusign_id,
	db_get_professional_dropdown,
	db_get_project_dropdown,
	db_add_project,
	db_update_project,
	db_remove_project,
	get_contract,
	db_archive_project,
	db_add_contract,
	hide_panel
})(withRouter(withToast(ProjectDetail)));
