import React, {Component} from 'react';
import {TextField} from '@material-ui/core';

class AddressBlock extends Component {

	render(){
		const {data} = this.props;
		return (
			<div className="address-div">
				<div className="address-side">
					<div className="address-item">
						<TextField 
							label="Address" 
							fullWidth
							type="text" 
							margin="dense" 
							value={data.ADDRESS} 
							name="ADDRESS" 
							onChange={this.props.onChange}
						/>
					</div>
					<div className="address-item">
						<TextField 
							label="City" 
							fullWidth
							type="text" 
							margin="dense" 
							value={data.CITY} 
							maxLength="20"
							name="CITY" 
							onChange={this.props.onChange}
						/>
					</div>
				</div>
				<div className="address-side">
					<div className="address-item-3">
						<TextField 
							label="State" 
							fullWidth
							type="text" 
							margin="dense" 
							value={data.STATE} 
							name="STATE" 
							onChange={this.props.onChange}
						/>
					</div>
					<div className="address-item-3">
						<TextField 
							label="Zip" 
							fullWidth
							type="text" 
							margin="dense" 
							value={data.ZIP} 
							maxLength="10"
							name="ZIP" 
							onChange={this.props.onChange}
						/>
					</div>
					<div className="address-item-3">
						<TextField 
							label="Country" 
							fullWidth
							type="text" 
							margin="dense" 
							value={data.COUNTRY} 
							maxLength="30"
							name="COUNTRY" 
							onChange={this.props.onChange}
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default AddressBlock;