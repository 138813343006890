import {GET_CLIENTS, ADD_CLIENT, UPDATE_CLIENT, REMOVE_CLIENT} from '../../actionTypes';

const clients = (state = [], action) => {
	switch(action.type) {
		case GET_CLIENTS:
			return [...action.payload];
		case ADD_CLIENT:
			return [...state, action.payload];
		case UPDATE_CLIENT:
			return state.map(client => {
				if(action.payload.ID === client.ID) {
					return action.payload;
				} else {
					return client;
				}
			});
		case REMOVE_CLIENT:
			return state.filter(client => {
				return client.ID !== action.payload.ID;
			});
		default:
			return state;
	}
}

export default clients;