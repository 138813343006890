import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import withToast from '../../utils/withToast';

import {removeError} from '../../store/actions/utils/errors';
import {db_get_project_archives, db_archive_project, db_remove_project} from '../../store/actions/tables/projects';
import {db_get_client_projects} from '../../store/actions/tables/client_projects';
import {db_get_consultants} from '../../store/actions/tables/consultants';
import {db_get_clients} from '../../store/actions/tables/clients';
import {set_db_data} from '../../store/actions/utils/db_data';
import {set_page} from '../../store/actions/utils/page';

import {sortList} from '../../utils/sortList';
import {searchList} from '../../utils/searchList';

import ProjectRow from '../../components/Rows/ProjectRow';
import Loading from '../../components/Graphics/Loading';
import ModalLoading from '../../components/Graphics/ModalLoading';

class AssignmentArchives extends Component {
	constructor(props){
		super(props);
		this.state = {
			loaded: false,
			loading: false,
			sort: 'CLIENT_ID'
		}
	}

	async componentDidMount() {
		await this.props.set_page("assignment-archives");
		await this.props.db_get_project_archives();
		await this.props.set_db_data({...this.props.db_data, title: "Assignment Archives", sort_btn: true, add_btn: false, search_btn: true, export_btn: true, filter_btn: false});
		this.setState({loaded: true});
	}

	handleSortChange = e => {
		this.setState({
			...this.state,
			'sort': e.target.id
		});
	}

	onRecover = async project => {
		this.setState({...this.state, loading: true});
		const resp = await this.props.db_archive_project({...project, ARCHIVE: 0});
		if(resp.complete) {
			this.props.addToast('Successfully recovered.', {appearance: "success", autoDismiss: true});
		} else {
			this.props.addToast(resp.error, {appearance: "error", autoDismiss: true});
		}
		this.setState({...this.state, loading: false});
	}
	
	onDelete = async project => {
		this.setState({...this.state, loading: true});
		const resp = await this.props.db_remove_project(project);
		if(resp.complete) {
			this.props.addToast('Successfully deleted.', {appearance: "success", autoDismiss: true});
		} else {
			this.props.addToast(resp.error, {appearance: "error", autoDismiss: true});
		}
		this.setState({...this.state, loading: false});
	}

	render() {
		const {errors, projects, currentProject, consultants, clients, client_projects, role, db_data, user} = this.props;
		const {loading, loaded} = this.state;

		let roleList = projects;
		let list = sortList(this.state.sort, db_data.asc, searchList(db_data.search, roleList));

		let projectList = list.map(project => 
			<Fragment key={project.ID}>
				<ProjectRow
					project={project}
					consultants={consultants}
					clients={clients}
					client_projects={client_projects}
					currentProject={currentProject}
					role={role}
					archive={true}
					onRecover={this.onRecover}
					onDelete={this.onDelete}
					user={user}
				/>
			</Fragment>
		);

		return (
			<Fragment>
				{!loaded && (
					<Loading />
				)}
				{errors.message && (
					<div className="alert alert-danger">
						{errors.message}
					</div>
				)}
				{loading && (<ModalLoading />)}
				{loaded && role !== "Consultant" && (
					<div className="container1">
						<div className="project-table">
							<table className="table">
								<thead>
									<tr className="table-head">
										<th scope="col" className="table-title leftrow"><div onClick={this.handleSortChange} id="CLIENT_ID" className={this.state.sort === "CLIENT_ID" ? "sortSelect" : "sortTitle"}>Client</div></th>
										<th scope="col" className="table-title"><div onClick={this.handleSortChange} id="PROJECT_ID" className={this.state.sort === "PROJECT_ID" ? "sortSelect" : "sortTitle"}>Project Name</div></th>
										<th scope="col" className="table-title delete"><div onClick={this.handleSortChange} id="CONSULTANT_ID" className={this.state.sort === "CONSULTANT_ID" ? "sortSelect" : "sortTitle"}>Consultant</div></th>
										<th scope="col" className="table-title"><div onClick={this.handleSortChange} id="START_DATE" className={this.state.sort === "START_DATE" ? "sortSelect" : "sortTitle"}>Start Date</div></th>
										<th scope="col" className="table-title"><div onClick={this.handleSortChange} id="END_DATE" className={this.state.sort === "END_DATE" ? "sortSelect" : "sortTitle"}>End Date</div></th>
										<th scope="col" className="table-title">Hours</th>
										<th scope="col" className="table-title">Bill</th>
										<th scope="col" className="table-title delete">Pay</th>
										<th scope="col" className="table-title delete"><div onClick={this.handleSortChange} id="SOW" className={this.state.sort === "SOW" ? "sortSelect" : "sortTitle"}>SOW?</div></th>
										<th scope="col" className="table-title">Status</th>
										<th scope="col" className="table-title status"></th>
									</tr>
								</thead>
								<tbody className="table-body">
									{projectList}
								</tbody>
							</table>
						</div>
					</div>
				)}
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		projects: state.projects,
		client_projects: state.client_projects,
		currentProject: state.currentProject,
		consultants: state.consultants,
		clients: state.clients,
		professionals: state.professionals,
		errors: state.errors,
		db_data: state.db_data,
		user: state.user
	};
}

export default connect(mapStateToProps, {
		db_get_project_archives,
		db_remove_project,
		db_archive_project,
		db_get_client_projects,
		db_get_consultants, 
		db_get_clients,
		removeError,
		set_db_data,
		set_page
	})(withToast(AssignmentArchives));




