import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {confirmAlert} from 'react-confirm-alert';

import {removeError} from '../../store/actions/utils/errors';
import {db_get_client_project_archives, db_archive_client_project, db_remove_client_project, db_get_candidate_project_archives} from '../../store/actions/tables/client_projects';
import {set_db_data} from '../../store/actions/utils/db_data';
import {set_page} from '../../store/actions/utils/page';

import {sortList} from '../../utils/sortList';
import {searchList} from '../../utils/searchList';
import withToast from '../../utils/withToast';

import ClientProjectRow from '../../components/Rows/Client_ProjectRow';
import Loading from '../../components/Graphics/Loading';
import ModalLoading from '../../components/Graphics/ModalLoading';

class ProjectArchives extends Component {
	constructor(props){
		super(props);
		this.state = {
			loaded: false,
			loading: false,
			sort: 'CLIENT_ID'
		}
	}

	async componentDidMount() {
		if(!this.props.candidate) {
			await this.props.set_page("project-archives");
			await this.props.db_get_client_project_archives();
			await this.props.set_db_data({...this.props.db_data, title: "Project Archives", add_btn: false, search_btn: true, export_btn: true, sort_btn: true, filter_btn: false});
		} else {
			await this.props.set_page("candidate-project-archives");
			await this.props.db_get_candidate_project_archives();
			await this.props.set_db_data({...this.props.db_data, title: "Candidate Project Archives", add_btn: false, search_btn: true, export_btn: true, sort_btn: true, filter_btn: false});
		}
		this.setState({loaded: true});
	}

	async componentWillReceiveProps(next) {
		if(this.props.candidate !== next.candidate) {
			this.setState({loaded: false});
			if(!next.candidate) {
				await this.props.set_page("project-archives");
				await this.props.db_get_client_project_archives();
				await this.props.set_db_data({...this.props.db_data, title: "Project Archives", add_btn: false, search_btn: true, export_btn: true, sort_btn: true, filter_btn: false});
			} else {
				await this.props.set_page("candidate-project-archives");
				await this.props.db_get_candidate_project_archives();
				await this.props.set_db_data({...this.props.db_data, title: "Candidate Project Archives", add_btn: false, search_btn: true, export_btn: true, sort_btn: true, filter_btn: false});
			}
			this.setState({loaded: true});
		}
	}

	handleSortChange = e => {
		this.setState({
			...this.state,
			'sort': e.target.id
		});
	}

	onRecover = async client_project => {
		this.setState({...this.state, loading: true});
		const resp = await this.props.db_archive_client_project({...client_project, ARCHIVE: 0, REASON: null, OTHER_REASON: null});
		if(resp.complete) {
			this.props.addToast("Project successfully recovered.", {appearance: "success", autoDismiss: true});
		} else {
			this.props.addToast(resp.error, {appearance: "error", autoDismiss: true});
		}
		this.setState({...this.state, loading: false});
	}
	onDelete = async client_project => {
		this.setState({...this.state, loading: true});
		const resp = await this.props.db_remove_client_project(client_project);
		if(resp.complete) {
			this.props.addToast("Project successfully deleted.", {appearance: "success", autoDismiss: true});
		} else {
			this.props.addToast(resp.error, {appearance: "error", autoDismiss: true});
		}
		this.setState({...this.state, loading: false});
	}

	confirmRemove = client_project => {
  	confirmAlert({
      title: 'Confirm Delete',
      message: 'Are you sure you want to permanently delete this project?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.onDelete(client_project)
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  }

	render() {
		const {errors, client_projects, role, db_data, user} = this.props;
		const {loaded, loading} = this.state;
		let roleList = client_projects;
		let list = this.props.role === "Admin" ? sortList(this.state.sort, db_data.asc, searchList(db_data.search, roleList)) : sortList(this.state.sort, db_data.asc, searchList(db_data.search, roleList));
		let projectList = list.map(client_project =>
			<Fragment key={client_project.ID}>
				<ClientProjectRow
					client_project={client_project}
					role={role}
					archive={true}
					onRecover={this.onRecover}
					onDelete={this.confirmRemove}
					user={user}
				/>
			</Fragment>
		);
		return (
			<Fragment>
				{!loaded && (
					<Loading />
				)}
				{loading && (<ModalLoading />)}
				{errors.message && (
					<div className="alert alert-danger">
						{errors.message}
					</div>
				)}
				{loaded && (
					<div className="container1">
						<div className="project-table">
							<table className="table">
								{role !== "Consultant" && (<thead>
									<tr className="table-head">
										<th scope="col" className="table-title leftrow"><div onClick={this.handleSortChange} id="CLIENT_ID" className={this.state.sort === "CLIENT_ID" ? "sortSelect" : "sortTitle"}>Client</div></th>
										<th scope="col" className="table-title"><div onClick={this.handleSortChange} id="NAME" className={this.state.sort === "NAME" ? "sortSelect" : "sortTitle"}>Project Name</div></th>
										<th scope="col" className="table-title">Client Manager</th>
										<th scope="col" className="table-title"><div onClick={this.handleSortChange} id="MANAGER_ID" className={this.state.sort === "MANAGER_ID" ? "sortSelect" : "sortTitle"}>Project Manager</div></th>
										<th scope="col" className="table-title"><div onClick={this.handleSortChange} id="START_DATE" className={this.state.sort === "START_DATE" ? "sortSelect" : "sortTitle"}>Start Date</div></th>
										<th scope="col" className="table-title"><div onClick={this.handleSortChange} id="END_DATE" className={this.state.sort === "END_DATE" ? "sortSelect" : "sortTitle"}>End Date</div></th>
										<th scope="col" className="table-title"><div onClick={this.handleSortChange} id="VALUE" className={this.state.sort === "VALUE" ? "sortSelect" : "sortTitle"}>Value</div></th>
										<th scope="col" className="table-title delete"><div onClick={this.handleSortChange} id="TYPE" className={this.state.sort === "TYPE" ? "sortSelect" : "sortTitle"}>Type</div></th>
										<th scope="col" className="table-title">Status</th>
										<th scope="col" className="table-title status"></th>
									</tr>
								</thead>)}
								<tbody className="table-body">
									{projectList}
								</tbody>
							</table>
						</div>
					</div>
				)}
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		client_projects: state.client_projects,
		consultants: state.consultants,
		errors: state.errors,
		db_data: state.db_data,
		user: state.user
	};
}

export default connect(mapStateToProps, {
		db_get_client_project_archives,
		db_archive_client_project,
		db_remove_client_project,
		db_get_candidate_project_archives,
		removeError,
		set_db_data,
		set_page
	})(withToast(ProjectArchives));
