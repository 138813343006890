import {apiCall} from '../../../services/api';
import empty from 'is-empty';

import {GET_TERMS} from '../../actionTypes';

import {DB_ERROR} from '../utils/errors';

export const state_get_terms = terms => ({
	type: GET_TERMS,
	terms
});

export const db_get_terms = () => dispatch => {
	return apiCall('get', '/api/springahead/terms')
	.then(function(res) {
    let terms = [];

    if(!empty(res.Terms)) {
      terms = Array.isArray(res.Terms.Term)
        ? res.Terms.Term
        : [{...res.Terms.Term}];
    }

		dispatch(state_get_terms(terms.filter(term => term.Active._text === 'true').map(term => ({Id: term.Id._text, Name: term.Name._text, DaysDue: term.DaysDue._text, Active: term.Active._text === 'true'}))));
	})
	.catch(function(err) {
		dispatch(DB_ERROR(err));
	});
}

export const db_return_term = id => (dispatch, getState) => {
	const terms = getState().terms;
	const blank = {
		Active: false,
    DaysDue: "",
		Name: "",
		Id: "",
	}
	const term = terms.find(term => {
		return term.Id == id;
	});

	return empty(term) || empty(id) ? blank : term;
}
