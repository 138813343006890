import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';

import {toggle, hide_panel} from '../../store/actions/utils/panel';
import {removeError} from '../../store/actions/utils/errors';
import {set_db_data} from '../../store/actions/utils/db_data';
import {set_page} from '../../store/actions/utils/page';

import {sortList} from '../../utils/sortList';
import {searchList} from '../../utils/searchList';

import ConsultantRow from '../../components/Rows/ConsultantRow';
import Loading from '../../components/Graphics/Loading';

class ConsultantData extends Component {
	constructor(props){
		super(props);
		this.state = {
			loaded: false,
			sort: 'last_name'
		}
	}

	async componentDidMount() {
		await this.props.set_page("consultants");
		await this.props.hide_panel();
		await this.props.set_db_data({...this.props.db_data, title: "Consultants", sort_btn: true, add_btn: false, search_btn: true, export_btn: true, filter_btn: false});
		this.setState({loaded: true});
	}

	handleChange = e => {
		this.setState({
			[e.target.name]: e.target.value
		});
	}

	handleSortChange = e => {
		this.setState({
			...this.state,
			'sort': e.target.id
		});
	}

	render() {
		const {consultants, errors, db_data} = this.props;
		let consultantList = sortList(this.state.sort, db_data.asc, searchList(db_data.search, consultants)).map(consultant =>
			consultant.user_id !== null && ['active', 'affiliate'].includes(consultant.consultant_status.toLowerCase()) ?
				<ConsultantRow
					key={consultant.user_id}
					consultant={consultant}
				/> : null
		);

		return (
			<Fragment>
				{!this.state.loaded && (
					<Loading />
				)}
				{errors.message && (
					<div className="alert alert-danger">
						{errors.message}
					</div>
				)}
				{this.state.loaded && (
					<div className="container1">
						<div className="project-table">
							<table className="table">
								<thead>
									<tr>
										<th scope="col" className="table-title leftrow"><div onClick={this.handleSortChange} id="first_name" className={this.state.sort === "first_name" ? "sortSelect" : "sortTitle"}>First Name</div></th>
										<th scope="col" className="table-title"><div onClick={this.handleSortChange} id="last_name" className={this.state.sort === "last_name" ? "sortSelect" : "sortTitle"}>Last Name</div></th>
										<th scope="col" className="table-title"><div onClick={this.handleSortChange} id="email_address" className={this.state.sort === "email_address" ? "sortSelect" : "sortTitle"}>Email</div></th>
										<th scope="col" className="table-title">SpringAhead ID</th>
										<th scope="col" className="table-title status">Role</th>
									</tr>
								</thead>
								<tbody className="table-body">
									{consultantList}
								</tbody>
							</table>
						</div>
					</div>
				)}
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		consultants: state.consultants,
		panel: state.panel,
		errors: state.errors,
		db_data: state.db_data
	};
}

export default connect(mapStateToProps, {set_page, removeError, toggle, hide_panel, set_db_data})(ConsultantData);
