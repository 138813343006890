import React, {Component} from 'react';

import {TextField, MenuItem} from '@material-ui/core';

import PercentageInput from '../Inputs/PercentageInput';

class RepRow extends Component {
	constructor(props) {
		super(props);
		this.state = {
			rep: props.rep,
			commission: props.rep.Commission
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if(prevState.rep.UserId !== this.state.rep.UserId || prevState.rep.Commission !== this.state.rep.Commission) {
			this.props.updateRow(this.props.index, this.state.rep);
		}
		if(prevProps.rep !== this.props.rep) {
			this.setState({rep: this.props.rep, commission: this.props.rep.Commission});
		}
	}

	removeRow = e => {
		e.preventDefault();
		this.props.removeRow(this.props.index)
	}

	onCommissionChange = e => {
		e.preventDefault();
		this.setState({...this.state, commission: e.target.value});
	}

	onCommissionBlur = e => {
		e.preventDefault();
		this.setState({...this.state, rep: {...this.state.rep, Commission: this.state.commission}});
	}

	onDropdownChange = e => {
		this.setState({...this.state, rep: {...this.state.rep, UserId: e.target.value}});
	}

	render() {
		const {options} = this.props;
		const {rep} = this.state;

		return (
			<div className="sales-rep-row" style={{marginTop: rep.Type === "AccountManager" ? "0" : "0.5rem"}}>
				<div className="sales-rep-title" style={{width: "30%", paddingLeft: rep.Type !== "AccountManager" ? "0" : "12px"}}>{rep.Type !== "AccountManager" && (<button className="sales-rep-remove" onClick={this.removeRow}>x</button>)}<span className="sales-rep-title-text">{rep.Type === "AccountManager" ? "Client Manager" : "Sales Bonus"}</span></div>
				<div style={{width: "45%"}}>
					<TextField
	          select
	          fullWidth
	          value={rep.UserId}
	          onChange={this.onDropdownChange}
	          disabled={rep.Type === "AccountManager"}
	        >
	          {options.map((option) => (
	            <MenuItem key={option.value} value={option.value}>
	              {option.label}
	            </MenuItem>
	          ))}
	        </TextField>
        </div>
				<div style={{width: "20%"}}>
					<PercentageInput value={this.state.commission} onChange={this.onCommissionChange} onBlur={this.onCommissionBlur}/>
				</div>
			</div>
		);
	}
}

export default RepRow;