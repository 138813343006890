import React, {Component, Fragment} from 'react';
import empty from 'is-empty';
import moment from 'moment';

import {TextField, Modal} from "@material-ui/core";
import {Add, Check, Close} from "@material-ui/icons";

import {sa_get_project_tasks} from '../../store/actions/peripherals/springahead';

import DatePicker from "../Inputs/DatePicker";

class Milestones extends Component {

	constructor(props) {
		super(props);
		this.state = {
			taskPopup: false,
			tasks: []
		}
	}

	async componentDidMount() {
		const tasks = await sa_get_project_tasks(this.props.project.PROJECT.SPRINGAHEAD_ID);
		this.setState({
			...this.state,
			tasks: tasks
		})
	}

  render() {
  	const {milestones, project, milestoneUnits, milestoneDate, milestoneName} = this.props;
  	const {taskPopup, tasks} = this.state;

  	let unitSum = 0;
		let dollarSum = 0;
		milestones.forEach(function(milestone) {
			unitSum += Number(milestone.UNITS);
			dollarSum += Number(milestone.UNITS) * (project.PAY || 0);
		});

  	return(
  		<Fragment>
	  		<Modal open={taskPopup} onClose={() => this.setState({...this.state, taskPopup: false})} style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
					<div style={{display: "flex", flexDirection: "column", padding: "2rem", minWidth: "40rem", minHeight: "20rem", background: "white"}}>
						<span style={{width: "100%", fontSize: "1.5rem", marginBottom: "0.5rem"}}>Project Tasks</span>
						<table className="table" style={{marginBottom: 0}}>
							<thead>
								<tr className="table-head">
									<th scope="col" className="table-title">Name</th>
									{project.PROJECT.TYPE === "Fixed Price" && (<th scope="col" className="table-title">Estimated Bill Date</th>)}
									<th scope="col" className="table-title"></th>
								</tr>
							</thead>
							<tbody className="table-body">
								{tasks.map((task, i) => (<tr key={i}>
									<td data-container="body" className="table-data">{task.Name}</td>
									{project.PROJECT.TYPE === "Fixed Price" && (<td data-container="body" className="table-data">{empty(task.ESTIMATED_BILL_DATE) ? "" : moment.utc(task.ESTIMATED_BILL_DATE).format('YYYY-MM-DD')}</td>)}
									<td><div onClick={() => { this.props.taskToMilestone(task); this.setState({...this.state, taskPopup: false}); }} style={{cursor: "pointer", width: "2rem", height: "2rem", borderRadius: "50%", backgroundColor: "green", color: "white", display: "flex", alignItems: "center", justifyContent: "center"}}><Add /></div></td>
								</tr>))}
							</tbody>
						</table>
					</div>
				</Modal>
	  		<div className="comp-chart" style={{marginTop: "1rem"}}>
					<div className="comp-chart-row" style={{position: "relative"}}>
						<div className="new-cell" style={{width: "45%"}}>Task</div>
						<div className="new-cell" style={{width: "20%"}}>Date</div>
						<div className="new-cell" style={{width: "15%"}}>Units</div>
						<div className="new-cell" style={{width: "20%"}}>Amount</div>
					</div>
					{milestones.map((milestone, i) => (
						<div className="comp-chart-row" style={{position: "relative"}} key={i}>
							<div className="new-cell" style={{width: "45%"}}>{milestone.NAME}</div>
							<div className="new-cell" style={{width: "20%"}}>{moment.utc(milestone.ESTIMATED_PAY_DATE).format('YYYY-MM-DD')}</div>
							<div className="new-cell" style={{width: "15%"}}>{milestone.UNITS}</div>
							<div className="new-cell" style={{width: "20%"}}>$ {(project.PAY * Number(milestone.UNITS)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
							<div onClick={() => this.props.deleteMilestone(milestone)} style={{position: "absolute", cursor: "pointer", right: -25, height: "2rem", width: "2rem", borderRadius: "50%", background: "red", color: "white", display: "flex", justifyContent: "center", alignItems: "center"}}><Close /></div>
						</div>
					))}
					<div className="comp-chart-row" style={{position: "relative"}}>
						<div className="new-cell" style={{width: "45%"}}>
							<TextField
								fullWidth
								placeholder="Milestone name..."
								type="text"
								value={milestoneName}
								name="milestoneName"
								onChange={this.props.onChange}
								onBlur={this.props.handleMilestoneBlur}
							/>
						</div>
						<div className="new-cell" style={{width: "20%"}}><DatePicker value={milestoneDate} onChange={this.props.handleMilestoneDateChange} onBlur={this.props.handleMilestoneBlur} name="milestoneDate"/></div>
						<div className="new-cell" style={{width: "15%"}}>
							<TextField
								fullWidth
								placeholder="Units..."
								type="number"
								value={milestoneUnits}
								name="milestoneUnits"
								onChange={this.props.onChange}
								onBlur={this.props.handleMilestoneBlur}
							/>
						</div>
						<div className="new-cell" style={{width: "20%"}}>$ {(milestoneUnits * project.PAY).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
						<div style={{position: "absolute", cursor: "pointer", right: -25, top: 8, height: "2rem", width: "2rem", borderRadius: "50%", background: (!empty(milestoneUnits) && !empty(milestoneName) && !empty(milestoneDate)) ? "green" : "lightgrey", color: "white", display: "flex", justifyContent: "center", alignItems: "center"}}><Check /></div>
						{empty(milestoneName) && empty(milestoneUnits) && empty(milestoneDate) && (<div onClick={() => this.setState({...this.state, taskPopup: true})} style={{position: "absolute", cursor: "pointer", right: -50, top: 8, height: "2rem", width: "2rem", borderRadius: "50%", background: "#01579B", color: "white", display: "flex", justifyContent: "center", alignItems: "center"}}><Add /></div>)}
					</div>
					<div className="comp-chart-row" style={{position: "relative"}}>
						<div className="new-cell" style={{width: "65%", justifyContent: "flex-start"}}>Total:</div>
						<div className="new-cell" style={{width: "15%"}}>{unitSum.toFixed(2)}</div>
						<div className="new-cell" style={{width: "20%"}}>${dollarSum.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
					</div>
				</div>
			</Fragment>
  	);
  }
}

export default Milestones;
