import React, {Component} from 'react';

class PayScheduleRow extends Component {
	constructor(props){
    super(props);
    this.state = {
    	clicked: false
    };
  }
	render() {
		const {professional, id, handleRowClicked} = this.props;
		const clicked = id === professional.ID ? "current-overhead-row" : "";
		const rowClasses = `row table-row ${clicked}`;
		return (
			<tr className={rowClasses} onClick={handleRowClicked} id="professional-row">
				<td data-container="body" className="table-data">{professional.NAME}</td>
				<td data-container="body" className="table-data">$ {Number(professional.BILL).toFixed(2)}</td>
				<td data-container="body" className="table-data">$ {Number(professional._90).toFixed(2)}</td>
				<td data-container="body" className="table-data">$ {Number(professional._85).toFixed(2)}</td>
				<td data-container="body" className="table-data">$ {Number(professional._80).toFixed(2)}</td>
			</tr>
		);
	}
}

export default PayScheduleRow;