import React, {Component} from 'react';
import moment from 'moment';
import empty from 'is-empty';

import {project_status} from '../../utils/status';
import { archive_reason_options } from '../../utils/options';

import ArchiveBtns from '../Buttons/ArchiveBtns';

class Client_ProjectRow extends Component {
	constructor(props){
    super(props);
    this.state = {
    	clicked: false
    };
  }

  commas = x => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}

  statusStyles = status => {
  	switch(status) {
  		case "Closed":
  			return "table-data stat-completed";
			case "Not Started":
				return "table-data stat-not-started";
			case "Active":
				return "table-data stat-in-progress";
			case "Candidate":
				return "table-data stat-candidate";
			case "Ongoing":
				return "table-data stat-in-progress";
      case "Reporting":
        return "table-data stat-candidate";
			default:
				return "table-data";
  	}
  }

  onRecover = e => {
  	e.preventDefault();
  	this.props.onRecover(this.props.client_project);
  }
  onDelete = e => {
  	e.preventDefault();
  	this.props.onDelete(this.props.client_project);
  }

	render() {
		const {client_project, handleRowClicked, role, id, candidate, leads, user} = this.props;
		const clicked = id === client_project.ID ? "current-overhead-row" : "";
		const rowClasses = `row table-row ${clicked} ${(role === "Consultant" && !leads) ? "read" : ""}`;
		const status = archive_reason_options.find(o => o.value == client_project.REASON)?.label || project_status(moment.utc(client_project.START_DATE), empty(client_project.END_DATE) ? null : moment.utc(client_project.END_DATE), this.props.candidate);
		const statusStyle = this.statusStyles(status);
		const manager = client_project.MANAGER;
		const client = client_project.CLIENT;
    const am = client_project.ACCOUNT_MANAGER;
		return (
			<tr className={rowClasses} onClick={handleRowClicked} id="project-row">
				<td data-container="body" className="table-data"><div className="img-row"><div className="row-img"><img className="row-img-src" src={`https://utilicastclientpictures.blob.core.windows.net/${client.ID}client/profile${user.sas}`} alt=""/></div>{client.ABBREV}</div></td>
				<td data-container="body" className="table-data">{client_project.NAME}</td>
        <td data-container="body" className="table-data">{`${am.first_name} ${am.last_name}`}</td>
				<td data-container="body" className="table-data">{candidate ? `${client_project.LEAD.first_name} ${client_project.LEAD.last_name}` : `${manager.first_name} ${manager.last_name}`}</td>
				<td data-container="body" className="table-data">{empty(client_project.START_DATE) ? '' : moment.utc(client_project.START_DATE).format("MMM D, YYYY")}</td>
				<td data-container="body" className="table-data">{empty(client_project.END_DATE) ? '' : moment.utc(client_project.END_DATE).format("MMM D, YYYY")}</td>
				<td data-container="body" className="table-data delete">{empty(client_project.VALUE) ? '' : `$${this.commas(Number(client_project.VALUE).toFixed(2))}`}</td>
        <td data-container="body" className="table-data delete">{empty(client_project.TYPE) ? '' : client_project.TYPE}</td>
				<td data-container="body" className={statusStyle}>{status}</td>
				{this.props.archive && (<ArchiveBtns onRecover={this.onRecover} onDelete={this.onDelete}/>)}
			</tr>
		);
	}
}

export default Client_ProjectRow;
