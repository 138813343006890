import {CHANGE_PAGE} from '../../actionTypes';

const page = (state = 'consultant-assignments', action) => {
	switch(action.type) {
		case CHANGE_PAGE:
			return action.page;
		default:
			return state;
	}
}

export default page;