import React, {Component} from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import {Delete} from '@material-ui/icons';

class KeyRow extends Component {
	constructor(props){
    super(props);
    this.state = {
    };
  }

  onDelete = e => {
  	e.preventDefault();
  	this.props.onDelete(this.props.keyRow);
  }

	render() {
		const {keyRow} = this.props;
		return (
			<tr className="row table-row">
				<td data-container="body" className="table-data">{keyRow.id}</td>
				<td data-container="body" className="table-data">{keyRow.NAME}</td>
        <td data-container="body" className="table-data"><CopyToClipboard text={keyRow.TOKEN} onCopy={() => alert(keyRow.NAME + " copied to clipboard!")}><span>{keyRow.TOKEN.substring(0,75) + "..."}</span></CopyToClipboard></td>
				<td data-container="body" className="table-data" ><div className='archive-btns-outer-div'><div className='archive-btns-div'><div className='archive-row-btn' style={{background: '#d9534f'}} onClick={this.onDelete}><Delete style={{color: "white", fontSize: "1.25rem", marginLeft: "0.1rem"}}/></div></div></div></td>
			</tr>
		);
	}
}

export default KeyRow;