import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {confirmAlert} from 'react-confirm-alert';
import moment from 'moment';

import {Button, Modal, Typography, Grid, TextField} from '@material-ui/core';

import {draft_amendment} from '../../store/actions/peripherals/documents';
import {db_add_milestone, db_delete_milestone} from '../../store/actions/tables/milestones';

import {stringifyNumber} from '../../utils/stringifyNumber';
import withToast from '../../utils/withToast';
import fileChange from '../../utils/fileChange';

import logo from '../../images/logo.jpg';
import DatePicker from "../../components/Inputs/DatePicker";
import RichText from '../../components/Inputs/RichText';
import Loading from '../../components/Graphics/Loading';
import ModalLoading from '../../components/Graphics/ModalLoading';
import Milestones from '../../components/Contracts/Milestones';

import empty from 'is-empty';

class AmendedEdit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			date: false,
			services: false,
			hours: false,
			amount: false,
			expenses: false,
			milestones: false,
			data: {
				ID: '',
				END_DATE: '',
				SERVICES: '',
				HOURS: '',
				EXPENSES_AMOUNT: '',
				EXPENSES_DESC: '',
				NUMBER: '',
				MILESTONES: []
			},
			validation: {
				date: false
			},
			project: {},
			attachment: '',
			contractLoading: false,
			loading: false
		}
	}

	componentDidMount() {
		const {data} = this.props;
		this.setState({
			...this.state,
			new: true,
			data: {
				...this.state.data,
				ID: data.ID,
				END_DATE: data.END_DATE,
				SERVICES: empty(data.CONTRACT) ? '' : data.CONTRACT.SERVICES,
				HOURS: data.HOURS,
				EXPENSES_AMOUNT: data.EXPENSES_AMOUNT,
				EXPENSES_DESC: data.EXPENSES_DESC,
				NUMBER: empty(data.AMENDMENTS) ? "First" : stringifyNumber(data.AMENDMENTS.length + 1),
				MILESTONES: data.CONTRACT.MILESTONES
			},
			project: data,
			loaded: true,
			milestoneUnits: '',
			milestoneDate: '',
			milestoneName: ''
		});
	}

	componentDidUpdate(prevProps, prevState) {
		if(prevProps.errors !== this.props.errors) {
			this.setState({
				...this.state,
				loaded: true,
				contractLoading: false
			});

		}
	}

	onChange = e => {
		this.setState({
			...this.state,
			[e.target.name]: e.target.value
		});
	}

	handleMilestoneDateChange = (value: any) => {
    this.setState({ 
    	...this.state,
			milestoneDate: empty(value) ? '' : moment.utc(value).format('YYYY-MM-DD')
  	});
  };

	handleMilestoneBlur = async () => {
		const {milestoneUnits, milestoneName, milestoneDate, data} = this.state;
		if(!empty(milestoneUnits) && !empty(milestoneName) && !empty(milestoneDate)) {
			const resp = await db_add_milestone({RATE_ID: data.ID, NAME: milestoneName, ESTIMATED_PAY_DATE: milestoneDate, UNITS: milestoneUnits});
			if(resp.complete) {
				this.setState({
					...this.state,
					data: {
						...data,
						MILESTONES: [...data.MILESTONES, {ID: resp.ID, RATE_ID: data.ID, NAME: milestoneName, ESTIMATED_PAY_DATE: milestoneDate, UNITS: milestoneUnits}].sort((a, b) => moment.utc(a.ESTIMATED_PAY_DATE) - moment.utc(b.ESTIMATED_PAY_DATE))
					},
					milestoneName: '',
					milestoneDate: '',
					milestoneUnits: ''
				})
			}
		}
	}

	onFileUpload = e => {
		e.preventDefault();
		this.fileChange(e, this.setFileState);
		fileChange(e, blob => this.setState({...this.state, attachment: blob.substring(28, blob.length)}));
	}

	handleAmendmentAppear = e => {
		this.setState({
			...this.state,
			[e.target.name]: true
		})
	}

	handleAmendmentHide = e => {
		this.setState({
			...this.state,
			[e.target.name]: false
		})
	}

	handleChange = e => {
		this.setState({
			...this.state,
			data: {
				...this.state.data,
				[e.target.name]: e.target.value
			}
		});
	}

	handleServicesChange = (val) => {
		this.setState({
			...this.state,
			data: {
				...this.state.data,
				SERVICES: val
			}
		});
	}

	handleDateChange = (value: any) => {
    this.setState({ 
    	...this.state,
			data: {
				...this.state.data,
				END_DATE: empty(value) ? '' : moment.utc(value).format('YYYY-MM-DD')
			},
			validation: {
				...this.state.validation,
				date: empty(this.state.project.PROJECT.END_DATE) ? false : empty(value) ? false : (moment.utc(value).format('YYYY-MM-DD') !== moment.utc(this.state.project.PROJECT.END_DATE).format('YYYY-MM-DD') && moment.utc(value).isAfter(moment.utc(this.state.project.PROJECT.END_DATE)))
			}
  	});
  };

  confirmToDocusign = () => {
  	confirmAlert({
      title: 'Confirm Submission',
      message: 'Are you sure you want to submit to Docusign? This contract will be legally binding.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.handleSubmit()
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  }

	handleSubmit = async () => {
		const {data, milestones} = this.state;
		this.setState({...this.state, loading: true});
		await this.props.draft_amendment({
			ID: data.ID,
			END_DATE: this.state.date ? data.END_DATE : '',
			SERVICES: this.state.services ? data.SERVICES : '',
			HOURS: this.state.hours ? data.HOURS : '',
			EXPENSES_AMOUNT: this.state.amount ? data.EXPENSES_AMOUNT : '',
			EXPENSES_DESC: this.state.expenses ? data.EXPENSES_DESC : '',
			NUMBER: data.NUMBER,
			MILESTONES: data.MILESTONES,
			MILESTONES_CHANGE: milestones
		}, this.state.project, this.state.attachment, this.props.goBack);
		this.setState({...this.state, loading: false});
	}

	deleteMilestone = async (milestone) => {
		this.setState({...this.state, loading: true});
		const resp = await db_delete_milestone({ID: milestone.ID});
		if(resp.complete) {
			this.setState({...this.state, data: {...this.state.data, MILESTONES: this.state.data.MILESTONES.filter(mile => mile.ID !== milestone.ID)}});
			this.props.addToast("Milestone successfully removed.", {appearance: 'success', autoDismiss: true});
		} else {
			this.props.addToast(resp.error, {appearance: 'error', autoDismiss: true});
		}
		this.setState({...this.state, loading: false});
	}

	render() {
		const {errors} = this.props;
		const {project, data, loading, loaded, milestoneDate, milestoneName, milestoneUnits, validation} = this.state;

		const fixedPrice = project.TYPE === "Fixed Price";

		return (
			<Fragment>
				{!empty(errors) && (
					<Grid container item justify="space-between" alignItems="center" style={{position: "fixed", background: "#d9534f", padding: "1rem 2rem", zIndex: "1000", width: "100vw", right: "0", top: "0", left: "0"}}>
						<Grid xs={11} container item><Typography variant="h6" style={{color: "white", width: "100%", overflow: "hidden"}}>{errors}</Typography></Grid>
						<Grid xs={1} container item justify="flex-end"><Typography variant="h4" style={{color: "white", cursor: "pointer"}} onClick={() => this.props.removeError()}>x</Typography></Grid>
					</Grid>
				)}
				{loading && (<ModalLoading />)}
				{loaded && (<div className="page-background" style={{marginTop: "2rem"}}>
					<div className="page" style={{height: "auto"}}>
						<div className="margins">
							<div className="contract-row" height="50px">
								<img src={logo} alt="logo" height="50px"/>
							</div>
							<div className="contract-row center-txt">
								<p className="heading">{data.NUMBER.toUpperCase()} AMENDED STATEMENT OF WORK</p>
							</div>
							<div className="contract-row">
								<p>
									This {data.NUMBER} Amended Statement of Work is issued between Consultant (as identified below) and Utilicast. 
									This Statement of Work is an attachment to and subject to the terms and conditions of the Employment Agreement or Consulting Services Agreement (“Agreement”) in place between Consultant and Utilicast. 
									In the event of a conflict between the terms of this Statement of Work and the Agreement, the terms of the Agreement shall prevail.
								</p>
								<p>
									This {data.NUMBER} Amended Statement of Work (SOW) is for Work Products as stated in Article 2 of the Agreement for a third party, {project.CLIENT.NAME} (“{project.CLIENT.ABBREV}”).
								</p>
								<p>This {data.NUMBER} Amended SOW amends the underlying SOW in the areas listed below. All other aspects of the underlying SOW remain unchanged and in effect.</p>
							</div>
							<ol className="contract-row tabbed">
								<li>
									<div className="contract-list">
										<p className="contract-list-title">Consultant Name: </p>
										<p>{project.CONSULTANT.first_name} {project.CONSULTANT.last_name}</p>
									</div>
								</li>
								<li>
									<div className="contract-list">
										<p className="contract-list-title">Effective Date of Statement of Work: </p>
										<p>{moment.utc(project.START_DATE).format("MMM D, YYYY")} - {moment.utc(project.END_DATE).format("MMM D, YYYY")}</p>
									</div>
								</li>
								<Fragment>
									{!this.state.date && (<Button variant="contained" color="secondary" onClick={() => this.setState({...this.state, date: true})} name="date" style={{fontSize: "1rem"}}>Amend</Button>)}
									{this.state.date && (<div className="contract-amend">
										<button className="no-amend" onClick={this.handleAmendmentHide} name="date" style={{marginTop: "20px"}}>X</button>
										<p className="contract-list-title">AMENDMENT: </p>
										<p>{moment.utc(project.START_DATE).format("MMM D, YYYY")} -</p>&nbsp;<div style={{alignSelf: 'flex-start'}}><div style={{marginBottom: "7px", marginLeft: "6px"}}><DatePicker value={data.END_DATE} label={empty(project.PROJECT.END_DATE) ? '' : `(before ${moment.utc(project.PROJECT.END_DATE).format("YYYY-MM-DD")})`} name="END_DATE" onChange={this.handleDateChange} error={validation.date}/></div></div>
									</div>)}
								</Fragment>
								<li>
									<div className="contract-list">
										<p className="contract-list-title">Customer/Project: </p>
										<p>{project.CLIENT.ABBREV} / {project.PROJECT.NAME}</p>
									</div>
								</li>
								<li>
									<div className="contract-list">
										<p className="contract-list-title">Utilicast Project Managers/Leads: </p>
										<p>{project.PROJECT_MANAGER.first_name} {project.PROJECT_MANAGER.last_name}</p>
									</div>
								</li>
								<li>
									<div className="contract-list">
										<p className="contract-list-title">Services and Deliverables Changes: </p>
										{!this.state.services && (<p>None</p>)}
									</div>
								</li>
								<Fragment>
									{!this.state.services && (<Button variant="contained" color="secondary" onClick={() => this.setState({...this.state, services: true})} style={{fontSize: "1rem"}}>Amend</Button>)}
									{this.state.services && (<div className="contract-amend">
										<button className="no-amend" onClick={this.handleAmendmentHide} name="services">X</button>
										<p className="contract-list-title" style={{alignSelf: 'flex-start'}}>AMENDMENT: </p>
										<div style={{height: "200px", width: "100%"}}><RichText value={data.SERVICES || ''} onChange={this.handleServicesChange} style={{width: "90%", height: "75%", position: "relative"}}/></div>
									</div>)}
								</Fragment>
								<li>
									<div className="contract-list">
										<p className="contract-list-title">Expected {fixedPrice ? "Units" : "Hours"}: </p>
										<p>{project.HOURS} {fixedPrice ? "units" : "hours"} as directed by the Project Manager</p>
									</div>
								</li>
								<Fragment>
									{!this.state.hours && (<Button variant="contained" color="secondary" onClick={() => this.setState({...this.state, hours: true})} name="hours" style={{fontSize: "1rem"}}>Amend</Button>)}
									{this.state.hours && (<div className="contract-amend">
										<button className="no-amend" onClick={this.handleAmendmentHide} name="hours">X</button>
										<p className="contract-list-title">AMENDMENT: </p>
										<TextField type="number" name="HOURS" style={{alignSelf: "flex-start", width: "7rem", marginTop: "-0.5rem"}} value={data.HOURS} onChange={this.handleChange}/>&nbsp;&nbsp;<p>hours as directed by the Project Manager</p>
									</div>)}
								</Fragment>
								<li>
									<div className="contract-list">
										<p className="contract-list-title">Expenses Amount: </p>
										<p>$ {Number(project.EXPENSES_AMOUNT).toFixed(2)}</p>
									</div>
								</li>
								<Fragment>
									{!this.state.amount && (<Button variant="contained" color="secondary" onClick={() => this.setState({...this.state, amount: true})} name="amount" style={{fontSize: "1rem"}}>Amend</Button>)}
									{this.state.amount && (<div className="contract-amend">
										<button className="no-amend" onClick={this.handleAmendmentHide} name="amount">X</button>
										<p className="contract-list-title">AMENDMENT: </p>
										<TextField type="number" name="EXPENSES_AMOUNT" style={{alignSelf: "flex-start", width: "7rem", marginTop: "-0.5rem"}} value={data.EXPENSES_AMOUNT} onChange={this.handleChange}/>&nbsp;&nbsp;
									</div>)}
								</Fragment>
								<li>
									<div className="contract-list">
										<p className="contract-list-title">Expenses Changes: </p>
										{!this.state.expenses && (<p>None</p>)}
									</div>
								</li>
								<Fragment>
									{!this.state.expenses && (<Button variant="contained" color="secondary" onClick={() => this.setState({...this.state, expenses: true})} name="expenses" style={{fontSize: "1rem"}}>Amend</Button>)}
									{this.state.expenses && (<div className="contract-amend">
										<button className="no-amend" onClick={this.handleAmendmentHide} name="expenses">X</button>
										<p className="contract-list-title">AMENDMENT: </p>
										<TextField fullWidth type="text" name="EXPENSES_DESC" style={{alignSelf: "flex-start", marginTop: "-0.5rem"}} value={data.EXPENSES_DESC} onChange={this.handleChange}/>
									</div>)}
								</Fragment>
								{fixedPrice && (<Fragment>
									<li>
										<div className="contract-list">
											<p className="contract-list-title">Milestones Change: </p>
											{!this.state.milestones && (<p>None</p>)}
										</div>
									</li>
									<Fragment>
										{!this.state.milestones && (<Button variant="contained" color="secondary" onClick={() => this.setState({...this.state, milestones: true})} name="milestones" style={{fontSize: "1rem"}}>Amend</Button>)}
										{this.state.milestones && (<div className="contract-amend">
											<button className="no-amend" onClick={this.handleAmendmentHide} name="milestones">X</button>
											<Milestones 
												milestones={data.MILESTONES} 
												deleteMilestone={this.deleteMilestone} 
												project={project} 
												handleMilestoneBlur={this.handleMilestoneBlur} 
												handleMilestoneDateChange={this.handleMilestoneDateChange} 
												onChange={this.onChange}
												milestoneDate={milestoneDate}
												milestoneName={milestoneName}
												milestoneUnits={milestoneUnits}
												taskToMilestone={(task) => this.setState({...this.state, milestoneName: task.Name, milestoneDate: empty(task.ESTIMATED_BILL_DATE) ? "" : moment.utc(task.ESTIMATED_BILL_DATE).format('YYYY-MM-DD')})}
											/>
										</div>)}
									</Fragment>
								</Fragment>)}
							</ol>
						</div>
					</div>
					<div className="page" style={{height: "10rem", minHeight: "10rem", margin: "2rem auto 4rem"}}>
						<div className="margins" style={{height: "10rem", minHeight: "10rem", padding: "1rem 15mm", justifyContent: "space-around"}}>
							<h2>Attachment?</h2>
							<input type="file" accept="application/pdf" name="attachment" onChange={this.onFileUpload}/>
						</div>
					</div>
					<div style={{width: "210mm", margin: 'auto', display: 'flex', justifyContent: 'center', marginBottom: "8rem"}} className="btn-group">
						<Button variant="contained" color="primary" onClick={this.confirmToDocusign} style={{fontSize: "1.5rem"}} disabled={validation.date}>Submit to Docusign</Button>
					</div>
				</div>)}
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		errors: state.errors
	};
}

export default connect(mapStateToProps, {draft_amendment})(withToast(AmendedEdit));

