import {apiCall} from '../../../services/api';

//GET

export const db_get_project_revenue = (data) => {
	return apiCall('post', '/api/charts/projects/revenue', data)
	.then(function(res) {
		return {data: res};
	})
	.catch(function(err) {
		return {error: err}
	});
}
