import {apiCall} from '../../../services/api';
import empty from 'is-empty';

import {DB_ERROR} from '../utils/errors';
import {hide_panel} from '../utils/panel';
import {db_return_consultant} from '../tables/consultants';
import {db_return_term} from '../tables/terms';
import {GET_CLIENTS, ADD_CLIENT, UPDATE_CLIENT, REMOVE_CLIENT, GET_CLIENT_OPTIONS} from '../../actionTypes';

import {defaultClient} from '../../../utils/nulls';

// ACTIONS BELOW

export const state_get_clients = payload => ({
	type: GET_CLIENTS,
	payload
});

export const state_add_client = payload => ({
	type: ADD_CLIENT,
	payload
});

export const state_update_client = payload => ({
	type: UPDATE_CLIENT,
	payload
});

export const state_remove_client = payload => ({
	type: REMOVE_CLIENT,
	payload
});

export const state_get_client_options = payload => ({
	type: GET_CLIENT_OPTIONS,
	payload
});

//GET

export const db_get_clients = () => async (dispatch, getState) => {
	const user = await getState().user;
	return apiCall('get', '/api/clients')
	.then(function(res) {
		dispatch(state_get_clients(res.map(client => ({
      ...client,
      IMG: `https://utilicastclientpictures.blob.core.windows.net/${client.ID}client/profile${user.sas}`,
      MANAGER: dispatch(db_return_consultant(client.MANAGER_ID)),
      TERM: dispatch(db_return_term(client.TERM_ID))
    }))));
	})
	.catch(function(err) {
		dispatch(DB_ERROR(err));
	});
}

export const db_return_client = id => {
	return apiCall('get', `/api/clients/${id}`)
	.then(function(res) {
		return {complete: true, data: res};
	})
	.catch(function(err) {
		return {complete: false, error: err.data.error};
	});
}

export const db_get_manager_clients = manager_id => {
	return apiCall('get', `/api/clients/manager/${manager_id}`)
	.then(function(res) {
		return res;
	})
	.catch(function(err) {
		console.log(err);
	});
}

export const db_get_client_dropdown = () => dispatch => {
	return apiCall('get', `/api/dropdowns/clients`)
	.then(function(res) {
		dispatch(state_get_client_options(res));
		return {complete: true};
	})
	.catch(function(err) {
		dispatch(DB_ERROR(err));
		return {complete: false, error: err.data.error};
	});
}

export const db_get_am_client_dropdown = id => dispatch => {
	return apiCall('get', `/api/dropdowns/am/clients/${id}`)
	.then(function(res) {
		dispatch(state_get_client_options(res));
		return {complete: true};
	})
	.catch(function(err) {
		dispatch(DB_ERROR(err));
		return {complete: false, error: err.data.error};
	});
}

export const db_get_client_amendments = id => {
	return apiCall('get', `/api/clients/amendments/${id}`)
	.then(function(res) {
		return res.blobs;
	})
	.catch(function(err) {
		console.log(err);
	});
}

//EDIT

export const db_update_client = client => async (dispatch, getState) => {
	const user = await getState().user;
	return apiCall('put', `/api/clients/${client.ID}`, client)
	.then(function(res) {
		dispatch(state_update_client({...res, IMG: `https://utilicastclientpictures.blob.core.windows.net/${res.ID}client/profile${user.sas}`, MANAGER: dispatch(db_return_consultant(res.MANAGER_ID))}));
		return {complete: true};
	})
	.catch(function(err) {
		return {complete: false, error: err.data.error};
	});
}

//ADD

export const db_add_client = (client) => async (dispatch, getState) => {
	const user = await getState().user;
	return apiCall('post', `/api/clients`, {...client, IMG: empty(client.IMG) ? defaultClient : client.IMG})
	.then(function(res) {
		dispatch(state_add_client({...res, IMG: `https://utilicastclientpictures.blob.core.windows.net/${res.ID}client/profile${user.sas}`, MANAGER: dispatch(db_return_consultant(res.MANAGER_ID))}));
		dispatch(hide_panel());
		return {complete: true};
	})
	.catch(function(err) {
		return {complete: false, error: err.data.error};
	});
}

export const db_add_return_client = (abbrev) => async (dispatch, getState) => {
	const user = await getState().user;
	const client = {
		NAME: '',
		ABBREV: abbrev,
		MANAGER_ID: '',
		ADDRESS: '',
		ENTITY: '',
		DELIVERABLES_LINK: '',
		CITY: '',
		STATE: '',
		ZIP: '',
		SPRINGAHEAD_ID: '',
		COUNTRY: '',
		TERMS_LINK: '',
    TERM_ID: '',
    PO_REQUIRED: 0,
		IMG: defaultClient
	}
	return apiCall('post', `/api/clients`, client)
	.then(function(res) {
		return {complete: true, data: {...res, IMG: `https://utilicastclientpictures.blob.core.windows.net/${res.ID}client/profile${user.sas}`}};
	})
	.catch(function(err) {
		return {complete: false, error: err.data.error};
	});
}

export const db_add_client_amendments = data => {
	return apiCall('post', `/api/clients/amendments`, data)
	.then(function(res) {
		return {complete: true};
	})
	.catch(function(err) {
		return {complete: false, error: err.data.error};
	});
}

//DELETE

export const db_delete_client = client => dispatch => {
	const id = client.ID;
	return apiCall('delete', `/api/archives/client/${id}`)
	.then(function(res) {
		dispatch(state_remove_client(client));
		return {complete: true};
	})
	.catch(function(err) {
		return {complete: false, error: err.data.error};
	})
}

export const db_delete_client_amendments = data => {
	return apiCall('post', `/api/clients/amendments/delete`, data)
	.then(function(res) {
		return {complete: true};
	})
	.catch(function(err) {
		return {complete: false, error: err.data.error};
	});
}

//ARCHIVES BELOW

export const db_get_client_archives = () => async (dispatch, getState) => {
	const user = await getState().user;
	return apiCall('get', '/api/archives/client')
	.then(function(res) {
		dispatch(state_get_clients(res.map(client => ({...client, IMG: `https://utilicastclientpictures.blob.core.windows.net/${client.ID}client/profile${user.sas}`, MANAGER: dispatch(db_return_consultant(client.MANAGER_ID))}))));
	})
	.catch(function(err) {
		dispatch(DB_ERROR(err));
	});
}

export const db_archive_client = client => dispatch => {
	return apiCall('put', `/api/archives/client`, client)
	.then(function(res) {
		dispatch(state_remove_client(client));
		return {complete: true};
	})
	.catch(function(err) {
		return {complete: false, error: err.data.error};
	});
}
