import empty from 'is-empty';

const filterClient = (filters, list) => {
	const clientIDs = filters.filterClients.map(cli => cli.value);
	if(!empty(clientIDs)) {
		return list.filter(function(item) {
			return clientIDs.includes(item.CLIENT_ID);
		})
	} else {
		return list;
	}
}

const filterConsultant = (filters, list, field) => {
	const conIDs = filters.filterConsultants.map(con => con.value);
	if(!empty(conIDs)) {
		return list.filter(function(item) {
			return conIDs.includes(Number(item[field]));
		})
	} else {
		return list;
	}
}

const filterSow = (filters, list) => {
	if(!empty(filters.filterSow)) {
		const tf = filters.filterSow === "YES" ? true : false;
		return list.filter(function(item) {
			return item.SOW === tf;
		})
	} else {
		return list;
	}
}

export const filter = (filters, list, field) => {
	return filterSow(filters, filterClient(filters, filterConsultant(filters, list, field)));
}