import React, {Component} from 'react';
import {TextField, InputAdornment} from '@material-ui/core';
import empty from 'is-empty';

class CurrencyInput extends Component {
	constructor(props) {
		super(props);
		this.state = {
			focus: false
		}
	}
	render() {
		const {value, label, name, disabled} = this.props;
		const {focus} = this.state;
		return (
			<TextField
				label={label}
				fullWidth
				margin="dense"
				type="number"
				value={value}
				name={name}
				disabled={disabled}
				onChange={this.props.onChange}
				onFocus={(e) => {this.setState({focus: true}); this.props.onFocus && this.props.onFocus(e);}}
				onBlur={(e) => {this.setState({focus: false}); this.props.onBlur && this.props.onBlur(e);}}
				InputProps={{startAdornment: (focus || !empty(value)) ? (<InputAdornment position="start" style={{paddingBottom: "3px"}}>$</InputAdornment>) : null}}
			/>
		)
	}
}

export default CurrencyInput;
