import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';

import {removeError} from '../../store/actions/utils/errors';
import {toggle, hide_panel} from '../../store/actions/utils/panel';
import {db_get_sponsors, db_get_am_sponsors} from '../../store/actions/tables/sponsors';
import {db_get_client_dropdown, db_get_am_client_dropdown} from '../../store/actions/tables/clients';
import {set_db_data} from '../../store/actions/utils/db_data';
import {addCancel} from '../../store/actions/utils/addbtn';
import {set_page} from '../../store/actions/utils/page';

import {sortList} from '../../utils/sortList';
import {searchList} from '../../utils/searchList';

import SponsorRow from '../../components/Rows/SponsorRow';
import SponsorDetail from './SponsorDetail';
import Loading from '../../components/Graphics/Loading';

class SponsorData extends Component {
	constructor(props){
		super(props);
		this.state = {
			loaded: false,
			sort: 'LAST',
			id: null
		}
	}

	async componentDidMount() {
		await this.props.set_page(this.props.role === "Admin" ? "sponsors" : this.props.role === "Client Manager" ? "am-sponsors" : "consultant-sponsors");
		await this.props.hide_panel();
		this.props.role === "Client Manager" ? await this.props.db_get_am_sponsors(this.props.user.id) : await this.props.db_get_sponsors();
		this.props.role === "Client Manager" ? await this.props.db_get_am_client_dropdown(this.props.user.id) : await this.props.db_get_client_dropdown();
		await this.props.set_db_data({
			...this.props.db_data, 
			title: this.props.role === "Admin" ? "Admin Client Sponsors" : this.props.role === "Client Manager" ? "My Client Sponsors" : "Client Sponsors", 
			sort_btn: true, 
			add_btn: this.props.role !== "Consultant", 
			search_btn: true, 
			export_btn: true, 
			filter_btn: false
		});
		this.setState({loaded: true});
	}

	async componentDidUpdate(prevProps, prevState) {
		if(prevProps.role !== this.props.role) {
			this.setState({...this.state, loaded: false});
			this.props.hide_panel();
			this.props.role === "Client Manager" ? await this.props.db_get_am_sponsors(this.props.user.id) : await this.props.db_get_sponsors();
			this.props.role === "Client Manager" ? await this.props.db_get_am_client_dropdown(this.props.user.id) : await this.props.db_get_client_dropdown();
			this.props.set_page(this.props.role === "Admin" ? "sponsors" : this.props.role === "Client Manager" ? "am-sponsors" : "consultant-sponsors");
			this.props.set_db_data({...this.props.db_data, title: this.props.role === "Admin" ? "Admin Client Sponsors" : this.props.role === "Client Manager" ? "Client Manager Client Sponsors" : "Client Sponsors", add_btn: this.props.role !== "Consultant"});
			this.setState({...this.state, loaded: true});
		}
	}

	async handleRowClicked(sponsor) {
		await this.setState({
			...this.state,
			id: this.props.role === "Consultant" ? null : sponsor.ID === this.state.id ? null : sponsor.ID
		});

		this.props.hide_panel();
	}

	handleChange = e => {
		this.setState({
			[e.target.name]: e.target.value
		});
	}

	handleSortChange = e => {
		this.setState({
			...this.state,
			'sort': e.target.id
		});
	}

	render() {
		const {sponsors, errors, db_data, role, user} = this.props;
		const {id} = this.state;

		let sponsorList = sortList(this.state.sort, db_data.asc, searchList(db_data.search, sponsors)).map(sponsor => 
			<Fragment key={sponsor.ID}>
				<SponsorRow
					sponsor={sponsor}
					handleRowClicked={this.handleRowClicked.bind(this, sponsor)}
					id={id}
					role={role}
					user={user}
				/>
				{id === sponsor.ID && (
					<tr>
						<td colSpan="7" className="editForm">
							<SponsorDetail currentSponsor={sponsor} onHide={() => this.setState({...this.state, id: null})} role={role} edit/>
						</td>
					</tr>
				)}
			</Fragment>
		);
		
		return (
			<Fragment>
				{!this.state.loaded && (
					<Loading />
				)}
				{errors.message && (
					<div className="alert alert-danger">
						{errors.message}
					</div>
				)}
				{this.state.loaded && (
					<div className="container1">
						{this.props.panel && (
							<div className="container-add">
								<SponsorDetail handleCancel={this.props.addCancel} role={role} edit={false}/>
							</div>
						)}
						<div className="project-table">
							<table className="table">
								<thead>
									<tr>
										<th scope="col" className="table-title leftrow"><div onClick={this.handleSortChange} id="CLIENT_ID" className={this.state.sort === "CLIENT_ID" ? "sortSelect" : "sortTitle"}>Client</div></th>
										<th scope="col" className="table-title"><div onClick={this.handleSortChange} id="FIRST" className={this.state.sort === "FIRST" ? "sortSelect" : "sortTitle"}>First Name</div></th>
										<th scope="col" className="table-title"><div onClick={this.handleSortChange} id="LAST" className={this.state.sort === "LAST" ? "sortSelect" : "sortTitle"}>Last Name</div></th>
										<th scope="col" className="table-title">Title</th>
										<th scope="col" className="table-title"><div onClick={this.handleSortChange} id="EMAIL" className={this.state.sort === "EMAIL" ? "sortSelect" : "sortTitle"}>Email</div></th>
										<th scope="col" className="table-title">Phone</th>
										<th scope="col" className="table-title status">Address</th>
									</tr>
								</thead>
								<tbody className="table-body">
									{sponsorList}
								</tbody>
							</table>
						</div>
					</div>
				)}
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		sponsors: state.sponsors,
		errors: state.errors,
		panel: state.panel,
		db_data: state.db_data,
		user: state.user
	};
}

export default connect(mapStateToProps, {
	db_get_sponsors, 
	db_get_am_sponsors,
	db_get_am_client_dropdown,
	db_get_client_dropdown,
	removeError,
	toggle,
	hide_panel,
	set_db_data,
	addCancel,
	set_page
})(SponsorData);



