import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {confirmAlert} from 'react-confirm-alert';
import moment from 'moment';
import empty from 'is-empty';

import {Button, Modal, Typography, Grid} from '@material-ui/core';

import {draft_contract} from '../../store/actions/peripherals/documents';
import {db_update_contract, db_add_contract} from '../../store/actions/peripherals/contracts';
import {db_add_milestone, db_delete_milestone} from '../../store/actions/tables/milestones';
import {removeError} from '../../store/actions/utils/errors';
import fileChange from '../../utils/fileChange';
import withToast from '../../utils/withToast';

import logo from '../../images/logo.jpg';
import RichText from '../../components/Inputs/RichText';
import SaveBtn from '../../components/Buttons/SaveBtn';
import Loading from '../../components/Graphics/Loading';
import ModalLoading from '../../components/Graphics/ModalLoading';
import Milestones from "../../components/Contracts/Milestones";

class ContractEdit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			new: false,
			data: {
				ID: '',
				SERVICES: '',
				COMP_DETAILS: '',
				MILESTONES: []
			},
			project: {},
			attachment: "",
			loaded: false,
			milestoneName: '',
			milestoneUnits: '',
			milestoneDate: '',
			taskPopup: false,
			unitsValidated: false
		}
	}

	async componentDidMount() {
		const {data} = this.props;
		if (!empty(data.CONTRACT)) {
			this.setState({
				...this.state,
				data: data.CONTRACT,
				project: data,
				loaded: true,
				unitsValidated: (data.CONTRACT.MILESTONES.reduce((a,b) => a + Number(b.UNITS), 0).toFixed(2) == Number(data.HOURS)) || (data.TYPE !== "Fixed Price")
			})
		} else {
			this.setState({
				...this.state,
				new: true,
				data: {
					...this.state.data,
					ID: data.ID
				},
				project: data,
				loaded: true
			})
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if(prevProps.errors !== this.props.errors) {
			this.setState({
				...this.state,
				loaded: true
			});
		}
		if(prevState.unitsValidated !== this.state.unitsValidated) {
			if(!this.state.unitsValidated) {
				this.props.addToast("The sum of the milestone units does not equal the total units allocated for the project.", {appearance: "error", autoDismiss: true});
			}
		}
	}

	handleChange = e => {
		this.setState({
			...this.state,
			data: {
				...this.state.data,
				[e.target.name]: e.target.value
			}
		});
	}

	onChange = e => {
		this.setState({
			...this.state,
			[e.target.name]: e.target.value
		});
	}

	handleMilestoneDateChange = (value: any) => {
    this.setState({
    	...this.state,
			milestoneDate: empty(value) ? '' : moment.utc(value).format('YYYY-MM-DD')
  	});
  };

	handleServicesChange = (val) => {
		this.setState({
			...this.state,
			data: {
				...this.state.data,
				SERVICES: val
			}
		});
	}

	handleCompChange = (val) => {
		this.setState({
			...this.state,
			data: {
				...this.state.data,
				COMP_DETAILS: val
			}
		});
	}

	handleDateChange = (value: any) => {
    this.setState({
    	...this.state,
			data: {
				...this.state.data,
				EFFECTIVE_DATE: value
			}
  	});
  };

  onFileUpload = e => {
		e.preventDefault();
		fileChange(e, blob => this.setState({...this.state, attachment: blob.substring(28, blob.length)}));
	}

  confirmToDocusign = () => {
  	confirmAlert({
      title: 'Confirm Submission',
      message: 'Are you sure you want to submit to Docusign? This contract will be legally binding.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.handleSubmit()
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  }

  handleMilestoneBlur = async () => {
		const {milestoneUnits, milestoneName, milestoneDate, data, project} = this.state;
		if(!empty(milestoneUnits) && !empty(milestoneName) && !empty(milestoneDate)) {
			const resp = await db_add_milestone({RATE_ID: project.ID, NAME: milestoneName, ESTIMATED_PAY_DATE: milestoneDate, UNITS: milestoneUnits});
			if(resp.complete) {
				const newMilestones = [...data.MILESTONES, {ID: resp.ID, RATE_ID: project.ID, NAME: milestoneName, ESTIMATED_PAY_DATE: milestoneDate, UNITS: milestoneUnits}].sort((a, b) => moment.utc(a.ESTIMATED_PAY_DATE) - moment.utc(b.ESTIMATED_PAY_DATE));
				this.setState({
					...this.state,
					data: {
						...data,
						MILESTONES: newMilestones
					},
					milestoneName: '',
					milestoneDate: '',
					milestoneUnits: '',
					unitsValidated: (newMilestones.reduce((a,b) => a + Number(b.UNITS), 0).toFixed(2) == Number(project.HOURS)) || (project.TYPE !== "Fixed Price")
				})
			} else {
				this.props.addToast(resp.error, {appearance: "error", autoDismiss: true});
			}
		}
	}

  deleteMilestone = async (milestone) => {
		this.setState({...this.state, loading: true});
		const resp = await db_delete_milestone({ID: milestone.ID});
		if(resp.complete) {
			const newMilestones = this.state.data.MILESTONES.filter(mile => mile.ID !== milestone.ID);
			console.log(newMilestones.reduce((a,b) => a + Number(b.UNITS), 0))
			this.setState({
				...this.state,
				unitsValidated: (newMilestones.reduce((a,b) => a + Number(b.UNITS), 0).toFixed(2) == Number(this.state.project.HOURS)) || (this.state.project.TYPE !== "Fixed Price"),
				data: {
					...this.state.data,
					MILESTONES: newMilestones
				}
			});
			this.props.addToast("Milestone successfully removed.", {appearance: 'success', autoDismiss: true});
		} else {
			this.props.addToast(resp.error, {appearance: 'error', autoDismiss: true});
		}
		this.setState({...this.state, loading: false});
	}

  handleSave = async () => {
  	this.setState({...this.state, loading: true});
  	const resp = this.state.new ? await this.props.db_add_contract(this.state.data) : await this.props.db_update_contract(this.state.data);
  	if(resp.complete) {
  		this.props.addToast("Contract record saved.", {appearance: 'success', autoDismiss: true});
  		this.props.goBack();
  	} else {
  		this.props.addToast(resp.error, {appearance: 'error', autoDismiss: true});
  	}
  	this.setState({...this.state, loading: false});
  }

	handleSubmit = async () => {
		this.setState({...this.state, loading: true});
		const resp = await this.props.draft_contract(this.state.data, this.state.project, this.state.attachment, this.props.goBack);
		if(resp.complete) {
			this.props.addToast("Contract submitted to DocuSign.", {appearance: 'success', autoDismiss: true});
		} else {
			this.props.addToast(resp.error, {appearance: 'error', autoDismiss: true});
		}
		this.setState({...this.state, loading: false});
	}

	render() {
		const {role, errors} = this.props;
		const {data, project, milestoneName, milestoneUnits, milestoneDate, loaded, loading, unitsValidated} = this.state;

		return (
			<Fragment>
				{!empty(errors) && (
					<Grid container item justify="space-between" alignItems="center" style={{position: "fixed", background: "#d9534f", padding: "1rem 2rem", zIndex: "1000", width: "100vw", right: "0", top: "0", left: "0"}}>
						<Grid xs={11} container item><Typography variant="h6" style={{color: "white", width: "100%", overflow: "hidden"}}>{errors}</Typography></Grid>
						<Grid xs={1} container item justify="flex-end"><Typography variant="h4" style={{color: "white", cursor: "pointer"}} onClick={() => this.props.removeError()}>x</Typography></Grid>
					</Grid>
				)}
				{loading && (<ModalLoading />)}
				{loaded && (<div className="page-background">
					<div className="page">
						<div className="margins">
							<div className="contract-row" height="50px">
								<img src={logo} alt="logo" height="50px"/>
							</div>
							<div className="contract-row center-txt">
								<p className="heading">STATEMENT OF WORK</p>
								<p className="heading2">{project.NAME}</p>
							</div>
							<div className="contract-row">
								<p>
									This Statement of Work is issued between {project.CONSULTANT.consultant_type === 'Contractor' ? `${project.CONSULTANT.contractor_company_name}` : `${project.CONSULTANT.first_name} ${project.CONSULTANT.last_name}`} and Utilicast.
									This Statement of Work is an attachment to and subject to the terms and conditions of the Employment Agreement
									or Consulting Services Agreement (“Agreement”) in place between Consultant and Utilicast.
									In the event of a conflict between the terms of this Statement of Work and the Agreement,
									the terms of the Agreement shall prevail.
								</p>
								<p>
									This Agreement is for Work Products as specified in Article 2 for a third party, {project.CLIENT.NAME + ' '}
									(“{project.CLIENT.ABBREV}”).
								</p>
							</div>
							<div className="contract-row tabbed" style={{height: '80px'}}>
								<p className="heading3">I. <u>Effective Date of this Statement of Work</u></p>
								<div className="effective-date-div">
									<div>
										This Statement of Work is effective as of {moment.utc(project.START_DATE).format('MMM D, YYYY')}
									</div>
								</div>
							</div>
							<div className="contract-row tabbed">
								<p className="heading3">II. <u>Services to be Performed and Deliverables</u></p>
								{role !== "Consultant" && (<div className="quill-div">
									<RichText value={data.SERVICES || ''} onChange={this.handleServicesChange}/>
								</div>)}
								{role === "Consultant" && (<div className="quill-div" dangerouslySetInnerHTML={{__html: data.SERVICES}}></div>)}
								<p>
									Consultant will save Deliverables to Utilicast Sharepoint site. Utilicast documents for
									{' ' + project.CLIENT.ABBREV} can be found at: <a target="_blank" href={project.CLIENT.DELIVERABLES_LINK} rel="noopener noreferrer">{project.CLIENT.DELIVERABLES_LINK}</a>.
								</p>
							</div>
							<div className="contract-row tabbed">
								<p className="heading3">III. <u>Schedule of Performance</u></p>
								<p>
									The period of performance for this Statement of Work is {moment.utc(project.START_DATE).format('MMM D, YYYY')} through {moment.utc(project.END_DATE).format('MMM D, YYYY')}.
									No work shall be performed before the start date or after the end date specified
									in this Statement of Work without prior written consent from Utilicast. Utilicast is not financially
									responsible for work performed outside these dates. Consultant will observe Customer and
									Utilicast’s business hours, security rules, policies, procedures, and holiday schedule while working
									on Customer and Utilicast's premises. If Consultant is given access, whether on-site or through
									remote facilities to any of Customer and Utilicast's computer or electronic data storage systems,
									Consultant will use such access solely to perform the Services.
								</p>
							</div>
						</div>
					</div>
					<div className="page">
						<div className="margins">
							<div className="contract-row tabbed">
								<p className="heading3">IV. <u>Compensation</u></p>
								{project.TYPE === "T&M" && (<p>
									Utilicast will pay an hourly fee for work performed according to this Statement of Work as
									follows. The Time and Materials estimates in this Statement of Work shall not be exceeded
									without written authorization from Utilicast.
								</p>)}
								{project.TYPE === "Fixed Price" && (<p>
									Utilicast will pay a fixed fee for work performed according to this Statement of Work as follows.  Any milestone payment is subject to and contingent on the acceptance of such milestone by the Client and payment by Client.
								</p>)}
								<div className="comp-chart">
									<div className="comp-chart-row">
										<div className="new-cell" style={{width: "100%", justifyContent: "flex-start"}}>{moment.utc(project.START_DATE).format('MMM D, YYYY')} - {moment.utc(project.END_DATE).format('MMM D, YYYY')}</div>
									</div>
									<div className="comp-chart-row">
										<div className="new-cell" style={{width: "30%"}}>Professional Category</div>
										<div className="new-cell" style={{width: "15%"}}>Pay Rate</div>
										<div className="new-cell" style={{width: "20%"}}>Staff</div>
										<div className="new-cell" style={{width: "15%"}}>{project.TYPE === "Fixed Price" ? "Tot. Units" : "Est. Hours"}</div>
										<div className="new-cell" style={{width: "20%"}}>Fees</div>
									</div>
									<div className="comp-chart-row">
										<div className="new-cell" style={{width: "30%"}}>{project.PROFESSIONAL_DETAIL.NAME}</div>
										<div className="new-cell" style={{width: "15%"}}>$ {Number(project.PAY).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
										<div className="new-cell" style={{width: "20%"}}>{project.CONSULTANT.first_name} {project.CONSULTANT.last_name}</div>
										<div className="new-cell" style={{width: "15%"}}>{project.HOURS}</div>
										<div className="new-cell" style={{width: "20%"}}>$ {(project.HOURS * project.PAY).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
									</div>
									<div className="comp-chart-row">
										<div className="new-cell" style={{width: "80%", justifyContent: "flex-start"}}>Expenses: {project.EXPENSES_DESC}</div>
										<div className="new-cell" style={{width: "20%"}}>$ {Number(project.EXPENSES_AMOUNT).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
									</div>
									<div className="comp-chart-row">
										<div className="new-cell" style={{width: "80%", justifyContent: "flex-start"}}>Total:</div>
										<div className="new-cell" style={{width: "20%"}}>$ {((project.HOURS * project.PAY) + Number(project.EXPENSES_AMOUNT)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
									</div>
								</div>
								{project.TYPE === "Fixed Price" && (<Milestones
									milestones={data.MILESTONES}
									deleteMilestone={this.deleteMilestone}
									project={project}
									handleMilestoneBlur={this.handleMilestoneBlur}
									handleMilestoneDateChange={this.handleMilestoneDateChange}
									onChange={this.onChange}
									milestoneDate={milestoneDate}
									milestoneName={milestoneName}
									milestoneUnits={milestoneUnits}
									taskToMilestone={(task) => this.setState({...this.state, milestoneName: task.Name, milestoneDate: empty(task.ESTIMATED_BILL_DATE) ? "" : moment.utc(task.ESTIMATED_BILL_DATE).format('YYYY-MM-DD')})}
								/>)}
								<p></p>
								<p>Compensation Details:</p>
								{role !== "Consultant" && (<div className="quill-div">
									<RichText value={data.COMP_DETAILS || ''} onChange={this.handleCompChange} />
								</div>)}
								{role === "Consultant" && (<div className="quill-div" dangerouslySetInnerHTML={{__html: data.COMP_DETAILS}}></div>)}
							</div>
							<div className="contract-row tabbed">
								<p className="heading3">V. <u>Quality of Services</u></p>
								<p>
									See Agreement, Arts. 1-2.
								</p>
							</div>
							<div className="contract-row tabbed">
								<p className="heading3">VI. <u>Statement of Work Managers</u></p>
								<div className="container-fluid">
									<div className="staff-box">
										<div className="col-xs-6 cell-box">
											<b>Utilicast</b>
										</div>
										<div className="col-xs-6 cell-box">
											<b>Staff</b>
										</div>
									</div>
								</div>
								<div className="container-fluid">
									<div className="staff-box">
										<div className="col-xs-6 cell-box">
											{project.ACCOUNT_MANAGER.first_name} {project.ACCOUNT_MANAGER.last_name}
										</div>
										<div className="col-xs-6 cell-box">
											{project.CONSULTANT.first_name} {project.CONSULTANT.last_name}
										</div>
									</div>
								</div>
							</div>
							<p></p>
							<div className="contract-row tabbed">
								<p className="heading3">VII. <u>Warranty</u></p>
								<p>
									See Agreement, Art. 1.
								</p>
							</div>
						</div>
					</div>
					<div className="page" style={{height: "10rem", minHeight: "10rem", marginBottom: "4rem"}}>
						<div className="margins" style={{height: "10rem", minHeight: "10rem", padding: "1rem 15mm", justifyContent: "space-around"}}>
							<h2>Attachment?</h2>
							<input type="file" accept="application/pdf" name="attachment" onChange={this.onFileUpload}/>
						</div>
					</div>
					<div style={{width: "190mm", margin: 'auto', display: 'flex', justifyContent: 'center', marginBottom: "8rem"}} className="btn-group">
						<SaveBtn onClick={this.handleSave} text="Save" />
						<Button variant="contained" color="primary" style={{marginLeft: '3rem', fontSize: "1rem"}} onClick={this.confirmToDocusign} disabled={!unitsValidated}>Submit to Docusign</Button>
					</div>
				</div>)}
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		errors: state.errors
	};
}

export default connect(mapStateToProps, {removeError, draft_contract, db_update_contract, db_add_contract})(withToast(ContractEdit));
