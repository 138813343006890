import {apiCall} from '../../../services/api';

export const db_add_milestone = data => {
	return apiCall('post', `/api/milestones`, data)
	.then(function(res) {
		return {complete: true, ID: res.ID};
	})
	.catch(function(err) {
		return {complete: false};
	});
}

export const db_delete_milestone = data => {
	return apiCall('post', `/api/milestones/delete`, data)
	.then(function(res) {
		return {complete: true};
	})
	.catch(function(err) {
		return {complete: false};
	});
}