import React, {Component, Fragment} from 'react';
import moment from 'moment';
import empty from 'is-empty';

import {assignment_status} from '../../utils/status';

import ArchiveBtns from '../Buttons/ArchiveBtns';

class ProjectRow extends Component {
	constructor(props){
    super(props);
    this.state = {
    	clicked: false,
    	archiveName: ''
    };
  }

  statusStyles = status => {
  	switch(status) {
  		case "Completed":
  			return "table-data stat-completed";
			case "Not Started":
				return "table-data stat-not-started";
			case "Pending":
				return "table-data stat-pending";
			case "In-Progress":
				return "table-data stat-in-progress";
			case "Ongoing":
				return "table-data stat-in-progress";
      case "Reporting":
        return "table-data stat-candidate";
			default:
				return "";
  	}
  }

  onRecover = e => {
  	e.preventDefault();
  	this.props.onRecover(this.props.project);
  }
  onDelete = e => {
  	e.preventDefault();
  	this.props.onDelete(this.props.project);
  }

	render() {
		const {project, handleRowClicked, role, id, user} = this.props;
		const clicked = id === project.ID ? "current-overhead-row" : "";
		const rowClasses = `table-row firstrow ${clicked}${role === "Reports" ? "read" : ""}`;
		const sow = project.SOW ? "table-data delete sow-yes" : "table-data delete sow-no";
		const status = assignment_status(moment.utc(project.START_DATE), empty(project.END_DATE) ? null : moment.utc(project.END_DATE), project.SOW);
		const statusStyle = this.statusStyles(status);
		const consultant = project.CONSULTANT;
		const client = project.CLIENT;
		const client_project = project.PROJECT;
		return (
			<tr className={rowClasses} onClick={handleRowClicked}>
				<td data-container="body" className="table-data"><div className="img-row"><div className="row-img"><img className="row-img-src" src={`https://utilicastclientpictures.blob.core.windows.net/${client.ID}client/profile${user.sas}`} alt=""/></div>{client.ABBREV}</div></td>
				<td data-container="body" className="table-data project">{empty(client_project.NAME) ? this.state.archiveName : client_project.NAME}</td>
				<td data-container="body" className="table-data delete">{`${consultant.first_name} ${consultant.last_name}`}</td>
				<td data-container="body" className="table-data">{empty(project.START_DATE) ? '' : moment.utc(project.START_DATE).format("MMM D, YYYY")}</td>
				<td data-container="body" className="table-data">{empty(project.END_DATE) ? '' : moment.utc(project.END_DATE).format("MMM D, YYYY")}</td>
        <td data-container="body" className="table-data">{empty(project.HOURS) ? '' : project.HOURS}</td>
        {role === "Reports" && (<td data-container="body" className="table-data">{moment.utc(project.START_DATE).isSame(moment.utc(project.END_DATE)) ? (project.HOURS/((1/365)*20)).toFixed(0) + "%" : (project.HOURS/(((((moment.duration(moment.utc(project.END_DATE).diff(moment.utc(project.START_DATE)))).asDays()) + 1)/365)*20)).toFixed(0) + "%"}</td>)}
				{role !== "Reports" && (<Fragment><td data-container="body" className="table-data">$ {Number(project.BILL).toFixed(2)}</td>
				<td data-container="body" className="table-data delete">$ {empty(project.PAY) ? '' : Number(project.PAY).toFixed(2)}</td>
				<td data-container="body" className={sow}>{project.SOW ? "YES" : "NO"}</td></Fragment>)}
				<td data-container="body" className={statusStyle}>{status}</td>
				{this.props.archive && (<ArchiveBtns onRecover={this.onRecover} onDelete={this.onDelete}/>)}
			</tr>
		);
	}
}

export default ProjectRow;