import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import withToast from '../../utils/withToast';

import {TextField, Grid, MenuItem} from '@material-ui/core';

import {db_update_professional, db_remove_professional, db_add_professional} from '../../store/actions/tables/professionals';

import {years} from '../../utils/options';

import SaveBtn from '../../components/Buttons/SaveBtn';
import DeleteBtn from '../../components/Buttons/DeleteBtn';
import CurrencyInput from '../../components/Inputs/CurrencyInput';
import ModalLoading from '../../components/Graphics/ModalLoading';

class PayScheduleDetail extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			data: {
				ID: '',
				NAME: '',
				BILL: '',
				_90: '',
				_85: '',
				_80: '',
				YEAR: this.props.edit ? "" : moment().format('YYYY')
			}
		}
	}

	componentDidMount() {
		this.setState({...this.state, data: this.props.edit ? this.props.currentProfessional : this.state.data});
	}

	handleChange = e => {
		this.setState({
			...this.state,
			data: {
				...this.state.data,
				[e.target.name]: e.target.value
			}
		});
	}

	handleAdd = async () => {
		this.setState({...this.state, loading: true});
		const resp = await this.props.db_add_professional(this.state.data, this.props.year);
		if(resp.complete) {
			this.props.addToast("Rate successfully added.", {appearance: "success", autoDismiss: true});
		} else {
			this.props.addToast(resp.error, {appearance: "error", autoDismiss: true});
		}
		this.setState({...this.state, loading: false});
	}

	handlePut = async () => {
		this.setState({...this.state, loading: true});
		const resp = await this.props.db_update_professional(this.state.data);
		if(resp.complete) {
			this.props.addToast("Rate successfully updated.", {appearance: "success", autoDismiss: true});
			this.props.onHide();
		} else {
			this.props.addToast(resp.error, {appearance: "error", autoDismiss: true});
		}
		this.setState({...this.state, loading: false});
	}

	handleRemove = async () => {
		this.setState({...this.state, loading: true});
		const resp = await this.props.db_remove_professional(this.state.data);
		if(resp.complete) {
			this.props.addToast("Rate successfully removed.", {appearance: "success", autoDismiss: true});
		} else {
			this.props.addToast(resp.error, {appearance: "error", autoDismiss: true});
		}
		this.setState({...this.state, loading: false});
	}

	render() {
		const {edit} = this.props;
		const {data, loading} = this.state;

		return (
			<Fragment>
				{loading && (<ModalLoading />)}
				<div className="form">
					<form>
						<Grid container item xs={12}>
							<Grid container item direction="column" xs={12} sm={6} style={{padding: "0 1rem"}}>
								<TextField 
									label="Category Name" 
									fullWidth
									margin="dense"
									type="text" 
									value={data.NAME} 
									name="NAME" 
									onChange={this.handleChange}
								/>
								<CurrencyInput label="Bill Rate" value={data.BILL} name="BILL" onChange={this.handleChange}/>
								<CurrencyInput label="90% Rate" value={data._90} name="_90" onChange={this.handleChange}/>
							</Grid>
							<Grid container item direction="column" xs={12} sm={6} style={{padding: "0 1rem"}}>
								<TextField
				          select
				          label="Year"
				          margin="dense" 
				          value={data.YEAR}
				          onChange={this.handleChange}
				          name="YEAR"
				        >
				          {years.map((option) => (
				            <MenuItem key={option.value} value={option.value}>
				              {option.label}
				            </MenuItem>
				          ))}
				        </TextField>
								<CurrencyInput label="85% Rate" value={data._85} name="_85" onChange={this.handleChange}/>
								<CurrencyInput label="80% Rate" value={data._80} name="_80" onChange={this.handleChange}/>
							</Grid>
							<div className="col-xs-12"></div>
							<div className="form-commands">
								<div className="btn-group data-form-commands">
									<SaveBtn onClick={edit ? this.handlePut : this.handleAdd} text="Save" />
									<DeleteBtn onClick={edit ? this.handleRemove : this.props.handleCancel} text={edit ? "Delete" : "Cancel"} delete={edit ? true : false} style={{marginLeft: "1.5rem"}}/>
								</div>
							</div>
						</Grid>
					</form>
				</div>
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		
	};
}

export default connect(mapStateToProps, {db_update_professional, db_remove_professional, db_add_professional})(withToast(PayScheduleDetail));

