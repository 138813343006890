import {GET_CLIENT_PROJECTS, ADD_CLIENT_PROJECT, UPDATE_CLIENT_PROJECT, REMOVE_CLIENT_PROJECT} from '../../actionTypes';

const client_projects = (state = [], action) => {
	switch(action.type) {
		case GET_CLIENT_PROJECTS:
			return [...action.payload];
		case ADD_CLIENT_PROJECT:
			return [...state, action.payload];
		case UPDATE_CLIENT_PROJECT:
			return state.map(client_project => {
				if(action.payload.ID === client_project.ID) {
					return {...client_project, ...action.payload};
				} else {
					return client_project;
				}
			});
		case REMOVE_CLIENT_PROJECT:
			return state.filter(client_project => {
				return client_project.ID !== action.payload.ID;
			});
		default:
			return state;
	}
}

export default client_projects;