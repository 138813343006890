import {GET_PROFESSIONAL_OPTIONS} from '../../actionTypes';

const professionalOptions = (state = [], action) => {
	switch(action.type) {
		case GET_PROFESSIONAL_OPTIONS:
			return [...action.payload];
		default:
			return state;
	}
}

export default professionalOptions;