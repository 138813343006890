import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';

import {removeError} from '../../store/actions/utils/errors';
import {db_get_client_projects, db_get_candidate_projects, db_get_am_client_projects, db_get_am_candidate_projects, db_get_consultant_client_projects} from '../../store/actions/tables/client_projects';
import {db_get_client_dropdown, db_get_am_client_dropdown} from '../../store/actions/tables/clients';
import {db_get_terms} from '../../store/actions/tables/terms';
import {toggle, hide_panel} from '../../store/actions/utils/panel';
import {set_db_data} from '../../store/actions/utils/db_data';
import {addCancel} from '../../store/actions/utils/addbtn';
import {set_page} from '../../store/actions/utils/page';

import {sortList} from '../../utils/sortList';
import {searchList} from '../../utils/searchList';
import {filter} from '../../utils/filters';

import ClientProjectRow from '../../components/Rows/Client_ProjectRow';
import ClientProjectDetail from './Client_ProjectDetail';
import Loading from '../../components/Graphics/Loading';

class Client_ProjectData extends Component {
	constructor(props){
		super(props);
		this.state = {
			loaded: false,
			sort: 'CLIENT_ID',
			id: null
		}
	}

	mount = async () => {
		this.setState({...this.state, loaded: false});
		const consultant = this.props.role !== "Consultant";
    await this.props.db_get_terms();
		if(!this.props.candidate) {
			await this.props.set_page(this.props.role === "Admin" ? "admin-projects" : this.props.role === "Client Manager" ? "am-projects" : "consultant-projects");
			this.props.role === "Client Manager" ? await this.props.db_get_am_client_projects(this.props.user.id) : await this.props.db_get_client_projects();
			await this.props.set_db_data({...this.props.db_data, title: this.props.role === "Client Manager" ? "My Active Projects" : this.props.role + " Active Projects", add_btn: consultant, search_btn: true, export_btn: true, sort_btn: true, filter_btn: true});
		} else if(this.props.leads) {
			await this.props.set_page("consultant-leads");
			await this.props.db_get_consultant_client_projects(this.props.user.id, false);
			await this.props.set_db_data({...this.props.db_data, title: "My Leads", add_btn: true, search_btn: true, export_btn: true, sort_btn: true, filter_btn: true});
		} else if (this.props.submittedleads) {
			await this.props.set_page("consultant-submitted-leads");
			await this.props.db_get_consultant_client_projects(this.props.user.id, true);
			await this.props.set_db_data({...this.props.db_data, title: "My Submitted Leads", add_btn: false, search_btn: true, export_btn: true, sort_btn: true, filter_btn: true});
		} else {
			await this.props.set_page(this.props.role === "Admin" ? "candidate-projects" : this.props.role === "Client Manager" ? "am-candidate-projects" : "consultant-candidate-projects");
			this.props.role === "Client Manager" ? await this.props.db_get_am_candidate_projects(this.props.user.id) : await this.props.db_get_candidate_projects();
			await this.props.set_db_data({...this.props.db_data, title: this.props.role === "Client Manager" ? "My Candidate Projects" : this.props.role + " Candidate Projects", add_btn: consultant, search_btn: true, export_btn: true, sort_btn: true, filter_btn: true});
		}
		await this.props.hide_panel();
		this.props.role === "Client Manager" ? await this.props.db_get_am_client_dropdown(this.props.user.id) : await this.props.db_get_client_dropdown();
		this.setState({...this.state, loaded: true});
	}

	async componentDidMount() {
		this.mount();
	}

	async componentDidUpdate(prevProps, prevState) {
		if(this.props.candidate !== prevProps.candidate || prevProps.role !== this.props.role || this.props.leads !== prevProps.leads) {
			this.mount();
		}
	}

	async handleRowClicked(data) {
		await this.setState({
			...this.state,
			id: this.props.leads ? data.ID === this.state.id ? null : data.ID : this.props.role === "Consultant" ? null : data.ID === this.state.id ? null : data.ID
		});

		this.props.hide_panel();
	}

	handleChange = e => {
		this.setState({
			...this.state,
			[e.target.name]: e.target.value
		});
	}

	handleSortChange = e => {
		this.setState({
			...this.state,
			'sort': e.target.id
		});
	}

	render() {
		const {client_projects, user, role, db_data, candidate, leads, submittedleads} = this.props;
		const {id} = this.state;

		let list = sortList(this.state.sort, db_data.asc, searchList(db_data.search, filter(this.props.filters, client_projects, "MANAGER_ID")));
		let projectList = list.map(client_project =>
			<Fragment key={client_project.ID}>
				<ClientProjectRow
					client_project={client_project}
					handleRowClicked={this.handleRowClicked.bind(this, client_project)}
					candidate={candidate}
					id={id}
					role={role}
					leads={leads}
					user={user}
				/>
				{id === client_project.ID && (
					<tr>
						<td colSpan="9" className="editForm">
							<ClientProjectDetail user={user} role={role} onHide={() => this.setState({...this.state, id: null})} currentClientProject={client_project} candidate={candidate} edit leads={leads}/>
						</td>
					</tr>
				)}
			</Fragment>
		);
		return (
			<Fragment>
				{!this.state.loaded && (
					<Loading />
				)}
				{this.state.loaded && (
					<div className="container1">
						{this.props.panel && (
							<div className="container-add">
								<ClientProjectDetail user={user} role={role} handleCancel={this.props.addCancel} candidate={candidate} leads={leads} submittedleads={submittedleads}/>
							</div>
						)}
						<div className="project-table">
							<table className="table">
								<thead>
									<tr className="table-head">
										<th scope="col" className="table-title leftrow"><div onClick={this.handleSortChange} id="CLIENT_ID" className={this.state.sort === "CLIENT_ID" ? "sortSelect" : "sortTitle"}>Client</div></th>
										<th scope="col" className="table-title"><div onClick={this.handleSortChange} id="NAME" className={this.state.sort === "NAME" ? "sortSelect" : "sortTitle"}>Project Name</div></th>
										<th scope="col" className="table-title"><div onClick={this.handleSortChange} id="ACCOUNT_MANAGER" className={this.state.sort === "ACCOUNT_MANAGER" ? "sortSelect" : "sortTitle"}>Client Manager</div></th>
										<th scope="col" className="table-title"><div onClick={this.handleSortChange} id={candidate ? "LEAD_ID" : "MANAGER_ID"} className={this.state.sort === "MANAGER_ID" || this.state.sort === "LEAD_ID" ? "sortSelect" : "sortTitle"}>{candidate ? "Entered By" : "Project Manager"}</div></th>
										<th scope="col" className="table-title"><div onClick={this.handleSortChange} id="START_DATE" className={this.state.sort === "START_DATE" ? "sortSelect" : "sortTitle"}>Start Date</div></th>
										<th scope="col" className="table-title"><div onClick={this.handleSortChange} id="END_DATE" className={this.state.sort === "END_DATE" ? "sortSelect" : "sortTitle"}>End Date</div></th>
										<th scope="col" className="table-title delete"><div onClick={this.handleSortChange} id="VALUE" className={this.state.sort === "VALUE" ? "sortSelect" : "sortTitle"}>Value</div></th>
										<th scope="col" className="table-title delete"><div onClick={this.handleSortChange} id="TYPE" className={this.state.sort === "TYPE" ? "sortSelect" : "sortTitle"}>Type</div></th>
										<th scope="col" className="table-title status">Status</th>
									</tr>
								</thead>
								<tbody className="table-body">
									{projectList}
								</tbody>
							</table>
						</div>
					</div>
				)}
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		client_projects: state.client_projects,
		errors: state.errors,
		panel: state.panel,
		db_data: state.db_data,
		user: state.user
	};
}

export default connect(mapStateToProps, {
		db_get_client_projects,
		db_get_client_dropdown,
		db_get_candidate_projects,
		db_get_am_client_projects,
		db_get_am_candidate_projects,
		db_get_am_client_dropdown,
		db_get_consultant_client_projects,
    db_get_terms,
		removeError,
		toggle,
		hide_panel,
		set_db_data,
		addCancel,
		set_page
	})(Client_ProjectData);
