import { AuthenticationContext } from 'react-adal';
import jwtDecode from 'jwt-decode';
import {apiCall} from './api';
import empty from 'is-empty';

const adalConfig = {
	tenant: '84624538-50aa-4736-89a2-5fbe3b9a04bf',
  clientId: '46499ae6-8546-4b05-bb22-38b2e96b2de8',
  endpoints: {
    'api': 'https://ops.utilicast.com',
    "https://graph.microsoft.com": "https://graph.microsoft.com",
    'logout': 'https://ops.utilicast.com/logout'
  },
  postLogoutRedirectUri: window.location.origin,
  redirectUri: process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://ops.utilicast.com',
  cacheLocation: 'localStorage'
};

export const authContext = new AuthenticationContext(adalConfig);

export const getToken = () => {
  return authContext.getCachedToken(authContext.config.clientId);
};

async function getProfileToken(email, token) {
  return await new Promise(function(resolve, reject) {
    authContext.acquireToken("https://graph.microsoft.com", function (error, id_token) {
      if (error || !id_token) {
        reject(error);
      } 
      else {
        getProfilePic(email, id_token, function(res) {
          resolve(res);
        });
      }
    });
  });
}

async function getProfilePic(email, token, callback) {
  await apiCall('get', 'https://graph.microsoft.com/v1.0/me/photo/$value',  
    { 
      headers: {
        "Authorization": "Bearer " + token, 
        "Access-Control-Allow-Origin": "*", "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
      }, 
      responseType: 'blob' 
    })
  .then(function(res) {
    const img = !empty(res) ? window.URL.createObjectURL(res) : "";
    callback(img);
  })
  .catch(function(err) {
    return err;
  });
}

export async function getUser() {
  const token = await getToken();
	const decoded = await jwtDecode(token);
  const header = await jwtDecode(token, {header: true});
  const pic = await getProfileToken(decoded.upn, token);

  return {
    email: decoded.upn,
    name: decoded.name,
    header: header,
    token: token,
    pic: pic
  }
}