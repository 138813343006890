import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {confirmAlert} from 'react-confirm-alert';
import moment from 'moment';
import empty from 'is-empty';

import {TextField, Grid, MenuItem, Input, FormControl, InputLabel, Select, Typography, Button, Tooltip} from '@material-ui/core';
import {GetApp, Delete, Info} from '@material-ui/icons';

import {db_update_client_project, db_add_client_project, db_archive_client_project, db_activate_candidate_project, state_remove_client_project, db_get_project_amendments, db_add_project_amendments, db_delete_project_amendments, db_update_invoicing} from '../../store/actions/tables/client_projects';
import {db_update_project_manager, db_update_project_client, sa_confirm_project_name, sa_get_project_reps, sa_get_project_tasks, sa_confirm_client_name} from '../../store/actions/peripherals/springahead';
import {db_get_sponsor_dropdown} from '../../store/actions/tables/sponsors';
import {db_return_consultant} from '../../store/actions/tables/consultants';
import {db_return_client} from '../../store/actions/tables/clients';
import {db_return_term} from '../../store/actions/tables/terms';

import {checkBlob} from '../../utils/checkBlob';
import {stringifyNumber} from '../../utils/stringifyNumber';
import {consultant_options, area_options, likelihood_options, archive_reason_options, term_options} from '../../utils/options';
import withToast from '../../utils/withToast';
import fileChange from '../../utils/fileChange';

import DatePicker from "../../components/Inputs/DatePicker";
import CurrencyInput from '../../components/Inputs/CurrencyInput';
import SaveBtn from '../../components/Buttons/SaveBtn';
import DeleteBtn from '../../components/Buttons/DeleteBtn';
import PlainBtn from '../../components/Buttons/PlainBtn';
import RepsBlock from '../../components/Blocks/RepsBlock';
import TaskPopup from '../../components/Popup/TaskPopup';
import InvoicePopup from '../../components/Popup/InvoicePopup';
import DownloadBtn from '../../components/Buttons/DownloadBtn';
import ModalLoading from '../../components/Graphics/ModalLoading';

class Client_ProjectDetail extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loaded: false,
			loading: false,
			data: {
				ID: '',
				CLIENT_ID: '',
				NAME: '',
				DESCRIPTION: '',
				TYPE: '',
				MANAGER_ID: '',
				PRACTICE_AREAS: [],
				START_DATE: '',
				END_DATE: '',
				VALUE: '',
				SPONSORS: [],
				SPRINGAHEAD_ID: '',
				BUDGET_ID: '',
				REP_ID: '',
				ARCHIVE: false,
				CANDIDATE: false,
				LEAD: null,
				LEAD_SUBMITTED: false,
        LEAD_SUBMITTED_AT: null,
				NEW_CLIENT: "",
				CONTRACT: "",
				LIKELIHOOD: 0,
        TERM: {},
        TERM_ID: '',
        PO_REQUIRED: true,
        REASON: null,
        OTHER_REASON: null,
			},
			validation: {
				dates: true,
				name: true,
				new_client: true
			},
			reps: [],
			tasks: [],
      termOptions: [],
			consultantOptions: consultant_options(props.consultants),
			taskPopup: false,
			invoicePopup: false,
			newClient: false,
			clientContract: false,
			projectContract: false,
			replaceContractBtn: false,
			addAmendmentBtn: true,
			amendments: [],
			attemptedSubmit: false
		}
	}

	mount = async () => {
		let clientContract = false;
		let projectContract = false;
		if(!this.props.candidate) {
			checkBlob(`${this.props.currentClientProject.CLIENT.ID}client`, 'contract', function(contract){
				clientContract = contract;
			});
			checkBlob(`${this.props.currentClientProject.ID}project`, 'contract', function(contract){
				projectContract = contract;
			});
		}
		const amendments = await db_get_project_amendments(this.props.currentClientProject.ID);

		await this.props.db_get_sponsor_dropdown(this.props.currentClientProject.CLIENT_ID);
		const reps = this.props.candidate ? [] : await sa_get_project_reps(this.props.currentClientProject.SPRINGAHEAD_ID);
		const tasks = this.props.candidate ? [] : await sa_get_project_tasks(this.props.currentClientProject.SPRINGAHEAD_ID);
		this.setState({
			...this.state,
			data: {
				...this.props.currentClientProject,
				SPONSORS: empty(this.props.currentClientProject.SPONSORS) ? [] : JSON.parse(this.props.currentClientProject.SPONSORS),
				PRACTICE_AREAS: empty(this.props.currentClientProject.PRACTICE_AREAS) ? [] : JSON.parse(this.props.currentClientProject.PRACTICE_AREAS),
				CONTRACT: "",
				NEW_CLIENT: ""
			},
			reps: reps,
			tasks: tasks,
			clientContract: clientContract,
			projectContract: projectContract,
			replaceContractBtn: projectContract,
			amendments: amendments,
      termOptions: term_options(this.props.terms),
			loaded: true
		});
	}

	async componentDidMount() {
		if(this.props.edit) {
			this.mount();
		} else {
			this.setState({
				...this.state,
        termOptions: term_options(this.props.terms),
				loaded: true
			});
		}
	}

	async componentDidUpdate(prevProps, prevState) {
		if(this.props.edit) {
			if(prevProps.currentClientProject.ID !== this.props.currentClientProject.ID) {
				this.mount();
			}
			if(prevProps.currentClientProject.MANAGER_ID !== this.props.currentClientProject.MANAGER_ID) {
				this.setState({...this.state, data: {...this.state.data, MANAGER_ID: this.props.currentClientProject.MANAGER_ID}});
			}
		}
		if(this.state.data.CLIENT_ID !== prevState.data.CLIENT_ID && !this.props.edit) {
			this.onNameBlur();
		}
		if(this.state.data.CLIENT_ID !== prevState.data.CLIENT_ID && !empty(this.state.data.CLIENT_ID)) {
			this.props.db_get_sponsor_dropdown(this.state.data.CLIENT_ID);
			if(!this.props.edit && !this.props.leads) {
				const client = await db_return_client(this.state.data.CLIENT_ID);
				this.setState({
					...this.state,
					data: {
						...this.state.data,
						MANAGER_ID: client.complete ? !empty(client.data) ? client.data.MANAGER_ID : null : null,
            TERM_ID: client.complete ? !empty(client.data) ? client.data.TERM_ID : null : null,
            PO_REQUIRED: client.complete ? !empty(client.data) ? client.data.PO_REQUIRED : true : true,
					}
				});
			}
		}
	}

	confirmRemoveAmendment = (name) => {
  	confirmAlert({
      title: 'Confirm Delete',
      message: 'Are you sure you want to delete this document? It will not be recoverable through our system.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.deleteAmendment(name)
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  }

	confirmRemove = () => {
  	confirmAlert({
      title: 'Confirm Archive',
      message: 'Are you sure you want to archive this project?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.handleRemove()
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  }

  confirmActivate = () => {
  	confirmAlert({
      title: 'Confirm Activate Project',
      message: 'Are you sure you want to activate this project? It will be added to SpringAhead as an active record.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.handleActivate()
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  }

  confirmSubmit = () => {
  	this.setState({...this.state, data: {...this.state.data, LEAD_SUBMITTED: true, LEAD_SUBMITTED_AT: moment().format('YYYY-MM-DD HH:mm:ss.SSS')}});
  	confirmAlert({
      title: 'Confirm Submit Lead',
      message: 'Are you sure you want to submit this lead to the client manager? You will not be able to edit it after this point.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
          	this.props.edit ? this.handlePut() : this.handleAdd();
          	this.props.state_remove_client_project(this.state.data);
          }
        },
        {
          label: 'No',
          onClick: () => this.setState({...this.state, data: {...this.state.data, LEAD_SUBMITTED: false}})
        }
      ]
    });
  }

	onContractUpload = e => {
		e.preventDefault();
		fileChange(e, blob => this.setState({...this.state, data: {...this.state.data, CONTRACT: blob}}));
	}

	onAmendmentUpload = e => {
		e.preventDefault();
		fileChange(e, async blob => {
			const {amendments} = this.state;
			const number = amendments.length === 0 ? 1 : Number(amendments[amendments.length - 1].substring(0,3)) + 1;
			let padToThree = number <= 999 ? `00${number}`.slice(-3) : number;
			const resp = await db_add_project_amendments({ID: this.props.currentClientProject.ID, NAME: `${padToThree}`, DOCUMENT: blob});
			resp.complete ? this.setState({...this.state, amendments: [...amendments, padToThree], addAmendmentBtn: true}) : this.setState({...this.state, addAmendmentBtn: true});
		});
	}

	deleteAmendment = async (name) => {
		const {amendments} = this.state;
		const resp = await db_delete_project_amendments({ID: this.props.currentClientProject.ID, NAME: name});
		if(resp.complete) this.setState({...this.state, amendments: amendments.filter(a => a !== name)});
	}

	onNameBlur = async () => {
		const client = this.props.edit ? this.props.currentClientProject.CLIENT : null;
		if(!empty(client)) {
			const namecheck = await sa_confirm_project_name(client.ABBREV, this.state.data.NAME);
			const editCheck = this.props.edit ? namecheck && (this.props.currentClientProject.NAME !== this.state.data.NAME) : false;
			this.setState({...this.state, validation: {...this.state.validation, name: this.props.edit ? !editCheck : !namecheck}});
		}
	}

	onAbbrevBlur = async () => {
		const namecheck = await sa_confirm_client_name(this.state.data.NEW_CLIENT);
		this.setState({...this.state, validation: {...this.state.validation, new_client: !namecheck }});
	}

	handleChange = e => {
		this.setState({
			...this.state,
			data: {
				...this.state.data,
				[e.target.name]: e.target.value
			}
		});
	}

  handleReasonChange = (e) => {
    this.setState({
    	...this.state,
    	data: {
				...this.state.data,
				REASON: e.target.value || null,
        OTHER_REASON: (e.target.value != 'other') ? null : this.state.data.OTHER_REASON,
			}
  	});
  }

	handleStartDateChange = (value: any) => {
    this.setState({
    	...this.state,
    	data: {
				...this.state.data,
				START_DATE: empty(value) ? '' : moment.utc(value).format('YYYY-MM-DD')
			}
  	});
  };

  handleEndDateChange = (value: any) => {
  	const after = empty(value) || moment.utc(value).isAfter(this.state.data.START_DATE) ? true : false;
    this.setState({
    	...this.state,
    	data: {
				...this.state.data,
				END_DATE: empty(value) ? '' : moment.utc(value).format('YYYY-MM-DD')
			},
			validation: {
				...this.state.validation,
				dates: after
			}
  	});
  };

  handleAdd = async () => {
  	this.setState({...this.state, loading: true});
		const clientData = await db_return_client(this.state.data.CLIENT_ID);
		const client = clientData.complete ? clientData.data : {};
		const manager = await this.props.db_return_consultant(client.MANAGER_ID);
		const resp = await this.props.db_add_client_project({
			...this.state.data,
			CLIENT_MANAGER_ID: manager.time_tracker_id,
			CLIENT_ABBREV: client.ABBREV,
			CANDIDATE: this.props.candidate ? true : false,
			LEAD_ID: this.props.user.id,
			PRACTICE_AREAS: JSON.stringify(this.state.data.PRACTICE_AREAS),
			SPONSORS: JSON.stringify(this.state.data.SPONSORS),
			LEAD_SUBMITTED: this.props.leads ? false : true,
      REASON: null,
      OTHER_REASON: null,
      TERM_ID: this.props.candidate ? client.TERM_ID : this.state.data.TERM_ID,
      PO_REQUIRED: this.props.candidate ? (client.PO_REQUIRED || 0) : this.state.data.PO_REQUIRED,
		});
		if(resp.complete) {
			this.props.addToast("Project successfully added.", {appearance: "success", autoDismiss: true});
		} else {
			this.props.addToast(resp.error, {appearance: "error", autoDismiss: true});
		}
		this.setState({...this.state, loading: false});
	}

	handlePut = async () => {
		this.setState({...this.state, loading: true});
		const clientData = await db_return_client(this.state.data.CLIENT_ID);
		const client = clientData.complete ? clientData.data : {};
		const manager = await this.props.db_return_consultant(client.MANAGER_ID);
		const resp = await this.props.db_update_client_project({
			...this.state.data,
			CLIENT_MANAGER_ID: manager.time_tracker_id,
			CLIENT_ABBREV: client.ABBREV,
			PRACTICE_AREAS: JSON.stringify(this.state.data.PRACTICE_AREAS),
			SPONSORS: JSON.stringify(this.state.data.SPONSORS),
      REASON: null,
      OTHER_REASON: null,
		});
		if(resp.complete) {
			this.props.addToast("Project successfully updated.", {appearance: "success", autoDismiss: true});
			this.props.onHide();
		} else {
			this.props.addToast(resp.error, {appearance: "error", autoDismiss: true});
		}
		this.setState({...this.state, loading: false});
	}

	handleActivate = async () => {
		this.setState({...this.state, loading: true});
		const clientData = await db_return_client(this.state.data.CLIENT_ID);
		const client = clientData.complete ? clientData.data : {};
		const manager = await this.props.db_return_consultant(client.MANAGER_ID);
		const resp = await this.props.db_activate_candidate_project({
			...this.state.data,
			CLIENT_MANAGER_ID: manager.time_tracker_id,
			CLIENT_ABBREV: client.ABBREV,
			CANDIDATE: false,
			PRACTICE_AREAS: JSON.stringify(this.state.data.PRACTICE_AREAS),
			SPONSORS: JSON.stringify(this.state.data.SPONSORS),
      REASON: null,
      OTHER_REASON: null,
		});
		if(resp.complete) {
			this.props.addToast("Project successfully activated.", {appearance: "success", autoDismiss: true});
			this.props.onHide();
		} else {
			this.props.addToast(resp.error, {appearance: "error", autoDismiss: true});
		}
		this.setState({...this.state, loading: false});
	}

	handleRemove = async () => {
		this.setState({...this.state, loading: true});
		const resp = await this.props.db_archive_client_project({...this.state.data, ARCHIVE: 1, CANDIDATE: this.props.candidate, TASKS: this.state.tasks});
		if(resp.complete) {
			this.props.addToast("Project successfully removed.", {appearance: "success", autoDismiss: true});
		} else {
			this.props.addToast(resp.error, {appearance: "error", autoDismiss: true});
		}
		this.setState({...this.state, loading: false});
	}

	render() {
		const {consultants, clientOptions, sponsorOptions, edit, candidate, leads, user, currentClientProject} = this.props;
		const {data, consultantOptions, termOptions, validation, reps, tasks, newClient, clientContract, projectContract, replaceContractBtn, addAmendmentBtn, amendments, taskPopup, invoicePopup, attemptedSubmit, loading, loaded} = this.state;

		const required = !empty(data.CLIENT_ID) && !empty(data.NAME) && !empty(data.MANAGER_ID) && !empty(data.START_DATE);
		const validated = required && validation.dates && validation.name;
    const disableArchive = candidate && !(data.REASON || false);

		return (
			<Fragment>
				{taskPopup && (<TaskPopup text="Tasks" project={data} onHide={() => this.setState({...this.state, taskPopup: false})} addTask={(task) => this.setState({...this.state, tasks: [...tasks, task]})} removeTask={(task) => this.setState({...this.state, tasks: tasks.filter(t => task.Id !== t.Id)})} data={tasks}/>)}
				{invoicePopup && (<InvoicePopup project={currentClientProject} onHide={() => this.setState({...this.state, invoicePopup: false})} onUpdate={this.props.db_update_invoicing} />)}
				{loading && (<ModalLoading />)}
				{loaded && (<div className="form">
					<form autoComplete="off">
						<Grid container item xs={12}>
							<Grid container item direction="column" xs={12} sm={6} style={{padding: "0 1rem"}}>
								<TextField
									label="Project Name"
									fullWidth
									required={!candidate}
									error={!validation.name || (empty(data.NAME) && attemptedSubmit)}
									helperText={validation.name ? (empty(data.NAME) && attemptedSubmit) ? "This field is required." : "" : "This name is already taken in SpringAhead. Please choose a different name."}
									maxLength="126"
									margin="dense"
									autoComplete="false"
									type="text"
									value={data.NAME}
									name="NAME"
									onChange={this.handleChange}
									onBlur={this.onNameBlur}
								/>
								<div style={{display: "flex", justifyContent: "space-between", alignItems: "flex-end"}}>
									{!newClient && (<TextField
					          select
					          fullWidth
					          required={!candidate}
					          error={empty(data.CLIENT_ID) && attemptedSubmit}
										helperText={(empty(data.CLIENT_ID) && attemptedSubmit) ? "This field is required." : ""}
					          label="Client"
					          margin="dense"
					          value={data.CLIENT_ID}
					          onChange={this.handleChange}
					          name="CLIENT_ID"
					          disabled={edit && !candidate}
					        >
					          {clientOptions.map((option) => (
					            <MenuItem key={option.value} value={option.value}>
					              {option.label}
					            </MenuItem>
					          ))}
					        </TextField>)}
					        {newClient && (<TextField
										label="Client"
										fullWidth
										maxLength="50"
										error={!validation.new_client}
										helperText={validation.new_client ? "" : "It appears this client is already in our system. If you do not see it in the dropdown, either contact Utilicast IT or enter a different name."}
										margin="dense"
										autoComplete="false"
										type="text"
										value={data.NEW_CLIENT}
										name="NEW_CLIENT"
                    disabled
										onChange={this.handleChange}
										onBlur={this.onAbbrevBlur}
									/>)}
					        {leads && !edit && (<span onClick={() => this.setState({...this.state, newClient: !newClient})} style={{background: "#01579B", color: "white", padding: "0.75rem 0.4rem", marginBottom: "4px", borderRadius: "3px", cursor: "pointer", marginLeft: "1rem", width: "8rem", textAlign: "center", verticalAlign: "center", fontWeight: "bold"}}>{!newClient ? "New" : "Existing"} Client</span>)}
								</div>
								<TextField
				          select
				          fullWidth
				          label="Type"
				          margin="dense"
				          value={data.TYPE}
				          onChange={this.handleChange}
				          name="TYPE"
				        >
				          {[{value: "Fixed Price", label: "Fixed Price"}, {value: "T&M", label: "T&M"}].map((option) => (
				            <MenuItem key={option.value} value={option.value}>
				              {option.label}
				            </MenuItem>
				          ))}
				        </TextField>
								{!leads && (<TextField
				          select
				          required={!candidate}
				          fullWidth
				          error={empty(data.MANAGER_ID) && attemptedSubmit}
									helperText={(empty(data.MANAGER_ID) && attemptedSubmit) ? "This field is required." : ""}
				          label="Project Manager"
				          margin="dense"
				          value={data.MANAGER_ID}
				          onChange={this.handleChange}
				          name="MANAGER_ID"
				          disabled={edit && !candidate}
				        >
				          {consultantOptions.map((option) => (
				            <MenuItem key={option.value} value={option.value}>
				              {option.label}
				            </MenuItem>
				          ))}
				        </TextField>)}
				        <FormControl margin="dense" fullWidth>
					        <InputLabel>Practice Area(s)</InputLabel>
					        <Select
					          multiple
					          value={data.PRACTICE_AREAS}
					          onChange={this.handleChange}
					          input={<Input />}
					          name="PRACTICE_AREAS"
					        >
					          {area_options.map((option) => (
					            <MenuItem key={option.value} value={option.value}>
					              {option.label}
					            </MenuItem>
					          ))}
					        </Select>
					      </FormControl>
								<CurrencyInput label="Value" name="VALUE" value={data.VALUE} onChange={this.handleChange}/>
								{(edit && !candidate) && (<Fragment><DatePicker value={data.START_DATE} onChange={this.handleStartDateChange} name="START_DATE" label="Start Date" required={!candidate} error={empty(data.START_DATE) && attemptedSubmit} errorMessage="This field is required."/>
								<DatePicker value={data.END_DATE} onChange={this.handleEndDateChange} name="END_DATE" label="End Date" error={!validation.dates && !empty(data.END_DATE)} errorMessage="The end date must be after the start date."/></Fragment>)}
							</Grid>
							<Grid container item direction="column" xs={12} sm={6} style={{padding: "0 1rem"}}>
								{edit && !candidate && (<Fragment><InputLabel style={{marginTop: "8px", marginBottom: "5px", fontSize: "0.95rem"}}><span>Project Commission</span><Tooltip title="Total should equal 50%"><Info style={{fontSize: "1rem"}}/></Tooltip><span>:</span></InputLabel>
									<RepsBlock
										reps={reps}
										consultants={consultants}
									/>
								</Fragment>)}
								{(!edit || candidate) && (<Fragment><DatePicker value={data.START_DATE} onChange={this.handleStartDateChange} name="START_DATE" label="Start Date" required={!candidate} error={empty(data.START_DATE) && attemptedSubmit} errorMessage="This field is required."/>
								<DatePicker value={data.END_DATE} onChange={this.handleEndDateChange} name="END_DATE" label="End Date" error={!validation.dates && !empty(data.END_DATE)} errorMessage="The end date must be after the start date."/></Fragment>)}
								<TextField
									label="Project Description (appears on SOW)"
									fullWidth
									placeholder="ex: Through a competitive process, Utilicast was hired to define requirements and develop a Statement of Work for replacement an Energy Management System (EMS)."
									multiline
									rows="6"
									rowsMax="10"
									type="text"
									value={data.DESCRIPTION}
									name="DESCRIPTION"
									onChange={e => this.setState({...this.state, data: {...data, DESCRIPTION: String(e.target.value).replace(/\s\s+/g, ' ')}})}
									inputProps={{ pattern: "[a-z]{1,15}" }}
								/>
								<FormControl margin="dense" fullWidth>
					        <InputLabel>Sponsor(s)</InputLabel>
					        <Select
					          multiple
					          value={data.SPONSORS}
					          onChange={this.handleChange}
					          input={<Input />}
					          name="SPONSORS"
					          disabled={sponsorOptions.length === 0 || newClient}
					        >
					          {sponsorOptions.map((option) => (
					            <MenuItem key={option.value} value={option.value}>
					              {option.label}
					            </MenuItem>
					          ))}
					        </Select>
					      </FormControl>
					      {candidate && (<FormControl margin="dense" fullWidth>
					        <InputLabel>Likelihood</InputLabel>
					        <Select
					          value={data.LIKELIHOOD}
					          onChange={this.handleChange}
					          input={<Input />}
					          name="LIKELIHOOD"
					        >
					          {likelihood_options.map((option) => (
					            <MenuItem key={option.value} value={option.value}>
					              {option.label}
					            </MenuItem>
					          ))}
					        </Select>
					      </FormControl>)}
                {!candidate && (<TextField
				          select
				          required
				          error={empty(data.TERM_ID) && attemptedSubmit}
									helperText={(empty(data.TERM_ID) && attemptedSubmit) ? "This field is required." : ""}
				          label="Term"
				          margin="dense"
				          value={data.TERM_ID}
				          onChange={this.handleChange}
				          name="TERM_ID"
				        >
				          {termOptions.map(option => (
				            <MenuItem key={option.value} value={option.value}>
				              {option.label}
				            </MenuItem>
				          ))}
				        </TextField>)}
                {!candidate && (<TextField
				          select
				          error={empty(data.PO_REQUIRED) && attemptedSubmit}
									helperText={(empty(data.PO_REQUIRED) && attemptedSubmit) ? "This field is required." : ""}
				          label="PO Required"
				          margin="dense"
				          value={data.PO_REQUIRED}
				          name="PO_REQUIRED"
                  disabled
				        >
                  <MenuItem key={true} value={true}>
                    Yes
                  </MenuItem>
                  <MenuItem key={false} value={false}>
                    No
                  </MenuItem>
				        </TextField>)}
					      {!candidate && (<Fragment>
						      <InputLabel style={{marginTop: "8px", marginBottom: "5px", fontSize: "0.95rem"}}>Governing Contract</InputLabel>
									<Button fullWidth style={{paddingLeft: "0"}}>
										<Grid container item justify="flex-start" alignItems="center">
											{projectContract && (<div style={{height: "3rem", width: "3rem", marginRight: "1rem", display: "flex", justifyContent: "center", alignItems: "center"}}><a href={`https://utilicastclientpictures.blob.core.windows.net/${data.ID}project/contract${user.sas}`} target="_none" style={{display: "flex", justifyContent: "center", alignItems: "center"}}><GetApp fontSize="large"/></a></div>)}
											{replaceContractBtn && (<Button size="small" variant="outlined" onClick={() => this.setState({...this.state, replaceContractBtn: false})}>REPLACE</Button>)}
											{!replaceContractBtn && (<input type="file" accept=".doc, .docx, .pdf" name="CONTRACT" onChange={this.onContractUpload}/>)}
										</Grid>
									</Button>
									<InputLabel style={{marginTop: "8px", marginBottom: "5px", fontSize: "0.95rem"}}>Governing Contract Amendments</InputLabel>
									<Button fullWidth style={{paddingLeft: "0"}}>
										<Grid container item justify="flex-start" alignItems="center">
											{amendments.map((amendment, i) => (
												<div style={{display: "flex", marginBottom: "0.2rem", marginRight: "0.5rem"}} key={i}>
													<Button onClick={() => this.confirmRemoveAmendment(amendment)} disableElevation variant="contained" size="small" style={{borderTopRightRadius: 0, borderBottomRightRadius: 0, color: "white", background: "red", minWidth: "1rem"}}><Delete fontSize="small" style={{color: "white"}}/></Button>
													<a href={`https://utilicastclientpictures.blob.core.windows.net/${data.ID}amendments/${amendment}${user.sas}`} target="_none" style={{flex: 1, height: "100%"}}><Button size="small" variant="contained" color="primary" disableElevation style={{color: "white", borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}>{stringifyNumber(i + 1)} Amd</Button></a>
												</div>
											))}
											{addAmendmentBtn && (<Button size="small" variant="outlined" onClick={() => this.setState({...this.state, addAmendmentBtn: false})}>ADD</Button>)}
											{!addAmendmentBtn && (<input type="file" accept=".doc, .docx, .pdf" name="CONTRACT" onChange={this.onAmendmentUpload}/>)}
										</Grid>
									</Button>
								</Fragment>)}
								{candidate && edit && !empty(data.LEAD.first_name) && (<Fragment>
									<InputLabel style={{marginTop: "8px", marginBottom: "5px", fontSize: "0.95rem"}}>Lead Entry</InputLabel>
									<Typography variant="body1"  style={{margin: "0.25rem 0"}}>{data.LEAD.first_name} {data.LEAD.last_name}</Typography>
								</Fragment>)}
							</Grid>
						</Grid>
						<div className="form-commands">
							<div className="btn-group">
								<SaveBtn onClick={(!validated && (!candidate || !validation.new_client)) ? () => {this.setState({...this.state, attemptedSubmit: true})} : edit ? this.handlePut : this.handleAdd} text="Save" disabled={!validated && (!candidate || !validation.new_client)}/>
								{(this.props.role === 'Admin' || (this.props.role === 'Client Manager' && candidate)) && (<DeleteBtn onClick={disableArchive ? ()=>false : (edit ? this.confirmRemove : this.props.handleCancel)} text={edit ? "Archive" : "Cancel"} delete={edit ? true : false} style={{marginLeft: "1.5rem"}} disabled={disableArchive}/>)}
                {(candidate && edit && ['Admin', 'Client Manager'].indexOf(this.props.role) !== -1) && (<FormControl style={{width: '100px', marginLeft: '1.5rem', marginTop: '-10px'}}>
					        <InputLabel>Archive Reason</InputLabel>
					        <Select
					          value={data.REASON}
					          onChange={this.handleReasonChange}
					          input={<Input />}
					          name="REASON"
					        >
					          {archive_reason_options.map((option) => (
					            <MenuItem key={option.value} value={option.value}>
					              {option.label}
					            </MenuItem>
					          ))}
					        </Select>
					      </FormControl>)}
                {(candidate && edit && data.REASON == 'other' && ['Admin', 'Client Manager'].indexOf(this.props.role) !== -1) && (<FormControl style={{width: '200px', marginLeft: '1.5rem', marginTop: '-15px'}}>
                  <TextField
                    label="Other Reason"
                    required={data.REASON == 'other'}
                    maxLength="255"
                    margin="dense"
                    autoComplete="false"
                    type="text"
                    value={data.OTHER_REASON}
                    name="OTHER_REASON"
                    onChange={this.handleChange}
                  />
					      </FormControl>)}
								{leads && validation.new_client && (<SaveBtn onClick={this.confirmSubmit} text="Submit to Client Manager" style={{marginLeft: "1.5rem"}}/>)}
								{candidate && edit && !leads && (<SaveBtn onClick={!validated ? () => {this.setState({...this.state, attemptedSubmit: true})} : this.confirmActivate} text="Make Active" disabled={!validated} style={{marginLeft: "1.5rem"}}/>)}
							</div>
							<div className="btn-group">
								{!candidate && edit && (<PlainBtn text="Invoicing" color="#01579B" onClick={() => this.setState({...this.state, invoicePopup: true})} style={{marginLeft: "1.5rem"}}/>)}
								{!candidate && edit && (<PlainBtn text="Tasks" color="#01579B" onClick={() => this.setState({...this.state, taskPopup: true})} style={{marginLeft: "1.5rem"}}/>)}
								{clientContract && (<DownloadBtn text="Client MSA" color="#01579B" onClick={() => { var win = window.open(`https://utilicastclientpictures.blob.core.windows.net/${data.CLIENT.ID}client/contract${user.sas}`, '_blank'); win.focus(); }} style={{marginLeft: "1.5rem"}}/>)}
							</div>
						</div>
					</form>
				</div>)}
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		errors: state.errors,
		consultants: state.consultants,
		clientOptions: state.clientOptions,
		sponsorOptions: state.sponsorOptions,
    terms: state.terms,
		user: state.user
	};
}

export default connect(mapStateToProps, {
	db_update_invoicing,
	db_update_project_client,
	db_add_client_project,
	db_update_project_manager,
	db_get_sponsor_dropdown,
	db_update_client_project,
	db_archive_client_project,
	db_return_consultant,
	db_activate_candidate_project,
	state_remove_client_project,
	sa_confirm_project_name
})(withToast(Client_ProjectDetail));
