import React, {Component, Fragment} from 'react';
import {Button} from '@material-ui/core';
import {withRouter} from 'react-router-dom';

class SOWBtn extends Component {

	onSave = e => {
		e.preventDefault();
		this.props.onSave();
	}

	render() {
		return (
			<Fragment>
				{this.props.redirect && this.props.history.push(this.props.route)}
				<Button variant="contained" onClick={this.props.disabled ? this.props.onClick : this.onSave} style={{...this.props.style, height: "3rem", background: this.props.disabled ? "" : this.props.color, color: "white", fontSize: "1rem", lineHeight: "0"}}>
					{this.props.text}
				</Button>
			</Fragment>
		);
	}
}

export default withRouter(SOWBtn);