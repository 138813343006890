import React, {Component, Fragment} from 'react';
import empty from 'is-empty';
import moment from 'moment';
import {confirmAlert} from 'react-confirm-alert';

import {TextField, Modal, Button, InputAdornment} from "@material-ui/core";
import {Close, Done, Edit} from "@material-ui/icons";

import {sa_add_project_task, sa_delete_project_task} from '../../store/actions/peripherals/springahead';
import {db_update_task} from '../../store/actions/tables/client_projects';

import DatePicker from '../Inputs/DatePicker';

class TaskPopup extends Component {

	constructor(props) {
		super(props);
		this.state = {
			task: {
				Id: '',
				Name: '',
				BillRate: '',
				ESTIMATED_BILL_DATE: ''
			},
			newRow: false,
			editId: '',
			error: ''
		}
	}

	addTask = async () => {
		const n = this.state.task.Name;
		const t = {
			...this.state.task,
			ProjectId: this.props.project.SPRINGAHEAD_ID,
			Name: `${this.props.project.CLIENT.ABBREV}${this.props.project.TYPE === "Fixed Price" ? " MS" : ""} - ${n} (${this.props.project.SPRINGAHEAD_ID})`
		};
		const resp = await sa_add_project_task(t);
		if(resp.complete) {
			this.props.addTask({...t, Id: resp.Id, InnerTaskId: resp.InnerTaskId});
			this.setState({...this.state, task: {Name: '', BillRate: '', ESTIMATED_BILL_DATE: ''}, newRow: false, error: ''});
		} else {
			this.setState({...this.state, error: resp.error});
		}
	}

	removeTask = async (task) => {
		const resp = await sa_delete_project_task({...task, ProjectId: this.props.project.SPRINGAHEAD_ID});
		if(resp.complete) {
			this.props.removeTask(task);
			this.setState({...this.state, newRow: false, error: ''});
		} else {
			this.setState({...this.state, error: resp.error});
		}
	}

	updateTask = async () => {
		const {task} = this.state;
		const resp = await db_update_task(task);
		if(resp.complete) {
			const existing = this.props.data.filter(t => t.Id === task.Id)[0];
			console.log(existing)
			this.props.removeTask(task);
			this.props.addTask({...existing, ESTIMATED_BILL_DATE: task.ESTIMATED_BILL_DATE});
			this.setState({...this.state, editId: '', task: {Id: '', Name: '', BillRate: '', ESTIMATED_BILL_DATE: ''}});
		} else {
			this.setState({...this.state, error: resp.error});
		}
	}

	confirmRemove = (task) => {
  	confirmAlert({
      title: 'Confirm Task Removal',
      message: 'Are you sure you want to remove this task. This has billing implications.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.removeTask(task)
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  }

  render() {
  	const {task, newRow, editId, error} = this.state;
  	const {data, project} = this.props;

  	let list = data.map(item => (
  		<tr key={item.Id}>
				<td data-container="body" className="table-data">{item.Name}</td>
				{project.TYPE === "Fixed Price" && (<Fragment>
					<td data-container="body" className="table-data">{empty(item.BillRate) ? "Not Specified" : `$${item.BillRate}`}</td>
					{(empty(editId) || editId !== item.Id) && (<td data-container="body" className="table-data">{empty(item.ESTIMATED_BILL_DATE) ? "" : moment.utc(item.ESTIMATED_BILL_DATE).format('YYYY-MM-DD')}</td>)}
					{!empty(editId) && editId === item.Id && (<DatePicker 
						value={task.ESTIMATED_BILL_DATE} onChange={(value: any) => this.setState({...this.state, task: {...task, ESTIMATED_BILL_DATE: empty(value) ? '' : moment.utc(value).format('YYYY-MM-DD')}})}
					/>)}
					<td data-container="body" className="table-data">{empty(item.ACTUAL_BILL_DATE) ? "NO" : "YES"}</td>
				</Fragment>)}
				<th data-container="body" className="table-data" style={{display: "flex", justifyContent: "flex-end"}}>
					<div style={{cursor: "pointer", height: "1.7rem", width: "1.7rem", borderRadius: "50%", background: "red", display: "flex", alignItems: "center", justifyContent: "center"}} onClick={() => this.confirmRemove(item)}>
						<Close style={{fontSize: "1rem", color: "white"}}/>
					</div>
					{!newRow && empty(editId) && project.TYPE === "Fixed Price" && (<div style={{cursor: "pointer", height: "1.7rem", width: "1.7rem", borderRadius: "50%", background: "grey", display: "flex", alignItems: "center", justifyContent: "center", marginLeft: "0.25rem"}} onClick={() => this.setState({...this.state, editId: item.Id, task: {...task, Id: item.Id, ESTIMATED_BILL_DATE: item.ESTIMATED_BILL_DATE}})}>
						<Edit style={{fontSize: "1rem", color: "white"}}/>
					</div>)}
					{!newRow && !empty(editId) && project.TYPE === "Fixed Price" && (<div style={{cursor: "pointer", height: "1.7rem", width: "1.7rem", borderRadius: "50%", background: empty(task.ESTIMATED_BILL_DATE) ? "lightgrey" : "green", display: "flex", alignItems: "center", justifyContent: "center", marginLeft: "0.25rem"}} onClick={empty(task.ESTIMATED_BILL_DATE) ? () => {} : () => this.updateTask()}>
						<Done style={{fontSize: "1rem", color: "white"}}/>
					</div>)}
				</th>
			</tr>
  	));

  	// list.push((<tr key="00000"><td colSpan="3" style={{fontSize: "1rem", color: "orange"}}>*Tasks can be used to enter time as a sub-project level. Contact accounting to add tasks to your project.</td></tr>))

  	return(
  		<Modal open onClose={this.props.onHide} style={{display: "flex", height: "100vh", width: "100vw", justifyContent: "center", alignItems: "center"}}>
  			<div style={{background: "white", padding: "1rem", fontSize: "1.2rem", display: "flex", flexDirection: "column", borderRadius: "6px", minWidth: "30rem"}}>
					<div className="project-table" style={{marginBottom: "2rem"}}>
						<table className="table" style={{marginBottom: 0}}>
							<thead>
								<tr className="table-head">
									<th scope="col" className="table-title leftrow">Task</th>
									{project.TYPE === "Fixed Price" && (<Fragment>
										<th scope="col" className="table-title">Amount</th>
										<th scope="col" className="table-title">Date</th>
										<th scope="col" className="table-title">Billed</th>
									</Fragment>)}
									<th scope="col" className="table-title status"></th>
								</tr>
							</thead>
							<tbody className="table-body">
								{list}
								{newRow && (<tr key={-2}>
									<td data-container="body" className="table-data">
										<TextField 
											type="text" 
											variant="outlined" 
											inputProps={{style: {padding: "0.5rem 0"}, maxLength: 128 - (`${project.CLIENT.ABBREV}${project.TYPE === "Fixed Price" ? " MS" : ""} -- (${project.SPRINGAHEAD_ID})`).length}} 
											maxLength="5"
											InputProps={{startAdornment: <InputAdornment position="start" style={{padding: 0}}>{project.CLIENT.ABBREV}{project.TYPE === "Fixed Price" ? " MS" : ""} -</InputAdornment>}}
											style={{minWidth: "20rem", width: "100%"}}
											value={task.Name} 
											onChange={(e) => this.setState({...this.state, task: {...task, Name: e.target.value}})}
										/>
									</td>
									{project.TYPE === "Fixed Price" && (<Fragment>
										<td data-container="body" className="table-data" style={{maxWidth: "10rem"}}>
											<TextField 
												type="number" 
												variant="outlined" 
												inputProps={{style: {padding: "0.5rem 0"}}} 
												InputProps={{startAdornment: <InputAdornment position="start" style={{padding: 0}}>$</InputAdornment>}}
												value={task.BillRate} 
												onChange={(e) => this.setState({...this.state, task: {...task, BillRate: e.target.value}})}
											/>
										</td>
										<td data-container="body" className="table-data" style={{width: "15rem"}}>
											<DatePicker 
												value={task.ESTIMATED_BILL_DATE} onChange={(value: any) => this.setState({...this.state, task: {...task, ESTIMATED_BILL_DATE: empty(value) ? '' : moment.utc(value).format('YYYY-MM-DD')}})}
											/>
										</td>
										<td data-container="body" className="table-data" style={{maxWidth: "10rem"}}>NO</td>
									</Fragment>)}
									<th data-container="body" className="table-data" style={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
										<Button variant="contained" size="small" style={{background: empty(task.Name) ? "lightgrey" : "green", display: "flex", alignItems: "center", justifyContent: "center", color: empty(task.Name) ? "black" : "white"}} onClick={empty(task.Name) ? () => {} : this.addTask}>SAVE</Button>
									</th>
								</tr>)}
								{!empty(error) && (<span style={{color: "red"}}>{error}</span>)}
								{!newRow && empty(editId) && (<tr key={-2}>
									<td data-container="body" className="table-data" colSpan={project.TYPE === "Fixed Price" ? "6" : "2"} style={{padding: "0.5rem 0"}}><div style={{width: "100%", display: "flex", justifyContent: "flex-end"}}><Button size="small" color="primary" variant="contained" onClick={() => this.setState({...this.state, newRow: true})}>Add</Button></div></td>
								</tr>)}
							</tbody>
						</table>
					</div>
					<div style={{width: "100%", display: "flex", justifyContent: "flex-end", padding: "1rem"}}>
						<Button onClick={this.props.onHide} variant="contained" style={{backgroundColor: "red", color: "white"}}>Close</Button>
					</div>
  			</div>
  		</Modal>
  	);
  }
}
 
export default TaskPopup;

