import {SET_DB_DATA} from '../../actionTypes';

const initialState = {
	title: 'Your Assignments',
	search: '',
	asc: true,
	search_btn: false,
	export_btn: false,
	sort_btn: false,
	add_btn: false,
	filter_btn: false
}

const db_data = (state = initialState, action) => {
	switch(action.type) {
		case SET_DB_DATA:
			return action.data
		default:
			return state;
	}
}

export default db_data;