import React, {Component, Fragment} from 'react';
import empty from 'is-empty';

import {TextField, InputLabel} from '@material-ui/core';

import CurrencyInput from '../Inputs/CurrencyInput';
import ExpenseOptions from '../Options/ExpenseOptions';
import AtCostOptions from '../Options/AtCostOptions';
import FixedCostOptions from '../Options/FixedCostOptions';

class ExpensesBlock extends Component {
	constructor(props) {
		super(props);
		this.state = {
			type: '',
			detail: '',
			amount: '',
			numTime: ''
		}
		this.onTypeChange = this.onTypeChange.bind(this);
	}

	async componentDidUpdate(prevProps, prevState) {
		if(prevState.detail !== this.state.detail) {
			await this.setState({
				...this.state,
				amount: '',
				numTime: ''
			});
			this.updateParent({EXPENSES_AMOUNT: ''});
		}
	}

	updateParent = values => {
		const local = this.state;
		this.props.onChange({
			...values,
			EXPENSES_DESC: local.type === "At cost" ? `At cost ${local.detail === "Limit" ? "limited to the amount given." : "not limited to an amount."}` : 
					 local.type === "Fixed cost" ? local.detail !== "One time" ? `At a fixed cost of $${Number(local.amount).toFixed(2)} ${local.detail.toLowerCase()} for ${local.numTime} ${local.detail.substring(4, local.detail.length)}s.` : 
					 `At a one-time fixed cost of $${Number(local.amount).toFixed(2)}.` : "No expenses"
		});
	}

	onTypeChange = async val => {
		await this.setState({
			...this.state,
			type: val,
			detail: ''
		});
		this.updateParent({EXPENSES_TYPE: val, EXPENSES_AMOUNT: ''});
	}

	onDetailChange = async val => {
		await this.setState({
			...this.state,
			detail: val
		});
	}

	onTimeChange = async e => {
		await this.setState({
			...this.state,
			numTime: e.target.value
		});
		const amount = ((empty(this.state.amount) ? 0 : Number(this.state.amount)) * (empty(this.state.numTime) ? 0 : Number(this.state.numTime)));
		this.updateParent({EXPENSES_AMOUNT: amount});
	}

	onAmountChange = async e => {
		await this.setState({
			...this.state,
			amount: e.target.value
		});
		const amount = ((empty(this.state.amount) ? 0 : Number(this.state.amount)) * (empty(this.state.numTime) ? 0 : Number(this.state.numTime)));
		this.updateParent({EXPENSES_AMOUNT: amount});
	}

	render() {

		const {data} = this.props;
		const {detail, numTime, amount} = this.state;

		return (
			<div className="">
				<div className="row">
					<div className="col-md-12">
						{!this.props.edit && (<Fragment>
							<div className="row">
								<div className="top-expenses-container">
									<div className="top-expenses-side">
										<InputLabel style={{marginTop: "8px", marginBottom: "5px", fontSize: "0.95rem"}}>Expenses Type:</InputLabel>
										<ExpenseOptions 
											onSelect={this.onTypeChange} 
											onClearDetail={this.onDetailChange}
										/>
									</div>
									{data.EXPENSES_TYPE !== "No expenses" && !empty(data.EXPENSES_TYPE) && (<div className="top-expenses-side">
										<InputLabel style={{marginTop: "8px", marginBottom: "5px", fontSize: "0.95rem"}}>{data.EXPENSES_TYPE === "At cost" ? "Limited?:" : "Frequency:"}</InputLabel>
										{data.EXPENSES_TYPE === "At cost" ? <AtCostOptions onSelect={this.onDetailChange}/> : <FixedCostOptions onSelect={this.onDetailChange}/>}
									</div>)}
								</div>
							</div>
							{!empty(detail) && (<div className="row">
								<div style={{display: "flex", justifyContent: "space-between", padding: "0 15px"}}>
									{(data.EXPENSES_TYPE === "Fixed cost" && detail !== "One time") && (<div className="fixed-cost-detail-container">
										<div className="fixed-cost-detail-option" style={{width: "45%"}}>
											<TextField fullWidth margin="dense" value={numTime} label={`How many ${detail.substring(4, detail.length)}s?`} name="numTime" type="number" onChange={this.onTimeChange} placeholder="#"/>
										</div>
										<div className="fixed-cost-detail-option" style={{width: "53%"}}>
											<CurrencyInput value={amount} label={`How much per ${detail.substring(4, detail.length)}?`} name="amount" onChange={this.onAmountChange} placeholder="Amount"/>
										</div>
									</div>)}
									{!(data.EXPENSES_TYPE === "No expenses" || (data.EXPENSES_TYPE === "At cost" && detail === "No Limit")) && (<div className="fixed-cost-detail-container">
										<div className="fixed-cost-detail-option" style={{width: "100%"}}>
											<CurrencyInput disabled={(data.EXPENSES_TYPE === "Fixed cost" && detail !== "One time")} label="Total" value={data.EXPENSES_AMOUNT} name="amount" onChange={async e => {await this.setState({amount: e.target.value}); this.updateParent({EXPENSES_AMOUNT: this.state.amount})}} placeholder="Amount"/>
										</div>
									</div>)}
								</div>
							</div>)}
						</Fragment>)}
						{this.props.edit && (<Fragment>
							<div className="row">
								<div className="top-expenses-container">
									<div className="top-expenses-side">
										<InputLabel style={{marginTop: "8px", marginBottom: "5px", fontSize: "0.95rem"}}>Expenses Type:</InputLabel>
										<ExpenseOptions 
											onSelect={val => this.props.onChange({EXPENSES_TYPE: val})} 
											chosen={data.EXPENSES_TYPE}
										/>
									</div>
									<div className="top-expenses-side">
										<CurrencyInput value={data.EXPENSES_AMOUNT} label="Total" name="amount" onChange={async e => {this.props.onChange({EXPENSES_AMOUNT: e.target.value})}} placeholder="Amount"/>
									</div>
								</div>
							</div>
							{data.EXPENSES_TYPE !== "No expenses" && (<div className="row">
								<div style={{padding: "0 15px", width: "100%"}}>
									<TextField margin="dense" fullWidth disabled={this.props.contractDownloadDisplay} label="Expenses Description" value={data.EXPENSES_DESC} name="description" onChange={e => {this.props.onChange({EXPENSES_DESC: e.target.value})}} type="text" placeholder="Description"/>
								</div>
							</div>)}
						</Fragment>)}
					</div>
				</div>
			</div>
		);
	}
}

export default ExpensesBlock;
