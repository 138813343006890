import React, {Component} from 'react';
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';
import 'quill-paste-smart';

class RichText extends Component {

	render() {

		const modules = {
    	toolbar: [
	      ['bold', 'italic', 'underline','strike'],
	      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}]
      ],
      clipboard: {
        allowed: {
          tags: ['a', 'b', 'strong', 'em', 'u', 's', 'i', 'p', 'br', 'ul', 'ol', 'li', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
          attributes: ['href', 'rel', 'target']
        },
        keepSelection: true,
      },
	  }

	  const formats = [
	    'bold', 'italic', 'underline', 'strike',
	    'list', 'bullet', 'indent'
	  ]

		return (
			<ReactQuill theme="snow" modules={modules} formats={formats} value={this.props.value} onChange={this.props.onChange} style={this.props.style}/>
		)
	}
}

export default RichText;
