import {GET_PROFESSIONALS, ADD_PROFESSIONAL, UPDATE_PROFESSIONAL, REMOVE_PROFESSIONAL} from '../../actionTypes';

const professionals = (state = [], action) => {
	switch(action.type) {
		case GET_PROFESSIONALS:
			return [...action.payload];
		case ADD_PROFESSIONAL:
			return [...state, action.payload];
		case UPDATE_PROFESSIONAL:
			return state.map(professional => {
				if(action.payload.ID === professional.ID) {
					return action.payload;
				} else {
					return professional;
				}
			});
		case REMOVE_PROFESSIONAL:
			return state.filter(professional => {
				return professional.ID !== action.payload.ID;
			});
		default:
			return state;
	}
}

export default professionals;