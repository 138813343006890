import {apiCall} from '../../../services/api';
import {DB_ERROR} from '../utils/errors';

export const db_return_contract = id => {
	return apiCall('get', `/api/contracts/${id}`)
	.then(function(res) {
		return {complete: true, data: res};
	})
	.catch(function(err) {
		return {complete: false, error: err.data.error};
	});
}

export const db_update_contract = contract => dispatch => {
	const id = contract.ID;
	return apiCall('put', `/api/contracts/${id}`, contract)
	.then(function(res) {
		return {complete: true};
	})
	.catch(function(err) {
		return {complete: false, error: err.data.error};
	});
}

export const db_update_docusign_id = contract => dispatch => {
	return apiCall('put', `/api/contracts/docusign/${contract.ID}`, contract)
	.then(function(res) {
		return {complete: true};
	})
	.catch(function(err) {
		return {complete: false, error: err.data.error};
	});
}

export const db_add_contract = contract => dispatch => {
	return apiCall('post', `/api/contracts`, contract)
	.then(function(res) {
		return {complete: true};
	})
	.catch(function(err) {
		return {complete: false, error: err.data.error};
	});
}