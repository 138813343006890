import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';

import {removeError} from '../../store/actions/utils/errors';
import {toggle, hide_panel} from '../../store/actions/utils/panel';
import {db_get_clients} from '../../store/actions/tables/clients';
import {db_get_terms} from '../../store/actions/tables/terms';
import {set_db_data} from '../../store/actions/utils/db_data';
import {addCancel} from '../../store/actions/utils/addbtn';
import {set_page} from '../../store/actions/utils/page';

import {sortList} from '../../utils/sortList';
import {searchList} from '../../utils/searchList';

import ClientRow from '../../components/Rows/ClientRow';
import ClientDetail from './ClientDetail';
import Loading from '../../components/Graphics/Loading';

class ClientData extends Component {
	constructor(props){
		super(props);
		this.state = {
			loaded: false,
			sort: 'NAME',
			id: null,
			admin: this.props.role === "Admin"
		}
	}

	async componentDidMount() {
		await this.state.admin ? this.props.set_page("clients") : this.props.set_page("consultant-clients");
		await this.props.hide_panel();
		await this.props.db_get_terms();
		await this.props.db_get_clients();
		await this.props.set_db_data({
			...this.props.db_data,
			title: this.state.admin ? "Clients" : "Client Managers",
			sort_btn: true,
			add_btn: this.state.admin ? true : false,
			search_btn: true,
			export_btn: true,
			filter_btn: false
		});
		this.setState({loaded: true});
	}

	componentWillReceiveProps(nextProps) {
		if(this.props.role !== nextProps.role) {
			this.setState({...this.state, loaded: false});
			const admin = nextProps.role === "Admin";
			admin ? this.props.set_page("clients") : this.props.set_page("consultant-clients");
			this.props.set_db_data({
				...this.props.db_data,
				title: admin ? "Clients" : "Client Managers",
				sort_btn: true,
				add_btn: admin ? true : false,
				search_btn: true,
				export_btn: true,
				filter_btn: false
			});
			this.setState({
				...this.state,
				admin: admin,
				loaded: true
			});
		}
	}

	async handleRowClicked(client) {
		await this.setState({
			...this.state,
			id: !this.state.admin ? null : client.ID === this.state.id ? null : client.ID
		});

		this.props.hide_panel();
	}

	handleChange = e => {
		this.setState({
			[e.target.name]: e.target.value
		});
	}

	handleSortChange = e => {
		this.setState({
			...this.state,
			'sort': e.target.id
		});
	}

	render() {

		const {clients, errors, db_data, role} = this.props;
		const {id} = this.state;

		let clientList = sortList(this.state.sort, db_data.asc, searchList(db_data.search.toLowerCase(), clients)).map(client =>
			<Fragment key={client.ID}>
				<ClientRow
					client={client}
					handleRowClicked={this.handleRowClicked.bind(this, client)}
					id={id}
					role={role}
				/>
				{id === client.ID && (
					<tr>
						<td colSpan="6" className="editForm">
							<ClientDetail currentClient={client} onHide={() => this.setState({...this.state, id: null})} edit/>
						</td>
					</tr>
				)}
			</Fragment>
		);
		return (
			<Fragment>
				{!this.state.loaded && (
					<Loading />
				)}
				{errors.message && (
					<div className="alert alert-danger">
						{errors.message}
					</div>
				)}
				{this.state.loaded && (
					<div className="container1">
						{this.props.panel && (
							<div className="container-add">
								<ClientDetail handleCancel={this.props.addCancel} edit={false}/>
							</div>
						)}
						<div className="project-table">
							<table className="table">
								<thead>
									<tr>
										<th scope="col" className="table-title leftrow"><div onClick={this.handleSortChange} id="NAME" className={this.state.sort === "NAME" ? "sortSelect" : "sortTitle"}>Legal Name</div></th>
										<th scope="col" className="table-title"><div onClick={this.handleSortChange} id="ABBREV" className={this.state.sort === "ABBREV" ? "sortSelect" : "sortTitle"}>Abbreviation</div></th>
										<th scope="col" className="table-title"><div onClick={this.handleSortChange} id="MANAGER_ID" className={this.state.sort === "MANAGER_ID" ? "sortSelect" : "sortTitle"}>Client Manager</div></th>
										<th scope="col" className="table-title">Address</th>
										<th scope="col" className="table-title status">Client Teams Site</th>
									</tr>
								</thead>
								<tbody className="table-body">
									{clientList}
								</tbody>
							</table>
						</div>
					</div>
				)}
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		clients: state.clients,
		currentClient: state.currentClient,
		errors: state.errors,
		panel: state.panel,
		db_data: state.db_data
	};
}

export default connect(mapStateToProps, {
	db_get_clients,
  db_get_terms,
	removeError,
	toggle,
	hide_panel,
	set_db_data,
	addCancel,
	set_page
})(ClientData);
