import React, {Component, Fragment} from 'react';
import {Switch, Route, withRouter, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import is from 'is_js';

import {set_user} from '../store/actions/utils/user';
import {getUser} from '../services/auth';
import {db_get_email_consultant, db_get_consultants} from '../store/actions/tables/consultants';

import Homepage from './Homepage';
import ContractData from './Contract/ContractData';
import AmendedData from './Amended/AmendedData';
import Loading from '../components/Graphics/Loading';
import SafariError from '../components/Security/SafariError';
import ServerDown from '../components/Security/ServerDown';

class Home extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loaded: false,
			safariError: false,
			serverDown: false
		}
	}

	async componentDidMount() {
		getUser().then((auth_user) => {
			db_get_email_consultant(auth_user).then(async (consultant) => {
				localStorage.setItem('apiToken', consultant.token);
				await this.props.set_user({
					...auth_user,
					role: consultant.role,
					id: consultant.result.time_tracker_id,
					sas: consultant.result.SAS_TOKEN
				});
				await this.props.db_get_consultants();
				this.setState({...this.state, loaded: true});
			}).catch((e) => {
				this.setState({...this.state, loaded: true, serverDown: true});
			});
		}).catch((error) => {
			this.setState({...this.state, loaded: true, safariError: is.safari(), serverDown: !is.safari()});
		})
	}

	render() {
		return (
			<Fragment>
				{!this.state.loaded && (
					<Loading />
				)}
				{this.state.safariError && (
					<SafariError />
				)}
				{this.state.serverDown && (
					<ServerDown />
				)}
				{this.state.loaded && !this.state.safariError && !this.state.serverDown && (<Switch>
					<Route exact path='/' render={props => <Redirect to="/dashboard"/>} />
					<Route path='/dashboard' render={({props, match}) => <Homepage user={this.props.user} match={match} {...props}/>} />
					<Route exact path='/assignments/admin/:id' render={props => <ContractData role="Admin" user={this.props.user} {...props}/>} />
					<Route exact path='/assignments/am/:id' render={props => <ContractData role="Client Manager" user={this.props.user} {...props}/>} />
					<Route exact path='/amendment/admin/:id' render={props => <AmendedData role="Admin" user={this.props.user} {...props}/>} />
					<Route exact path='/amendment/am/:id' render={props => <AmendedData role="Client Manager" user={this.props.user} {...props}/>} />
					<Route path='/*' render={props => <Redirect to="/dashboard"/>} />
				</Switch>)}
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		user: state.user
	};
}

export default connect(mapStateToProps, {set_user, db_get_consultants})(withRouter(Home));

