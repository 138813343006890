import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';

import {db_delete_key, db_add_key, db_get_keys} from '../../store/actions/tables/keys';
import {set_db_data} from '../../store/actions/utils/db_data';
import {set_page} from '../../store/actions/utils/page';
import {addCancel} from '../../store/actions/utils/addbtn';

import {sortList} from '../../utils/sortList';
import {searchList} from '../../utils/searchList';

import KeyRow from '../../components/Rows/KeyRow';
import Loading from '../../components/Graphics/Loading';
import AddKeyPopup from '../../components/Popup/AddKeyPopup';

class KeyData extends Component {
	constructor(props){
		super(props);
		this.state = {
			loaded: false,
			sort: 'NAME'
		}
	}

	async componentDidMount() {
		await this.props.set_page("keys");
		await this.props.db_get_keys();
		await this.props.set_db_data({...this.props.db_data, title: "API Keys", sort_btn: true, add_btn: true, search_btn: true, export_btn: false, filter_btn: false});
		this.setState({loaded: true});
	}

	handleSortChange = e => {
		this.setState({
			...this.state,
			'sort': e.target.id
		});
	}

	onDelete = key => {
		this.props.db_delete_key(key);
	}

	render() {
		const {keys, role, db_data, errors} = this.props;
		let roleList = keys;
		let list = sortList(this.state.sort, db_data.asc, searchList(db_data.search, roleList));
		let keyList = list.map(keyRow => 
			<Fragment key={keyRow.id}>
				<KeyRow
					keyRow={keyRow}
					role={role}
					onDelete={this.onDelete}
				/>
			</Fragment>
		);
		return (
			<Fragment>
				{!this.state.loaded && (
					<Loading />
				)}
				{errors.message && (
					<div className="alert alert-danger">
						{errors.message}
					</div>
				)}
				{this.props.panel && (<AddKeyPopup onSave={data => this.props.db_add_key(data)} onHide={() => this.props.addCancel()} text="Enter API Key Name"/>)}
				{this.state.loaded && (
					<div className="container1">
						<div className="project-table">
							<table className="table">
								<thead>
									<tr className="table-head">
										<th scope="col" className="table-title leftrow">ID</th>
										<th scope="col" className="table-title"><div onClick={this.handleSortChange} id="NAME" className={this.state.sort === "NAME" ? "sortSelect" : "sortTitle"}>Name</div></th>
										<th scope="col" className="table-title">Token (click key to copy)</th>
										<th scope="col" className="table-title status"></th>
									</tr>
								</thead>
								<tbody className="table-body">
									{keyList}
								</tbody>
							</table>
						</div>
					</div>
				)}
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		keys: state.keys,
		db_data: state.db_data,
		user: state.user,
		errors: state.errors,
		panel: state.panel
	};
}

export default connect(mapStateToProps, {
		db_get_keys,
		db_add_key,
		db_delete_key,
		set_db_data,
		set_page,
		addCancel
	})(KeyData);



