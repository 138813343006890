import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {confirmAlert} from 'react-confirm-alert';
import empty from 'is-empty';

import {TextField, Grid, MenuItem, Button, InputLabel} from '@material-ui/core';
import {GetApp, Delete} from '@material-ui/icons';

import {db_update_client, db_archive_client, db_add_client, db_get_client_amendments, db_add_client_amendments, db_delete_client_amendments} from '../../store/actions/tables/clients';
import {db_return_consultant} from '../../store/actions/tables/consultants';
import {sa_confirm_client_name} from '../../store/actions/peripherals/springahead';

import {stringifyNumber} from '../../utils/stringifyNumber';
import {consultant_options, term_options} from '../../utils/options';
import {checkBlob} from '../../utils/checkBlob';
import withToast from '../../utils/withToast';
import fileChange from '../../utils/fileChange';

import SaveBtn from '../../components/Buttons/SaveBtn';
import DeleteBtn from '../../components/Buttons/DeleteBtn';
import AddressBlock from '../../components/Blocks/AddressBlock';
import ModalLoading from '../../components/Graphics/ModalLoading';

class ClientDetail extends Component {

	constructor(props) {
		super(props);
		this.state = {
			data: {
				ID: '',
				NAME: '',
				ABBREV: '',
				MANAGER: {},
				MANAGER_ID: '',
				ADDRESS: '',
				ENTITY: '',
				DELIVERABLES_LINK: '',
				IMG: '',
				CONTRACT: '',
				CITY: '',
				STATE: '',
				ZIP: '',
				SPRINGAHEAD_ID: '',
				COUNTRY: '',
				TERMS_LINK: '',
        TERM: {},
        TERM_ID: '',
        PO_REQUIRED: true,
			},
			managerOptions: [],
			contractExists: false,
			replaceBtn: false,
			addAmendmentBtn: true,
			amendments: [],
			loaded: false,
			loading: false,
			attemptedSubmit: false,
			nameValidated: true,
      termOptions: []
		}
	}

	async componentDidMount() {
    const tthis = this;
		const amendments = this.props.currentClient ? await db_get_client_amendments(this.props.currentClient.ID) : [];
		checkBlob(`${!this.props.edit ? 0 : this.props.currentClient.ID}client`, 'contract', function(contract){
			tthis.setState({
				...tthis.state,
				data: tthis.props.edit ? {...tthis.props.currentClient, CONTRACT: ''} : tthis.state.data,
				managerOptions: consultant_options(tthis.props.consultants),
				contractExists: contract,
				replaceBtn: contract,
				amendments: amendments,
        termOptions: term_options(tthis.props.terms),
				loaded: true
			});
		});
	}

	componentDidUpdate(prevProps, prevState) {
		if(this.props.edit) {
			if(prevProps.currentClient.ID !== this.props.currentClient.ID) {
				const tthis = this;
				checkBlob(`${this.props.currentClient.ID}client`, 'contract', function(contract){
					tthis.setState({...tthis.state, data: {...this.props.currentClient, CONTRACT: ''}, contractExists: contract, replaceBtn: contract, loaded: true});
				});
			}
		}
	}

	onImageUpload = e => {
		e.preventDefault();
		fileChange(e, blob => this.setState({...this.state, data: {...this.state.data, IMG: blob}}));
	}

	onContractUpload = e => {
		e.preventDefault();
		fileChange(e, blob => this.setState({...this.state, data: {...this.state.data, CONTRACT: blob}}));
	}

	onAmendmentUpload = e => {
		e.preventDefault();
		fileChange(e, async blob => {
			const {amendments} = this.state;
			const number = amendments.length === 0 ? 1 : Number(amendments[amendments.length - 1].substring(0,3)) + 1;
			let padToThree = number <= 999 ? `00${number}`.slice(-3) : number;
			const resp = await db_add_client_amendments({ID: this.props.currentClient.ID, NAME: `${padToThree}`, DOCUMENT: blob});
			resp.complete ? this.setState({...this.state, amendments: [...amendments, padToThree], addAmendmentBtn: true}) : this.setState({...this.state, addAmendmentBtn: true});
		});
	}

	deleteAmendment = async (name) => {
		const {amendments} = this.state;
		const resp = await db_delete_client_amendments({ID: this.props.currentClient.ID, NAME: name});
		if(resp.complete) this.setState({...this.state, amendments: amendments.filter(a => a !== name)});
	}

	handleChange = e => {
		this.setState({
			...this.state,
			data: {
				...this.state.data,
				[e.target.name]: e.target.value
			},
			attemptedSubmit: false
		});
	}

	onAbbrevBlur = async () => {
		const namecheck = await sa_confirm_client_name(this.state.data.ABBREV);
		const editCheck = this.props.edit ? namecheck && (this.props.currentClient.ABBREV !== this.state.data.ABBREV) : false;
		this.setState({...this.state, nameValidated: this.props.edit ? !editCheck : !namecheck });
	}

	confirmRemove = () => {
  	confirmAlert({
      title: 'Confirm Archive',
      message: 'Are you sure you want to archive this client?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.handleRemove()
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  }

  confirmRemoveAmendment = (name) => {
  	confirmAlert({
      title: 'Confirm Delete',
      message: 'Are you sure you want to delete this document? It will not be recoverable through our system.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.deleteAmendment(name)
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  }

	handleAdd = async () => {
		this.setState({...this.state, loading: true});
		const resp = await this.props.db_add_client(this.state.data);
		if(resp.complete) {
			this.props.addToast('Client successfully added.', {appearance: 'success', autoDismiss: true});
		} else {
			this.props.addToast(resp.error, {appearance: 'error', autoDismiss: true});
		}
		this.setState({...this.state, loading: false});
	}

	handlePut = async () => {
		this.setState({...this.state, loading: true});
		const resp = await this.props.db_update_client({...this.state.data, UPDATE_REPS: this.props.currentClient.MANAGER_ID !== this.state.data.MANAGER_ID});
		if(resp.complete) {
			this.props.addToast('Client successfully updated.', {appearance: 'success', autoDismiss: true});
			this.props.onHide();
		} else {
			this.props.addToast(resp.error, {appearance: 'error', autoDismiss: true});
		}
		this.setState({...this.state, loading: false});
	}

	handleRemove = async () => {
		this.setState({...this.state, loading: true});
		const resp = await this.props.db_archive_client({...this.state.data, ARCHIVE: true});
		if(resp.complete) {
			this.props.addToast('Client successfully removed.', {appearance: 'success', autoDismiss: true});
		} else {
			this.props.addToast(resp.error, {appearance: 'error', autoDismiss: true});
		}
		this.setState({...this.state, loading: false});
	}

	render() {
		const {edit, user} = this.props;
		const {data, managerOptions, termOptions, contractExists, loaded, replaceBtn, attemptedSubmit, nameValidated, loading, addAmendmentBtn, amendments} = this.state;

		const required = !empty(data.NAME) && !empty(data.ABBREV) && !empty(data.MANAGER_ID);
		const validated = required && nameValidated;

		return (
			<Fragment>
				{loading && (<ModalLoading />)}
				{loaded && (<div className="form">
					<form autoComplete="off">
						<Grid container item xs={12}>
							<Grid container item direction="column" xs={12} sm={6} style={{padding: "0 1rem"}}>
								<TextField
									label="Legal Name"
									fullWidth
									required
									error={empty(data.NAME) && attemptedSubmit}
									helperText={(empty(data.NAME) && attemptedSubmit) ? "This field is required." : ""}
									margin="dense"
									autoComplete="false"
									type="text"
									value={data.NAME}
									name="NAME"
									onChange={this.handleChange}
								/>
								<TextField
									label="Abbreviation"
									fullWidth
									required
									error={!nameValidated || (empty(data.ABBREV) && attemptedSubmit)}
									helperText={nameValidated ? required ? "" : (empty(data.ABBREV) && attemptedSubmit) ? "This field is required." : "" : "This abbreviation is already taken in SpringAhead. Please choose a different abbreviation."}
									margin="dense"
									autoComplete="false"
									type="text"
									value={data.ABBREV}
									name="ABBREV"
									onChange={this.handleChange}
									onBlur={this.onAbbrevBlur}
								/>
								<TextField
				          select
				          required
				          error={empty(data.MANAGER_ID) && attemptedSubmit}
									helperText={(empty(data.MANAGER_ID) && attemptedSubmit) ? "This field is required." : ""}
				          label="Client Manager"
				          margin="dense"
				          value={data.MANAGER_ID}
				          onChange={this.handleChange}
				          name="MANAGER_ID"
				        >
				          {managerOptions.map((option) => (
				            <MenuItem key={option.value} value={option.value}>
				              {option.label}
				            </MenuItem>
				          ))}
				        </TextField>
								<TextField
									label="Entity Type"
									fullWidth
									type="text"
									margin="dense"
									value={data.ENTITY}
									name="ENTITY"
									onChange={this.handleChange}
								/>
                <TextField
				          select
				          required
				          error={empty(data.PO_REQUIRED) && attemptedSubmit}
									helperText={(empty(data.PO_REQUIRED) && attemptedSubmit) ? "This field is required." : ""}
				          label="PO Required"
				          margin="dense"
				          value={data.PO_REQUIRED}
				          onChange={this.handleChange}
				          name="PO_REQUIRED"
				        >
                  <MenuItem key={true} value={true}>
                    Yes
                  </MenuItem>
                  <MenuItem key={false} value={false}>
                    No
                  </MenuItem>
				        </TextField>
								<InputLabel style={{marginTop: "8px", marginBottom: "5px", fontSize: "0.95rem"}}>Image</InputLabel>
								<Button fullWidth style={{paddingLeft: "0"}}>
									<Grid container item justify="flex-start" alignItems="center">
										<div style={{height: "3rem", width: "3rem", marginRight: "1rem", display: "flex", justifyContent: "center", alignItems: "center"}}><img style={{width: "100%", objectFit: "cover"}} alt="" src={data.IMG}/></div>
										<input type="file" accept="image/*" name="IMAGE" onChange={this.onImageUpload}/>
									</Grid>
								</Button>
							</Grid>
							<Grid container item direction="column" xs={12} sm={6} style={{padding: "0 1rem"}}>
								<AddressBlock onChange={this.handleChange} data={data} />
								<TextField
									label="Deliverables Link"
									fullWidth
									type="text"
									margin="dense"
									value={data.DELIVERABLES_LINK}
									name="DELIVERABLES_LINK"
									onChange={this.handleChange}
								/>
								<TextField
									label="Terms Link"
									fullWidth
									type="text"
									margin="dense"
									value={data.TERMS_LINK}
									name="TERMS_LINK"
									onChange={this.handleChange}
								/>
                <TextField
				          select
				          required
				          error={empty(data.TERM_ID) && attemptedSubmit}
									helperText={(empty(data.TERM_ID) && attemptedSubmit) ? "This field is required." : ""}
				          label="Term"
				          margin="dense"
				          value={data.TERM_ID}
				          onChange={this.handleChange}
				          name="TERM_ID"
				        >
				          {termOptions.map(option => (
				            <MenuItem key={option.value} value={option.value}>
				              {option.label}
				            </MenuItem>
				          ))}
				        </TextField>
								<InputLabel style={{marginTop: "8px", marginBottom: "5px", fontSize: "0.95rem"}}>Governing Contract</InputLabel>
								<Button fullWidth style={{paddingLeft: "0"}}>
									<Grid container item justify="flex-start" alignItems="center">
										{contractExists && (<div style={{height: "3rem", width: "3rem", marginRight: "1rem", display: "flex", justifyContent: "center", alignItems: "center"}}><a href={`https://utilicastclientpictures.blob.core.windows.net/${data.ID}client/contract${user.sas}`} target="_none" style={{display: "flex", justifyContent: "center", alignItems: "center"}}><GetApp fontSize="large"/></a></div>)}
										{replaceBtn && (<Button size="small" variant="outlined" onClick={() => this.setState({...this.state, replaceBtn: false})}>REPLACE</Button>)}
										{!replaceBtn && (<input type="file" accept=".doc, .docx, .pdf" name="CONTRACT" onChange={this.onContractUpload}/>)}
									</Grid>
								</Button>
								<InputLabel style={{marginTop: "8px", marginBottom: "5px", fontSize: "0.95rem"}}>Governing Contract Amendments</InputLabel>
								<Button fullWidth style={{paddingLeft: "0"}}>
									<Grid container item justify="flex-start" alignItems="center">
										{amendments.map((amendment, i) => (
											<div style={{display: "flex", marginBottom: "0.2rem", marginRight: "0.5rem"}} key={i}>
												<Button onClick={() => this.confirmRemoveAmendment(amendment)} disableElevation variant="contained" size="small" style={{borderTopRightRadius: 0, borderBottomRightRadius: 0, color: "white", background: "red", minWidth: "1rem"}}><Delete fontSize="small" style={{color: "white"}}/></Button>
												<a href={`https://utilicastclientpictures.blob.core.windows.net/${data.ID}clientamendments/${amendment}${user.sas}`} target="_none" style={{flex: 1, height: "100%"}}><Button size="small" variant="contained" color="primary" disableElevation style={{color: "white", borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}>{stringifyNumber(i + 1)} Amd</Button></a>
											</div>
										))}
										{addAmendmentBtn && (<Button size="small" variant="outlined" onClick={() => this.setState({...this.state, addAmendmentBtn: false})}>ADD</Button>)}
										{!addAmendmentBtn && (<input type="file" accept=".doc, .docx, .pdf" name="CONTRACT" onChange={this.onAmendmentUpload}/>)}
									</Grid>
								</Button>
							</Grid>
							<div className="col-xs-12"></div>
							<div className="form-commands">
								<div className="btn-group data-form-commands">
									<SaveBtn onClick={!validated ? () => this.setState({...this.state, attemptedSubmit: true}) : edit ? this.handlePut : this.handleAdd} text="Save" disabled={!validated}/>
									<DeleteBtn onClick={edit ? this.confirmRemove : this.props.handleCancel} text={edit ? "Archive" : "Cancel"} delete={edit ? true : false} style={{marginLeft: "1.5rem"}}/>
								</div>
							</div>
						</Grid>
					</form>
				</div>)}
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		consultants: state.consultants,
		errors: state.errors,
    terms: state.terms,
		user: state.user
	};
}

export default connect(mapStateToProps, {
	db_update_client,
	db_add_client,
	db_archive_client,
	db_return_consultant
})(withToast(ClientDetail));
