import {apiCall} from '../../../services/api';
import empty from 'is-empty';

import {GET_CONSULTANTS} from '../../actionTypes';

import {DB_ERROR} from '../utils/errors';

export const state_get_consultants = consultants => ({
	type: GET_CONSULTANTS,
	consultants
});

export const db_get_consultants = () => dispatch => {
	return apiCall('get', '/api/consultants')
	.then(function(res) {
		dispatch(state_get_consultants(res));
	})
	.catch(function(err) {
		dispatch(DB_ERROR(err));
	});
}

export const db_return_consultant = id => (dispatch, getState) => {
	const consultants = getState().consultants;
	const blank = {
		time_tracker_id: "",
		first_name: "",
		last_name: ""
	}
	const con = consultants.find(con => {
		return con.time_tracker_id == id;
	});
	return empty(con) || empty(id) ? blank : con;
}

export const db_get_email_consultant = auth_user => {
	return apiCall('post', `/api/login`, auth_user)
	.then(function(res) {
		return res;
	})
	.catch(function(err) {
		console.log(err);
	});
}

// export const db_update_consultant = consultant => dispatch => {
// 	const id = consultant.ID;
// 	return apiCall('put', `/api/consultants/${id}`, consultant)
// 	.then(function(res) {
// 		if(dispatch(detectError(res))){
// 			dispatch(state_update_consultant(consultant));
// 			dispatch(execute_set_current_consultant({}));
// 			dispatch(removeError());
// 		}
// 	})
// 	.catch(function(err) {
// 		dispatch(addError(err.message));
// 	});
// }

// export const db_remove_consultant = consultant => dispatch => {
// 	const id = consultant.ID;
// 	return apiCall('delete', `/api/consultants/${id}`)
// 	.then(function(res) {
// 		if(dispatch(detectError(res))){
// 			dispatch(state_remove_consultant(consultant));
// 			dispatch(execute_set_current_consultant({}));
// 			dispatch(removeError());
// 		}
// 	})
// 	.catch(function(err) {
// 		dispatch(addError(err.message));
// 	})
// }

// export const db_add_consultant = consultant => dispatch => {
// 	return apiCall('post', `/api/consultants`, consultant)
// 	.then(function(res) {
// 		if(dispatch(detectError(res))){
// 			dispatch(db_get_consultants());
// 			dispatch(toggle());
// 			dispatch(removeError());
// 		}
// 	})
// 	.catch(function(err) {
// 		dispatch(addError(err.message));
// 	});
// }

