import {GET_PROJECTS, ADD_PROJECT, UPDATE_PROJECT, REMOVE_PROJECT} from '../../actionTypes';

const projects = (state = [], action) => {
	switch(action.type) {
		case GET_PROJECTS:
			return [...action.payload];
		case ADD_PROJECT:
			return [...state, action.payload];
		case UPDATE_PROJECT:
			return state.map(project => {
				if(action.payload.ID === project.ID) {
					return action.payload;
				} else {
					return project;
				}
			});
		case REMOVE_PROJECT:
			return state.filter(project => {
				return project.ID !== action.payload.ID;
			});
		default:
			return state;
	}
}

export default projects;