import moment from 'moment';
import empty from 'is-empty';

const lastSunday = date => {
  var lastDay = moment(date).endOf('month');
  let sub = 0;
  if (lastDay.day() === 7)
   sub = lastDay.day() - 7;
  else
   sub = lastDay.day();
  return lastDay.subtract(sub, 'days');
}

export const project_status = (start, end, candidate) => {
  const now = moment();
  if(candidate) {
    return "Candidate";
  } else if (now > start && empty(end)) {
    return "Ongoing";
  } else if (now > end && (now < lastSunday(end) || end > lastSunday(end))) {
    return "Reporting";
  } else if (now > start && now < end) {
    return "Active";
  } else if (now > end) {
    return "Closed";
  } else if (now < start) {
    return "Not Started";
  }
}

export const assignment_status = (start, end, sow) => {
  const now = moment();
  if (!sow) {
    return "Pending"
  } else if (now > start && empty(end)) {
    return "Ongoing";
  } else if (now > end && (now < lastSunday(end) || end > lastSunday(end))) {
    return "Reporting";
  } else if (now < start) {
    return "Not Started";
  } else if (now > start && now < end) {
    return "In-Progress";
  } else if (now > end) {
    return "Completed";
  } 
}