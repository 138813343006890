import {GET_CLIENT_OPTIONS} from '../../actionTypes';

const clientOptions = (state = [], action) => {
	switch(action.type) {
		case GET_CLIENT_OPTIONS:
			return [...action.payload];
		default:
			return state;
	}
}

export default clientOptions;