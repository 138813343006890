import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {confirmAlert} from 'react-confirm-alert';

import {removeError} from '../../store/actions/utils/errors';
import {db_get_sponsor_archives, db_archive_sponsor, db_delete_sponsor} from '../../store/actions/tables/sponsors';
import {set_db_data} from '../../store/actions/utils/db_data';
import {set_page} from '../../store/actions/utils/page';

import withToast from '../../utils/withToast';
import {sortList} from '../../utils/sortList';
import {searchList} from '../../utils/searchList';

import SponsorRow from '../../components/Rows/SponsorRow';
import Loading from '../../components/Graphics/Loading';
import ModalLoading from '../../components/Graphics/ModalLoading';

class SponsorArchives extends Component {
	constructor(props){
		super(props);
		this.state = {
			loaded: false,
			sort: 'LAST'
		}
	}

	async componentDidMount() {
		await this.props.set_page("sponsor-archives");
		await this.props.db_get_sponsor_archives();
		await this.props.set_db_data({...this.props.db_data, title: "Client Sponsors Archives", sort_btn: true, add_btn: false, search_btn: true, export_btn: true, filter_btn: false});
		this.setState({loaded: true});
	}

	handleSortChange = e => {
		this.setState({
			...this.state,
			'sort': e.target.id
		});
	}

	confirmDelete = sponsor => {
  	confirmAlert({
      title: 'Confirm Delete',
      message: 'Are you sure you want to permanently delete this sponsor?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.onDelete(sponsor)
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  }

	onRecover = async sponsor => {
		this.setState({...this.state, loading: true});
		const resp = await this.props.db_archive_sponsor({...sponsor, ARCHIVE: 0});
		if (resp.complete) {
			this.props.addToast("Successfully recovered.", {appearance: "success", autoDismiss: true});
		} else {
			this.props.addToast(resp.error, {appearance: "error", autoDismiss: true});
		}
		this.setState({...this.state, loading: false});
	}

	onDelete = async sponsor => {
		this.setState({...this.state, loading: true});
		const resp = await this.props.db_delete_sponsor(sponsor);
		if (resp.complete) {
			this.props.addToast("Successfully deleted.", {appearance: "success", autoDismiss: true});
		} else {
			this.props.addToast(resp.error, {appearance: "error", autoDismiss: true});
		}
		this.setState({...this.state, loading: false});
	}

	render() {
		const {sponsors, errors, currentSponsor, clients, db_data, user} = this.props;
		const {loaded, loading} = this.state;
		let sponsorList = sortList(this.state.sort, db_data.asc, searchList(db_data.search, sponsors)).map(sponsor => 
			<Fragment key={sponsor.ID}>
				<SponsorRow
					sponsor={sponsor}
					currentSponsor={currentSponsor}
					clients={clients}
					archive={true}
					onRecover={this.onRecover}
					onDelete={this.confirmDelete}
					user={user}
				/>
			</Fragment>
		);
		return (
			<Fragment>
				{!loaded && (<Loading />)}
				{loading && (<ModalLoading />)}
				{errors.message && (
					<div className="alert alert-danger">
						{errors.message}
					</div>
				)}
				{loaded && (
					<div className="container1">
						<div className="project-table">
							<table className="table">
								<thead>
									<tr>
										<th scope="col" className="table-title leftrow"><div onClick={this.handleSortChange} id="CLIENT_ID" className={this.state.sort === "CLIENT_ID" ? "sortSelect" : "sortTitle"}>Client</div></th>
										<th scope="col" className="table-title"><div onClick={this.handleSortChange} id="FIRST" className={this.state.sort === "FIRST" ? "sortSelect" : "sortTitle"}>First Name</div></th>
										<th scope="col" className="table-title"><div onClick={this.handleSortChange} id="LAST" className={this.state.sort === "LAST" ? "sortSelect" : "sortTitle"}>Last Name</div></th>
										<th scope="col" className="table-title">Title</th>
										<th scope="col" className="table-title"><div onClick={this.handleSortChange} id="EMAIL" className={this.state.sort === "EMAIL" ? "sortSelect" : "sortTitle"}>Email</div></th>
										<th scope="col" className="table-title">Phone</th>
										<th scope="col" className="table-title">Address</th>
										<th scope="col" className="table-title status"></th>
									</tr>
								</thead>
								<tbody className="table-body">
									{sponsorList}
								</tbody>
							</table>
						</div>
					</div>
				)}
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		clients: state.clients,
		sponsors: state.sponsors,
		currentSponsor: state.currentSponsor,
		errors: state.errors,
		db_data: state.db_data,
		user: state.user
	};
}

export default connect(mapStateToProps, {
	db_get_sponsor_archives,
	db_delete_sponsor,
	db_archive_sponsor,
	removeError,
	set_db_data,
	set_page
})(withToast(SponsorArchives));



