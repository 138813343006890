import {apiCall} from '../../../services/api';
import empty from 'is-empty';

import {DB_ERROR, addError} from '../utils/errors';

import {sa_amend_date} from '../../../utils/saSchema';

function downloadPDF(pdf, name) {
  const linkSource = `data:application/pdf;base64,${pdf}`;
  const downloadLink = document.createElement("a");

  downloadLink.href = linkSource;
  downloadLink.download = name + ".pdf";
  downloadLink.click();
}

function downloadZip(file, name) {
  const linkSource = file;
  const downloadLink = document.createElement("a");

  downloadLink.href = linkSource;
  downloadLink.download = name + ".zip";
  downloadLink.click();
}

export const draft_contract = (contractData, project, attachment, goBack) => dispatch => {
	return apiCall('post', `/api/documents/contract`, {project: project, contractData: contractData, attachment: attachment})
	.then(function(res) {
		const file_name = project.PROJECT.NAME + '_' + project.CONSULTANT.last_name;
		const file_string = res.file;
		downloadPDF(file_string, file_name);
		goBack();
	})
	.catch(function(err) {
		dispatch(addError(err.data.error));
	});
}

export const draft_amendment = (contractData, project, attachment, goBack) => dispatch => {
	return apiCall('post', `/api/documents/amendment`, {project: project, contractData: contractData, attachment: attachment})
	.then(function(res) {
		if(!empty(contractData.END_DATE)) {
			apiCall('post', `/api/springahead/projects/${project.PROJECT_ID}/rate/${project.SPRINGAHEAD_ID}`, sa_amend_date({END_DATE: contractData.END_DATE, CONSULTANT_ID: project.CONSULTANT_ID}))
			.then(function(res) {

			})
			.catch(function(err) {
				console.log(err)
			});
		}
		const file_name = "AMENDMENT_" + project.PROJECT.NAME + '_' + project.CONSULTANT.last_name;
		const file_string = res.file;
		downloadPDF(file_string, file_name);
		goBack();
	})
	.catch(function(err) {
		dispatch(addError(err.data.error));
	});
}

export const get_contract = (envelope_id) => dispatch => {
	return apiCall('post', `/api/documents/download`, {envelope_id: envelope_id})
	.then(function(res) {
		const file_name = `${envelope_id}.pdf`;
		downloadPDF(res.file, file_name);
	})
	.catch(function(err) {
		dispatch(addError(err.data.error));
	});
}
