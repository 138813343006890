import React, {Component} from 'react';
import empty from 'is-empty';

import {consultant_options} from '../../utils/options';
import {sortList} from '../../utils/sortList';
import {sa_add_project_rep, sa_delete_project_rep} from '../../store/actions/peripherals/springahead';

import RepRow from '../Rows/RepRow';

class RepsBlock extends Component {
	constructor(props) {
		super(props);
		this.state = {
			reps: []
		}
	}

	componentDidMount() {
		this.setState({...this.state, reps: this.props.reps});
	}

	addRow = e => {
		e.preventDefault();
		const {reps} = this.state;
		this.setState({...this.state, reps: [...reps, {Id: '', Name: '', Type: 'Other', UserId: '', Commission: '', ProjectId: reps[0].ProjectId}]});
	}

	removeRow = async index => {
		const {reps} = this.state;
		this.setState({...this.state, reps: reps.filter(function(rep, i) {return i !== index})});
	}

	updateRow = async (index, updated) => {
		const {reps} = this.state;
		if(empty(updated.UserId) || empty(updated.Commission)) {
			this.setState({...this.state, reps: reps.map((rep, i) => {if(i === index) return updated; else return rep; })});
		} else {
			let response = {}; 
			if(empty(updated.Id)) {
				response = await sa_add_project_rep(updated);
			} else {
				await sa_delete_project_rep(reps[index]); 
				response = await sa_add_project_rep(updated);
			}
			if(response.complete) updated = {...updated, Id: response.Id};
			if(response.complete) this.setState({...this.state, reps: reps.map((rep, i) => {if(i === index) return updated; else return rep; })});
		}
	}

	render() {
		const {consultants} = this.props;
		const {reps} = this.state;
		let sum = 0; 
		reps.forEach(function(rep) {sum += Number(rep.Commission)});

		let repList = sortList("Type", true, reps).map((rep,i) => 
			<RepRow 
				key={i}
				index={i}
				rep={rep}
				options={consultant_options(consultants)}
				removeRow={this.removeRow}
				updateRow={this.updateRow}
			/>
		);

		return (
			<div className="sales-rep-container">
				{repList}
				<div style={{display: "flex", width: "calc(4.5rem + 25%)", alignSelf: "flex-end", marginTop: "0.5rem", justifyContent: "flex-end"}}>
					{reps.length < 5 && (<button className="sales-rep-btn" onClick={this.addRow}>Add</button>)}
					<div className="sales-rep-title" style={{width: "calc(100% - 4rem)", textAlign: "right", display: "flex", justifyContent: "space-between", alignItems: "center", marginLeft: "0.5rem"}}><span style={{paddingLeft: "12px"}}>Total:</span><span>{sum.toFixed(1)}</span></div>
				</div>
			</div>
		);
	}
}

export default RepsBlock;




