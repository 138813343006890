import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {confirmAlert} from 'react-confirm-alert';
import withToast from '../../utils/withToast';

import {db_get_client_archives, db_archive_client, db_delete_client} from '../../store/actions/tables/clients';
import {set_db_data} from '../../store/actions/utils/db_data';
import {set_page} from '../../store/actions/utils/page';

import {sortList} from '../../utils/sortList';
import {searchList} from '../../utils/searchList';

import ClientRow from '../../components/Rows/ClientRow';
import Loading from '../../components/Graphics/Loading';
import ModalLoading from '../../components/Graphics/ModalLoading';

class ClientArchives extends Component {
	constructor(props){
		super(props);
		this.state = {
			loaded: false,
			loading: false,
			sort: 'NAME'
		}
	}

	async componentDidMount() {
		await this.props.set_page("client-archives");
		await this.props.db_get_client_archives();
		await this.props.set_db_data({...this.props.db_data, title: "Client Archives", sort_btn: true, add_btn: false, search_btn: true, export_btn: true, filter_btn: false});
		this.setState({loaded: true});
	}

	confirmDelete = (client) => {
  	confirmAlert({
      title: 'Confirm Delete',
      message: 'Are you sure you want to permanently delete this client?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.onDelete(client)
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  }

	onRecover = async client => {
		this.setState({...this.state, loading: true});
		const resp = await this.props.db_archive_client({...client, ARCHIVE: false});
		if (resp.complete) {
			this.props.addToast("Successfully recovered.", {appearance: "success", autoDismiss: true});
		} else {
			this.props.addToast(resp.error, {appearance: "error", autoDismiss: true});
		}
		this.setState({...this.state, loading: false});
	}

	onDelete = async client => {
		this.setState({...this.state, loading: true});
		const resp = await this.props.db_delete_client(client);
		if (resp.complete) {
			this.props.addToast("Successfully deleted.", {appearance: "success", autoDismiss: true});
		} else {
			this.props.addToast(resp.error, {appearance: "error", autoDismiss: true});
		}
		this.setState({...this.state, loading: false});
	}

	render() {
		const {clients, errors, db_data, user} = this.props;
		const {loaded, loading} = this.state;

		let clientList = sortList(this.state.sort, db_data.asc, searchList(db_data.search, clients)).map(client => 
			<Fragment key={client.ID}>
				<ClientRow
					client={client}
					archive={true}
					onRecover={this.onRecover}
					onDelete={this.confirmDelete}
					user={user}
				/>
			</Fragment>
		);
		return (
			<Fragment>
				{!loaded && (
					<Loading />
				)}
				{errors.message && (
					<div className="alert alert-danger">
						{errors.message}
					</div>
				)}
				{loading && (<ModalLoading />)}
				{loaded && (
					<div className="container1">
						<div className="project-table">
							<table className="table">
								<thead>
									<tr>
										<th scope="col" className="table-title leftrow"><div onClick={this.handleSortChange} id="NAME" className={this.state.sort === "NAME" ? "sortSelect" : "sortTitle"}>Legal Name</div></th>
										<th scope="col" className="table-title"><div onClick={this.handleSortChange} id="ABBREV" className={this.state.sort === "ABBREV" ? "sortSelect" : "sortTitle"}>Abbreviation</div></th>
										<th scope="col" className="table-title"><div onClick={this.handleSortChange} id="MANAGER_ID" className={this.state.sort === "MANAGER_ID" ? "sortSelect" : "sortTitle"}>Client Manager</div></th>
										<th scope="col" className="table-title">Address</th>
										<th scope="col" className="table-title">Client Teams Site</th>
										<th scope="col" className="table-title status"></th>
									</tr>
								</thead>
								<tbody className="table-body">
									{clientList}
								</tbody>
							</table>
						</div>
					</div>
				)}
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		clients: state.clients,
		consultants: state.consultants,
		errors: state.errors,
		db_data: state.db_data,
		user: state.user
	};
}

export default connect(mapStateToProps, {
	db_get_client_archives,
	db_delete_client,
	set_db_data,
	db_archive_client,
	set_page
})(withToast(ClientArchives));



