import React, {Component, Fragment} from 'react';
import empty from 'is-empty';

import {TextField, MenuItem} from "@material-ui/core";

import SaveBtn from '../Buttons/SaveBtn';
import DeleteBtn from '../Buttons/DeleteBtn';

class DownloadAmendmentPopup extends Component {

	constructor(props) {
		super(props);
		this.state = {
			id: ''
		}
	}

	handleChange = e => {
		this.setState({
			...this.state,
			id: e.target.value
		});
	}

	handleDownload = async () => {
		this.props.setState({loading: true});
		await this.props.onDownload(this.state.id);
		this.props.setState({loading: false});
		this.props.onHide();
	}

  render() {
  	const {id} = this.state;
  	const {options} = this.props;
  	return(
  		<Fragment>
  			<div className={this.props.sidebar ? "loading-div-container closed" : "loading-div-container"} style={{padding: "2rem"}}>
  				<h5 className="loading-div-text" style={{paddingBottom: "2rem", fontSize: "1.5rem"}}>{this.props.text}</h5>
	  			<div style={{width: "85%", fontSize: "1.2rem"}}>
						<TextField
		          select
		          fullWidth
		          label="Choose Amendment"
		          margin="dense" 
		          value={id}
		          onChange={this.handleChange}
		          name="id"
		        >
		          {options.map((option) => (
		            <MenuItem key={option.value} value={option.value}>
		              {option.label}
		            </MenuItem>
		          ))}
		        </TextField>
	  			</div>
					<div className="btn-group" style={{padding: "2rem 0"}}>
						<SaveBtn onClick={empty(id) ? () => {} : this.handleDownload} text="Download" disabled={empty(id)}/>
						<DeleteBtn onClick={this.props.onHide} text={"Cancel"} delete={false} style={{marginLeft: "1.5rem"}}/>
					</div>
				</div>
	  		<div className="backdrop"></div>
  		</Fragment>
  	);
  }
}
 
export default DownloadAmendmentPopup;

