import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';

import {Grid} from '@material-ui/core';
import {ExpandMore, GetApp, ChevronRight, PieChart, Work, Archive, VpnKey, MonetizationOn, Help, SupervisorAccount, Business, CheckCircle} from '@material-ui/icons';

class Sidebar extends Component {

	render() {
		const {user, page, dropdown, projects, springahead, archives} = this.props;

		return (
			<Grid container item md={2} style={{height: "100%", zIndex: "15", overflow: "scroll", boxShadow: "0 0 10px #8C8C8C", background: "white", color: "#8C8C8C"}}>
				<Grid container item direction="column" style={{width: "calc(100% + 1rem)", overflow: "scroll", zIndex: "15", padding: "2rem"}}>
					{user.role === "Admin" && (<Fragment><h5 className="db-dashboard-title">Administrator</h5>
					<ul className="db-dashboard-list">
						<div className="db-admin-data-dropdown" onClick={this.props.setDropdown}>
							<li>
								<PieChart style={{width: "1rem", height: "auto", fontSize: "inherit", marginBottom: "-0.15rem"}}/>
								<span className="db-admin-data title">Master Data</span>
							</li>
							{dropdown && (<ExpandMore />)}
							{!dropdown && (<ChevronRight />)}
						</div>
						{dropdown && (<div className="db-admin-container">
							<div className="db-vertical-divider"></div>
							<div className="db-admin-data-container">
								<Link className={page === "clients" ? "db-admin-data db-page-selected" : "db-admin-data"} id="clients" to="/dashboard/admin/clients">Clients</Link>
								<Link className={page === "consultants" ? "db-admin-data db-page-selected" : "db-admin-data"} id="consultants" to="/dashboard/admin/consultants">Consultants</Link>
								<Link className={page === "sponsors" ? "db-admin-data db-page-selected" : "db-admin-data"} id="sponsors" to="/dashboard/admin/sponsors">Client Sponsors</Link>
								<Link className={page === "professional" ? "db-admin-data db-page-selected" : "db-admin-data"} id="professional" to="/dashboard/admin/payschedule">Pay Schedule</Link>
							</div>
						</div>)}
						<div className="db-admin-data-dropdown" onClick={this.props.setProject}>
							<li>
								<Work style={{width: "1rem", height: "auto", fontSize: "inherit", marginBottom: "-0.15rem"}} />
								<span className="db-admin-data title">Project Data</span>
							</li>
							{projects && (<ExpandMore />)}
							{!projects && (<ChevronRight />)}
						</div>
						{projects && (<div className="db-admin-container">
							<div className="db-vertical-divider-projects"></div>
							<div className="db-admin-data-container">
								<Link className={page === "candidate-projects" ? "db-admin-data db-page-selected" : "db-admin-data"} id="candidate-projects" to="/dashboard/admin/projects/candidate">Candidate Projects</Link>
								<Link className={page === "admin-projects" ? "db-admin-data db-page-selected" : "db-admin-data"} id="admin-projects" to="/dashboard/admin/projects/active">Active Projects</Link>
								<Link className={page === "admin-assignments" ? "db-admin-data db-page-selected" : "db-admin-data"} id="admin-assignments" to="/dashboard/admin/assignments">Assignments</Link>
								<Link className={page === "utilicast-assignments" ? "db-admin-data db-page-selected" : "db-admin-data"} id="utilicast-assignments" to="/dashboard/admin/internalassignments">Utilicast Assignments</Link>
							</div>
						</div>)}
						{
							// <li> 
							// 	<Description style={{width: "1rem", height: "auto", fontSize: "inherit", marginBottom: "-0.15rem"}} />
							// 	<Link className={page === "invoices" ? "db-admin-data title db-page-selected" : "db-admin-data title"} id="invoices" to="/dashboard/admin/invoices">Invoice Tool</Link> 
							// </li>
						}
						<li> 
							<VpnKey style={{width: "1rem", height: "auto", fontSize: "inherit", marginBottom: "-0.15rem"}}/>
							<Link className={page === "keys" ? "db-admin-data title db-page-selected" : "db-admin-data title"} id="keys" to="/dashboard/admin/keys">API Keys</Link> 
						</li>
						<div className="db-admin-data-dropdown" onClick={this.props.setArchive}>
							<li>
								<Archive style={{width: "1rem", height: "auto", fontSize: "inherit", marginBottom: "-0.15rem"}} />
								<span className="db-admin-data title">Archives</span>
							</li>
							{archives && (<ExpandMore />)}
							{!archives && (<ChevronRight />)}
						</div>
						{archives && (<div className="db-admin-container">
							<div className="db-vertical-divider-archives"></div>
							<div className="db-admin-data-container">
								<Link className={page === "client-archives" ? "db-admin-data db-page-selected" : "db-admin-data"} id="client-archives" to="/dashboard/admin/archives/clients">Clients</Link>
								<Link className={page === "sponsor-archives" ? "db-admin-data db-page-selected" : "db-admin-data"} id="sponsor-archives" to="/dashboard/admin/archives/sponsors">Sponsors</Link>
								<Link className={page === "project-archives" ? "db-admin-data db-page-selected" : "db-admin-data"} id="project-archives" to="/dashboard/admin/archives/projects">Projects</Link>
								<Link className={page === "candidate-project-archives" ? "db-admin-data db-page-selected" : "db-admin-data"} id="candidate-project-archives" to="/dashboard/admin/archives/projects/candidate">Candidate Projects</Link>
								<Link className={page === "assignment-archives" ? "db-admin-data db-page-selected" : "db-admin-data"} id="assignment-archives" to="/dashboard/admin/archives/assignments">Assignments</Link>
							</div>
						</div>)}
						<div className="db-admin-data-dropdown" onClick={this.props.setSpringahead}>
							<li>
								<GetApp style={{width: "1rem", height: "auto", fontSize: "inherit", marginBottom: "-0.15rem"}} />
								<span className="db-admin-data title">Other Exports</span>
							</li>
							{springahead && (<ExpandMore />)}
							{!springahead && (<ChevronRight />)}
						</div>
						{springahead && (<div className="db-admin-container">
							<div className="db-vertical-divider-springahead" style={{height: "5.2rem"}}></div>
							<div className="db-admin-data-container">
								<li className="db-admin-data" onClick={this.props.user_sa_export}>SA Users</li>
								<li className="db-admin-data" onClick={this.props.project_sa_export}>SA Projects</li>
								<li className="db-admin-data" onClick={this.props.company_sa_export}>SA Companies</li>
								<li className="db-admin-data" onClick={this.props.documents_export}>All Documents</li>
							</div>
						</div>)}
					</ul></Fragment>)}
					{(user.role === "Client Manager" || user.role === "Admin") && (<Fragment><h5 className="db-dashboard-title">My Clients</h5>
					<ul className="db-dashboard-list">
						<li>
							<MonetizationOn style={{width: "1rem", height: "auto", fontSize: "inherit", marginBottom: "-0.15rem"}}/>
							<Link className={page === "am-sponsors" ? "db-admin-data db-page-selected" : "db-admin-data"} id="am-sponsors" to="/dashboard/am/sponsors">Client Sponsors</Link>
						</li>
						<li> 
							<Help style={{width: "1rem", height: "auto", fontSize: "inherit", marginBottom: "-0.15rem"}}/>
							<Link className={page === "am-candidate-projects" ? "db-admin-data db-page-selected" : "db-admin-data"} id="am-candidate-projects" to="/dashboard/am/projects/candidate">Candidate Projects</Link>
						</li>
						<li>
							<Work style={{width: "1rem", height: "auto", fontSize: "inherit", marginBottom: "-0.15rem"}} />
							<Link className={page === "am-projects" ? "db-admin-data db-page-selected" : "db-admin-data"} id="am-projects" to="/dashboard/am/projects/active">Active Projects</Link>
						</li>
						<li>
							<SupervisorAccount style={{width: "1rem", height: "auto", fontSize: "inherit", marginBottom: "-0.15rem"}} />
							<Link className={page === "am-assignments" ? "db-admin-data db-page-selected" : "db-admin-data"} id="am-assignments" to="/dashboard/am/assignments">Assignments</Link>
						</li>
					</ul></Fragment>)}
					<h5 className="db-dashboard-title">My Projects</h5>
					<ul className="db-dashboard-list">
						<li>
							<SupervisorAccount style={{width: "1rem", height: "auto", fontSize: "inherit", marginBottom: "-0.15rem"}} />
							<Link className={page === "consultant-assignments" ? "db-admin-data db-page-selected" : "db-admin-data"} id="consultant-assignments" to="/dashboard/">My Assignments</Link>
						</li>
						<li> 
							<Help style={{width: "1rem", height: "auto", fontSize: "inherit", marginBottom: "-0.15rem"}}/>
							<Link className={page === "consultant-leads" ? "db-admin-data db-page-selected" : "db-admin-data"} id="consultant-leads" to="/dashboard/projects/leads">My Leads</Link>
						</li>
						<li> 
							<CheckCircle style={{width: "1rem", height: "auto", fontSize: "inherit", marginBottom: "-0.15rem"}}/>
							<Link className={page === "consultant-submitted-leads" ? "db-admin-data db-page-selected" : "db-admin-data"} id="consultant-submitted-leads" to="/dashboard/projects/leads/submitted">Submitted Leads</Link>
						</li>
					</ul>
					<h5 className="db-dashboard-title">Reports</h5>
					<ul className="db-dashboard-list">
						<li> 
							<Help style={{width: "1rem", height: "auto", fontSize: "inherit", marginBottom: "-0.15rem"}}/>
							<Link className={page === "consultant-candidate-projects" ? "db-admin-data db-page-selected" : "db-admin-data"} id="consultant-candidate-projects" to="/dashboard/projects/candidate">Candidate Projects</Link>
						</li>
						<li> 
							<Work style={{width: "1rem", height: "auto", fontSize: "inherit", marginBottom: "-0.15rem"}} />
							<Link className={page === "consultant-projects" ? "db-admin-data db-page-selected" : "db-admin-data"} id="consultant-projects" to="/dashboard/projects/active">Active Projects</Link>
						</li>
						<li> 
							<SupervisorAccount style={{width: "1rem", height: "auto", fontSize: "inherit", marginBottom: "-0.15rem"}}/>
							<Link className={page === "company-assignments" ? "db-admin-data db-page-selected" : "db-admin-data"} id="company-assignments" to="/dashboard/assignments">Company Assignments</Link>
						</li>
						<li> 
							<Business style={{width: "1rem", height: "auto", fontSize: "inherit", marginBottom: "-0.15rem"}} />
							<Link className={page === "consultant-clients" ? "db-admin-data db-page-selected" : "db-admin-data"} id="consultant-clients" to="/dashboard/clients">Client Managers</Link>
						</li>
						<li> 
							<MonetizationOn style={{width: "1rem", height: "auto", fontSize: "inherit", marginBottom: "-0.15rem"}} />
							<Link className={page === "consultant-sponsors" ? "db-admin-data db-page-selected" : "db-admin-data"} id="consultant-sponsors" to="/dashboard/sponsors">Client Sponsors</Link>
						</li>
						<li> 
							<PieChart style={{width: "1rem", height: "auto", fontSize: "inherit", marginBottom: "-0.15rem"}} />
							<Link className={page === "consultant-charts" ? "db-admin-data db-page-selected" : "db-admin-data"} id="consultant-charts" to="/dashboard/charts">Charts</Link>
						</li>
					</ul>
				</Grid>
			</Grid>
		)
	}
}

export default Sidebar;





