import empty from 'is-empty';

function alpha(list) {
	return list.sort(function (a, b) {
    if(a.label < b.label) { return -1; }
		if(a.label > b.label) { return 1; }
		return 0;
	});
}

export const consultant_options = consultants => {
	let consultantOptions = [];
	consultants.forEach(consultant => {
		if(['active', 'affiliate'].includes(consultant.consultant_status.toLowerCase()) && !empty(consultant.time_tracker_id)) consultantOptions.push(Object.assign({}, {value: consultant.time_tracker_id}, {label: `${consultant.first_name} ${consultant.last_name}`}));
	});
	return alpha(consultantOptions);
}

export const term_options = terms => alpha(terms.map(term => Object.assign({}, {value: term.Id, label: term.Name})));

export const area_options = [
	{value: "Centralized Markets", label: "Centralized Markets"},
	{value: "Customer", label: "Customer"},
	{value: "Distribution", label: "Distribution"},
	{value: "Energy Services", label: "Energy Services"},
	{value: "Enterprise", label: "Enterprise"},
	{value: "Generation", label: "Generation"},
	{value: "Regional Reliability", label: "Regional Reliability"},
	{value: "Transmission", label: "Transmission"}
];

export const likelihood_options = [
	{value: 0.25, label: "25% - Speculative"},
	{value: 0.5, label: "50% - Likely"},
	{value: 0.75, label: "75% - Certain"}
];

export const archive_reason_options = [
  {value: 'lost', label: 'Lost'},
  {value: 'cancelled', label: 'Cancelled'},
  {value: 'merged', label: 'Merged'},
  {value: 'other', label: 'Other'},
];

export const years = [
	{value: "2019", label: "2019"},
	{value: "2020", label: "2020"},
	{value: "2021", label: "2021"},
	{value: "2022", label: "2022"},
	{value: "2023", label: "2023"},
	{value: "2024", label: "2024"},
	{value: "2025", label: "2025"},
	{value: "2026", label: "2026"},
	{value: "2027", label: "2027"},
	{value: "2028", label: "2028"},
	{value: "2029", label: "2029"},
	{value: "2030", label: "2030"},
	{value: "2031", label: "2031"},
	{value: "2032", label: "2032"},
	{value: "2033", label: "2033"},
	{value: "2034", label: "2034"},
	{value: "2035", label: "2035"},
	{value: "2036", label: "2036"},
	{value: "2037", label: "2037"},
	{value: "2038", label: "2038"},
	{value: "2039", label: "2039"},
	{value: "2040", label: "2040"}
];
