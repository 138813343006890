import {toggle} from './panel';
import {removeError} from './errors';

export const addBtn = () => dispatch => {
	dispatch(toggle());
}

export const addCancel = () => dispatch => {
	dispatch(removeError());
	dispatch(toggle());
}