import React, {Component} from 'react';

import HorizontalSelectOption from './HorizontalSelectOption';

class ExpensesType extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selected: ''
		}
	}

	componentDidMount() {
		this.setState({
			...this.state,
			selected: this.props.chosen
		})
	}

	onSelect = e => {
		e.preventDefault();
		const val = this.state.selected === e.target.id ? "" : e.target.id;
		this.setState({
			...this.state,
			selected: val
		});
		this.props.onSelect(val);
	}

	render() {
		const {selected} = this.state;
		return (
			<div className="expenses-type-container">
				<HorizontalSelectOption value="At cost" selected={selected} onSelect={this.onSelect} style={{width: "28%", borderTopLeftRadius: "3px", borderBottomLeftRadius: "3px"}} />
				<HorizontalSelectOption value="Fixed cost" selected={selected} onSelect={this.onSelect} style={{width: "36%"}} />
				<HorizontalSelectOption value="No expenses" selected={selected} onSelect={this.onSelect} style={{width: "36%", borderTopRightRadius: "3px", borderBottomRightRadius: "3px"}} />
			</div>
		);
	}
}

export default ExpensesType;