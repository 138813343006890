import React, {Component} from 'react';

import HorizontalSelectOption from './HorizontalSelectOption';

class AtCostOptions extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selected: ""
		}
	}

	onSelect = e => {
		e.preventDefault();
		const val = this.state.selected === e.target.id ? "" : e.target.id;
		this.setState({
			...this.state,
			selected: val
		});
		this.props.onSelect(val);
	}

	render() {
		const {selected} = this.state;
		return (
			<div className="expenses-type-container">
				<HorizontalSelectOption value="Per day" selected={selected} onSelect={this.onSelect} style={{width: "25%", borderTopLeftRadius: "3px", borderBottomLeftRadius: "3px"}} />
				<HorizontalSelectOption value="Per week" selected={selected} onSelect={this.onSelect} style={{width: "25%"}} />
				<HorizontalSelectOption value="Per month" selected={selected} onSelect={this.onSelect} style={{width: "25%"}} />
				<HorizontalSelectOption value="One time" selected={selected} onSelect={this.onSelect} style={{width: "25%", borderTopRightRadius: "3px", borderBottomRightRadius: "3px"}} />
			</div>
		);
	}
}

export default AtCostOptions;