import React, {Component} from 'react';

import safariGif from '../../images/safariTutorial.gif';

class SafariError extends Component {

	render() {
		return (
			<div style={{padding: "1.5rem", height: "100vh", maxWidth: "70rem", display: "flex", flexDirection: "column", margin: "auto", backgroundColor: "#ededed"}}>
				<h3>It looks like you are using Safari.</h3>
				<h5>Safari is not the optimal browser to use with the Utilicast Tool. If you have Chrome, please switch to Chrome. Otherwise, follow the tutorial video below to enable Safari.</h5>
				<img src={safariGif} alt="" style={{width: "100%"}}/>
			</div>
		);
	}
}

export default SafariError;