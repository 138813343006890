import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import moment from 'moment';

import {TextField, MenuItem} from '@material-ui/core';

import {removeError} from '../../store/actions/utils/errors';
import {toggle, hide_panel} from '../../store/actions/utils/panel';
import {db_get_yearly_professionals} from '../../store/actions/tables/professionals';
import {set_db_data} from '../../store/actions/utils/db_data';
import {addCancel} from '../../store/actions/utils/addbtn';
import {set_page} from '../../store/actions/utils/page';

import {sortList} from '../../utils/sortList';
import {searchList} from '../../utils/searchList';
import {years} from '../../utils/options';

import PayScheduleRow from '../../components/Rows/PayScheduleRow';
import PayScheduleDetail from './PayScheduleDetail';
import Loading from '../../components/Graphics/Loading';

class ProfessionalList extends Component {
	constructor(props){
		super(props);
		this.state = {
			loaded: false,
			sort: 'NAME',
			year: moment().format('YYYY'),
			id: null
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if(this.state.year !== prevState.year) {
			this.props.db_get_yearly_professionals(this.state.year);
		}
	}

	async componentDidMount() {
		await this.props.set_page("professional");
		await this.props.hide_panel();
		await this.props.db_get_yearly_professionals(moment().format('YYYY'));
		await this.props.set_db_data({...this.props.db_data, title: "Pay Schedule", sort_btn: true, add_btn: true, search_btn: true, export_btn: true, filter_btn: false});
		this.setState({loaded: true});
	}

	async handleRowClicked(data) {
		await this.setState({
			...this.state,
			id: data.ID === this.state.id ? null : data.ID
		});
		
		this.props.hide_panel();
	}

	handleChange = e => {
		this.setState({
			[e.target.name]: e.target.value
		});
	}

	handleSortChange = e => {
		this.setState({
			...this.state,
			'sort': e.target.id
		});
	}

	render() {
		const {professionals, errors, db_data} = this.props;
		const {id, year} = this.state;
		let data = professionals;
		let professionalList = sortList(this.state.sort, db_data.asc, searchList(db_data.search, data)).map(professional => 
			<Fragment key={professional.ID}>
				<PayScheduleRow
					professional={professional}
					handleRowClicked={this.handleRowClicked.bind(this, professional)}
					id={id}
				/>
				{id === professional.ID && (
					<tr>
						<td colSpan="5" className="editForm">
							<PayScheduleDetail year={year} onHide={() => this.setState({...this.state, id: null})} currentProfessional={professional} edit/>
						</td>
					</tr>
				)}
			</Fragment>
		);
		return (
			<Fragment>
				{!this.state.loaded && (
					<Loading />
				)}
				{errors.message && (
					<div className="alert alert-danger">
						{errors.message}
					</div>
				)}
				{this.state.loaded && (
					<div className="container1">
						{this.props.panel && (
							<div className="container-add">
								<PayScheduleDetail year={year} handleCancel={this.props.addCancel}/>
							</div>
						)}
						<div className="project-table">
							<div style={{width: "30rem", background: "white", padding: "0.75rem 1.25rem", borderRadius: "6px", marginBottom: "1rem"}}>
								<TextField
				          select
				          fullWidth
				          label="Year"
				          margin="dense" 
				          value={year}
				          onChange={this.handleChange}
				          name="year"
				        >
				          {years.map((option) => (
				            <MenuItem key={option.value} value={option.value}>
				              {option.label}
				            </MenuItem>
				          ))}
				        </TextField>
							</div>
							<table className="table">
								<thead>
									<tr>
										<th scope="col" className="table-title leftrow"><div onClick={this.handleSortChange} id="NAME" className={this.state.sort === "NAME" ? "sortSelect" : "sortTitle"}>Position Name</div></th>
										<th scope="col" className="table-title"><div onClick={this.handleSortChange} id="BILL" className={this.state.sort === "BILL" ? "sortSelect" : "sortTitle"}>Bill Rate</div></th>
										<th scope="col" className="table-title">90%</th>
										<th scope="col" className="table-title">85%</th>
										<th scope="col" className="table-title status">80%</th>
									</tr>
								</thead>
								<tbody className="table-body">
									{professionalList}
								</tbody>
							</table>
						</div>
					</div>
				)}
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		currentProfessional: state.currentProfessional,
		professionals: state.professionals,
		errors: state.errors,
		panel: state.panel,
		db_data: state.db_data
	};
}

export default connect(mapStateToProps, {set_page, db_get_yearly_professionals, toggle, removeError, hide_panel, set_db_data, addCancel})(ProfessionalList);


