import empty from 'is-empty';
import moment from 'moment';

function sanitizeXML(unsafe) {
    return String(unsafe).replace(/[<>&'"]/g, function (c) {
        switch (c) {
            case '<': return '&lt;';
            case '>': return '&gt;';
            case '&': return '&amp;';
            case '\'': return '&apos;';
            case '"': return '&quot;';
            default: return c;
        }
    });
}

export const sa_user_export = data => {
	return {
		"SPRINGAHEAD ID": empty(data.Id) ? '' : data.Id._text,
		"ACTIVE": empty(data.Active) ? '' : data.Active._text,
		"FIRST NAME": empty(data.FirstName) ? '' : data.FirstName._text,
		"LAST NAME": empty(data.LastName) ? '' : data.LastName._text,
		"EMPLOYEE TYPE": empty(data.EmployeeType) ? '' : data.EmployeeType._text
	}
}

export const sa_project_export = data => {
	return {
		"SPRINGAHEAD ID": empty(data.Id) ? '' : data.Id._text,
		"ACTIVE": empty(data.Active) ? '' : data.Active._text,
		"CLIENT SPRINGAHEAD ID": empty(data.Company) ? '' : empty(data.Company.Id) ? '' : data.Company.Id._text,
		"CLIENT NAME": empty(data.Company) ? '' : empty(data.Company.Name) ? '' : data.Company.Name._text,
		"PROJECT NAME": empty(data.Name) ? '' : data.Name._text,
		"START DATE": empty(data.DateStart) ? '' : data.DateStart._text,
		"END DATE": empty(data.DateStop) ? '' : data.DateStop._text,
		"MANAGER SPRINGAHEAD ID": empty(data.Manager) ? '' : empty(data.Manager.Id) ? '' : data.Manager.Id._text,
		"MANAGER FIRST NAME": empty(data.Manager) ? '' : empty(data.Manager.FirstName) ? '' : data.Manager.FirstName._text,
		"MANAGER LAST NAME": empty(data.Manager) ? '' : empty(data.Manager.LastName) ? '' : data.Manager.LastName._text
	}
}

export const sa_company_export = data => {
	return {
		"SPRINGAHEAD ID": empty(data.Id) ? '' : data.Id._text,
		"ACTIVE": empty(data.Active) ? '' : data.Active._text,
		"NAME": empty(data.Name) ? '' : data.Name._text,
		"BILL ADDRESS LINE 1": empty(data.BillAddress) ? '' : empty(data.BillAddress.AddressLine1) ? '' : data.BillAddress.AddressLine1._text,
		"BILL ADDRESS LINE 2": empty(data.BillAddress) ? '' : empty(data.BillAddress.AddressLine2) ? '' : data.BillAddress.AddressLine2._text,
		"BILL ADDRESS LINE 3": empty(data.BillAddress) ? '' : empty(data.BillAddress.AddressLine3) ? '' : data.BillAddress.AddressLine3._text,
		"BILL ADDRESS LINE 4": empty(data.BillAddress) ? '' : empty(data.BillAddress.AddressLine4) ? '' : data.BillAddress.AddressLine4._text
	}
}

export const sa_add_rep = data => {
	return {
		"ProjectRep": {
			"User": {
				"Id": {
					"_text": String(data.UserId)
				}
			},
			"Type": {
				"_text": data.Type
			},
			"Commission": {
				"_text": String(data.Commission)
			}
		}
	}
}

export const sa_add_task = data => {
	return {
		"Task": {
			"Name": {
				"_text": data.Name
			},
			"BillRate": {
				"_attributes": {
					"xsi:nil": empty(data.BillRate) ? "true" : undefined
				},
				"_text": empty(data.BillRate) ? undefined : sanitizeXML(data.BillRate)
			}
		}
	}
}

export const sa_amend_date = data => {
	return {
		"ProjectRate": {
			"Active": {
				"_text": 'true'
			},
			"DateStop": {
				"_text":  empty(data.END_DATE) ? undefined : sanitizeXML(moment.utc(data.END_DATE).format())
			},
			"User": {
				"Id": {
					"_text": String(data.CONSULTANT_ID)
				}
			}
		}
	}
}
