import React, {Component, Fragment} from 'react';

import '../../css/error.css';

class NoAccess extends Component {
	constructor(props) {
		super(props);
		this.onReturn = this.onReturn.bind(this);
	}

	onReturn = e => {
		e.preventDefault();
		this.props.onBack();
	}

	render() {
		return (
			<Fragment>
				<img className="background-list" style={{"WebkitUserSelect": "none"}} src="https://www.utilicast.com/recruitment/images/misc/background-grid-lines.png" alt="NO ACCESS"/>
				<div className="project-header">
					<div className="project-return1">
					</div>
				</div>
				<div className="error-body">
					<div className="error">You do not have access to this page.</div>
				</div>
			</Fragment>
		);
	}
}

export default NoAccess;