import {GET_KEYS, REMOVE_KEY} from '../../actionTypes';

const keys = (state = [], action) => {
	switch(action.type) {
		case GET_KEYS:
			return [...action.payload];
		case REMOVE_KEY:
			return state.filter(key => {
				return key.id !== action.payload.id;
			});
		default:
			return state;
	}
}

export default keys;