import React, {Component} from 'react';
import empty from 'is-empty';

import {TextField, InputAdornment} from '@material-ui/core';

class PercentageInput extends Component {
	constructor(props) {
		super(props);
		this.state = {
			focus: false
		}
	}
	render() {
		const {value, label, name, disabled} = this.props;
		const {focus} = this.state;
		return (
			<TextField 
				label={label}
				fullWidth
				margin="dense" 
				type="number" 
				value={value} 
				name={name}
				disabled={disabled}
				onChange={this.props.onChange}
				onFocus={() => this.setState({focus: true})}
				onBlur={(e) => {this.setState({focus: false}); this.props.onBlur(e);}}
				InputProps={{endAdornment: (focus || !empty(value)) ? (<InputAdornment position="end" style={{paddingBottom: "3px"}}>%</InputAdornment>) : null}}
			/>
		)
	}
}

export default PercentageInput;