import React, {Component} from 'react';
import {Button, Grid} from '@material-ui/core';
import {CheckCircle} from '@material-ui/icons';

class SaveBtn extends Component {
	
	render() {
		return (
			<Button variant="contained" onClick={this.props.onClick} style={{...this.props.style, height: "3rem", background: this.props.disabled ? "" : "#5cb85c", color: "white", fontSize: "1rem", lineHeight: "0"}}>
				<Grid container item justify="space-between" alignItems="center">
					<CheckCircle/>
					<span style={{margin: "0 0.5rem"}}>{this.props.text}</span>
				</Grid>
			</Button>
		);
	}
}

export default SaveBtn;