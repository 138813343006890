import React, {Component} from 'react';
import empty from 'is-empty';

import ArchiveBtns from '../Buttons/ArchiveBtns';

import defaultPic from '../../images/defaultClient.png';

class ClientRow extends Component {
	constructor(props){
    super(props);
    this.state = {
    	clicked: false
    };
  }

  onRecover = e => {
  	e.preventDefault();
  	this.props.onRecover(this.props.client);
  }
  onDelete = e => {
  	e.preventDefault();
  	this.props.onDelete(this.props.client);
  }

	render() {
		const {client, handleRowClicked, id, role} = this.props;
		const clicked = id === client.ID ? "current-overhead-row" : "";
		const rowClasses = `row table-row ${clicked} ${role === "Consultant" ? "read" : ""}`;
		return (
			<tr className={rowClasses} onClick={handleRowClicked} id="client-row">
				<td data-container="body" className="table-data"><div className="img-row"><div className="row-img"><img className="row-img-src" src={empty(client.IMG) ? defaultPic : client.IMG} alt="CLIENT IMG"/></div>{client.NAME}</div></td>
				<td data-container="body" className="table-data">{client.ABBREV}</td>
				<td data-container="body" className="table-data">{client.MANAGER.first_name} {client.MANAGER.last_name}</td>
				<td data-container="body" className="table-data">{`${client.ADDRESS}, ${client.CITY}, ${client.STATE} ${client.ZIP}, ${client.COUNTRY}`}</td>
				<td data-container="body" className="table-data">{!empty(client.DELIVERABLES_LINK) && (<a href={client.DELIVERABLES_LINK} target="_none" style={{display: "flex", alignItems: "center", width: "100%"}}><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 8 8" style={{marginRight: "0.5rem", marginBottom: "0.1rem"}}><path d="M4 0c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm2 1.78l.72.72-3.22 3.22-1.72-1.72.72-.72 1 1 2.5-2.5z" /></svg> Site</a>)}</td>
				{this.props.archive && (<ArchiveBtns onRecover={this.onRecover} onDelete={this.onDelete}/>)}
			</tr>
		);
	}
}

export default ClientRow;