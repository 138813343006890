import React, {Component, Fragment} from 'react';
import { ToastProvider } from '../utils/react-toast-notifications';
import IdleTimer from 'react-idle-timer';
import {Provider} from 'react-redux';
import {BrowserRouter as Router} from 'react-router-dom';

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core';

import {configureStore} from '../store';

import Toast from '../components/Graphics/Toast';
import Home from '../containers/Home';

import '../css/header.css';
import '../css/dashboard.css';
import '../css/forms.css';
import '../css/lists.css';
import '../css/overall.css';
import '../css/react-confirm-alert.css';

const store = configureStore();

const theme = createMuiTheme({
  typography: {
    fontFamily: '"Helvetica", "Arial", "sans-serif"'
  },
  palette: {
    primary: {
      main: "#01579B"
    },
    secondary: {
      main: "#F6B924",
      contrastText: "#fff"
    }
  }
});

class App extends Component {
	constructor(props) {
    super(props)
    this.idleTimer = null;
    this.onIdle = this._onIdle.bind(this);
  }
 
  _onIdle(e) {
    if(!alert('You have been inactive for 30 minutes. Hit OK to get the latest data.')){window.location.reload();}
  }

  render() {
    return (
    	<Fragment>
	    	<IdleTimer
	        ref={ref => { this.idleTimer = ref }}
	        element={document}
	        onIdle={this.onIdle}
	        debounce={250}
	        timeout={1000 * 30 * 60} 
	      />
        <ToastProvider placement="bottom-center" components={{ Toast: Toast }}>
  	      <Provider store={store}>
  	        <Router>
  	        	<ThemeProvider theme={theme}>
  	          	<Home />
  	          </ThemeProvider>
  	        </Router>
  	      </Provider>
        </ToastProvider>
      </Fragment>
    );
  }
}

export default App;
