let azure = require('azure-storage');
let {AZURE_STORAGE_KEY} = require('./constants');
let blobService = azure.createBlobService("utilicastclientpictures", AZURE_STORAGE_KEY);

export const checkBlob = (container, name, callback) => {
	blobService.doesBlobExist(container, name, function(error, result) {
	  if (!error) {
	    if (result.exists) {
	      callback(true);
	    } else {
	      callback(false);
	    }
	  } else {
	  	callback(false);
	  }
	});
}