import React, {Component, Fragment} from 'react';
import empty from 'is-empty';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import { Checkbox } from '@material-ui/core';

import {db_get_project_revenue} from '../../store/actions/peripherals/charts';
import {area_options} from '../../utils/options';

import Loading from '../../components/Graphics/Loading';
 
class MultiSeriesBar extends Component {	
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			areas: area_options.map(a => a.value),
			loaded: false
		}
	}

	getMin = (arr, prop) => {
    var min;
    for (var i=0 ; i<arr.length ; i++) {
      if (min == null || parseInt(arr[i][prop]) < parseInt(min[prop])) min = arr[i];
    }
    return min;
	}

	getMax = (arr, prop) => {
    var max;
    for (var i=0 ; i<arr.length ; i++) {
      if (max == null || parseInt(arr[i][prop]) > parseInt(max[prop])) max = arr[i];
    }
    return max;
	}

	async componentDidMount() {
		const resp = await db_get_project_revenue({PRACTICE_AREAS: area_options.map(a => a.value)});
		this.setState({
			...this.state,
			data: resp.data.map(item => ({revenue: empty(item) ? 0 : item[0].revenue, candidate: item.length < 2 ? 0 : item[1].adjusted_revenue, month: empty(item) ? '' : item[0].month})),
			loaded: true
		});
	}

	onCheckChange = async area => {
		const {areas} = this.state;
		const newAreas = areas.includes(area) ? areas.filter(a => a !== area) : [...areas, area];
		this.setState({...this.state, loaded: false});
		const resp = await db_get_project_revenue({PRACTICE_AREAS: newAreas});
		this.setState({
			...this.state,
			data: resp.data.map(item => ({revenue: empty(item) ? 0 : item[0].revenue, candidate: item.length < 2 ? 0 : item[1].adjusted_revenue, month: empty(item) ? '' : item[0].month})),
			areas: newAreas,
			loaded: true
		});
	}

	onAll = async () => {
		this.setState({...this.state, loaded: false});
		const resp = await db_get_project_revenue({PRACTICE_AREAS: area_options.map(a => a.value)});
		this.setState({
			...this.state,
			data: resp.data.map(item => ({revenue: empty(item) ? 0 : item[0].revenue, candidate: item.length < 2 ? 0 : item[1].adjusted_revenue, month: empty(item) ? '' : item[0].month})),
			areas: area_options.map(a => a.value),
			loaded: true
		});
	}

	onNone = async () => {
		this.setState({...this.state, loaded: false});
		const resp = await db_get_project_revenue({PRACTICE_AREAS: []});
		this.setState({
			...this.state,
			data: resp.data.map(item => ({revenue: empty(item) ? 0 : item[0].revenue, candidate: item.length < 2 ? 0 : item[1].adjusted_revenue, month: empty(item) ? '' : item[0].month})),
			areas: [],
			loaded: true
		});
	}

	render() {
		const {loaded, data, areas} = this.state;
		
		return (
			<div style={{backgroundColor: "white", padding: "1rem"}}>
				{!loaded && (<Loading />)}
				{loaded && (<Fragment>
						<h3 style={{width: "100%", textAlign: "center", paddingTop: "2rem"}}>Utilicast Identified Project Revenue</h3>
						<ResponsiveContainer width="100%" aspect={2}>
							<BarChart
				        data={data}
				        margin={{
				          top: 20, right: 30, left: 20, bottom: 20,
				        }}
				      >
				        <CartesianGrid strokeDasharray="3 3" />
				        <XAxis dataKey="month" />
				        <YAxis type="number" domain={[0, ((this.getMax(data, 'revenue').revenue + this.getMax(data, 'revenue').candidate + 300000) / 100000).toFixed(0) * 100000]} tickFormatter={(value) => "$" + value.toLocaleString()} allowDataOverflow/>
				        <Tooltip formatter={(value) => "$" + value.toLocaleString()}/>
				        <Legend />
				        <Bar dataKey="revenue" name="Active Projects" stackId="a" fill="#01579B" />
				        <Bar dataKey="candidate" name="Candidate Projects" stackId="a" fill="#F6B924" />
				      </BarChart>
			      </ResponsiveContainer>
			      <div style={{display: "flex", alignItems: "center", flexWrap: "wrap", backgroundColor: "white", marginTop: -40, marginLeft: -10, marginRight: -10}}>
			      	<div onClick={this.onAll} style={{display: "flex", alignItems: "center", cursor: "pointer"}}>
		      			<Checkbox color="secondary" checked={areas.length === area_options.length}/>
		      			<span style={{fontSize: "1rem"}}>All</span>
		      		</div>
		      		<div onClick={this.onNone} style={{display: "flex", alignItems: "center", cursor: "pointer"}}>
		      			<Checkbox color="secondary" checked={areas.length === 0}/>
		      			<span style={{fontSize: "1rem"}}>None</span>
		      		</div>
			      	{area_options.map((area, i) => (
			      		<div onClick={() => this.onCheckChange(area.value)} key={i} style={{display: "flex", alignItems: "center", cursor: "pointer"}}>
			      			<Checkbox color="primary" checked={areas.includes(area.value)}/>
			      			<span style={{fontSize: "1rem"}}>{area.value}</span>
			      		</div>
			      	))}
			      </div>
		      </Fragment>
				)}
			</div>
		);
	}
}

export default MultiSeriesBar;



