export default (data, callback) => {

  var resultMain = [];
  var result = {};
  function recurse(cur, prop){
      if (Object(cur) !== cur){
          result[prop] = cur;
      }else if(Array.isArray(cur)){
           for(var i=0, l=cur.length; i<l; i++)
               recurse(cur[i], prop);
          if(l === 0)
              result[prop] = [];
      }else{
          var isEmpty = true;
          for(var p in cur){
              isEmpty = false;
              recurse(cur[p], prop ? prop+"-"+p : p);
          }
          if(isEmpty && prop)
              result[prop] = {};
      }
  }
  for(var i=0; i<data.length; i++){
      result = {};
      recurse(data[i], "");
      resultMain[i] = result;
  }
  callback(resultMain);
  
}