import React, {Component} from 'react';

class ContractError extends Component {
	
	render() {
		return (
			<div className="col-xs-12 warning" style={{display: this.props.show ? '' : 'none'}}>
				<p>All the fields besides the notes field must be filled in order to draft a contract. Please save the record with all the fields filled before going to draft a contract.</p>
			</div>
		);
	}
}

export default ContractError;