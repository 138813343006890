import React, {Component, Fragment} from 'react';
import moment from 'moment';
import empty from 'is-empty';

import {Card, Grid} from "@material-ui/core";
import { withWidth } from '@material-ui/core';

import {stringifyNumber} from '../../utils/stringifyNumber';

import expenses from '../../images/expenses.png';

import DownloadBtn from '../Buttons/DownloadBtn';
import DownloadAmendmentPopup from '../Popup/DownloadAmendmentPopup';
import ModalLoading from '../Graphics/ModalLoading';


class ProjectCard extends Component {
	constructor(props){
    super(props);
    this.state = {
    	clicked: false,
    	loaded: false,
    	loading: false,
    	downloadPopup: false,
    	amendmentOptions: []
    };
    this.lastSunday = this.lastSunday.bind(this);
  }

  async componentDidMount() {
  	this.setState({
  		...this.state, 
  		amendmentOptions: empty(this.props.project.AMENDMENTS) ? [] : this.props.project.AMENDMENTS.map((amendment, i) => ({value: amendment.DOCUSIGN_ID, label: stringifyNumber(i + 1) + " Amendment"})),
  		loaded: true
  	});
  }

  lastSunday = date => {
    let lastDay = moment(date).endOf('month');
    let sub = 0;
    if (lastDay.day() === 7)
     	sub = lastDay.day() - 7;
    else
    	sub = lastDay.day();
    return lastDay.subtract(sub, 'days');
  }

  statusStyles = status => {
  	switch(status) {
  		case "Completed":
  			return "stat-completed project-status";
			case "Not Started":
				return "stat-not-started project-status";
			case "Pending":
				return "stat-pending project-status";
			case "In-Progress":
				return "stat-in-progress project-status";
			case "Ongoing":
				return "stat-in-progress project-status";
			case "Reporting":
        return "stat-candidate project-status";
			default:
				return "";
  	}
  }

  setStatus = (start, end, sow) => {
  	const now = moment();
  	if (!sow) {
  		return "Pending"
  	} else if (now > start && empty(end)) {
      return "Ongoing";
    } else if (now > end && (now < this.lastSunday(end) || end > this.lastSunday(end))) {
      return "Reporting";
    } else if (now < start) {
  		return "Not Started";
  	} else if (now > start && now < end) {
  		return "In-Progress";
  	} else if (now > end) {
  		return "Completed";
  	}
  }

	render() {
		const {project, width} = this.props;
		const {loading} = this.state;

		const status = this.setStatus(moment.utc(project.START_DATE), empty(project.END_DATE) ? null : moment.utc(project.END_DATE), project.SOW);
		const statusStyle = this.statusStyles(status);
		const client = project.CLIENT;
		const am = project.ACCOUNT_MANAGER;
		const cproject = project.PROJECT;
		const pm = project.PROJECT_MANAGER;
		const displayAmendment = !empty(project.AMENDMENTS);
		const displayContract = empty(project.CONTRACT) ? false : !empty(project.CONTRACT.DOCUSIGN_ID);
		
		return (
			<Fragment>
				{loading && (<ModalLoading />)}
				{this.state.downloadPopup && (<DownloadAmendmentPopup text={"Choose Amendment"} onHide={() => this.setState({...this.state, downloadPopup: false})} onDownload={this.props.get_contract} options={this.state.amendmentOptions} setState={(body) => this.setState({...this.state, ...body})}/>)}
				{this.state.loaded && (
					<Card style={{maxWidth: "30rem", height: "37rem", width: width === "xs" ? "calc(100% - 2rem)" : "25rem", margin: width === "xs" ? "1rem auto" : "1rem"}}>
						<Grid container item justify="space-between" direction="column" style={{height: "100%", width: "100%"}}>
							<Grid container item justify="space-between" style={{marginTop: "1rem"}}>
								<div className="client-logo"><img className="client-logo-src" src={`https://utilicastclientpictures.blob.core.windows.net/${client.ID}client/profile${this.props.user.sas}`} alt="CLIENT"/></div>
								<div className="client-name"><span className="client-name-text">{client.NAME} ({client.ABBREV})</span></div>
							</Grid>
							<div className="assignment-header">
								<div className="title-client-container">
									<h1 className="assignment-title">{cproject.NAME}</h1>
									<span className="manager-name-text">{client.ABBREV} Manager: {am.first_name} {am.last_name}</span>
									<span className="manager-name-text">Project Manager: {pm.first_name} {pm.last_name}</span>
									<p className="project-range">{empty(project.START_DATE) ? '' : moment.utc(project.START_DATE).format("MMM D, YYYY")} - {empty(project.END_DATE) ? '' : moment.utc(project.END_DATE).format("MMM D, YYYY")}</p>
									<p className={statusStyle}>{status}</p>
								</div>
							</div>
							<div className="assignment-details">
								<div className="hour-rate-description">
									<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.0" x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 100 100" xmlSpace="preserve" className="hour-rate-icon"><path d="M48.333,41.667V23.333h-6.666V45c0,1.843,1.49,3.333,3.333,3.333h18.333v-6.666H48.333z"/><circle cx="45" cy="63.333" r="3.333"/><circle cx="26.667" cy="45" r="3.333"/><path d="M80,62.5h-6.667C71.491,62.5,70,61.009,70,59.167h-0.007L70,59.163c0-1.842,1.491-3.33,3.333-3.33v-0.006v0.006H80  c1.843,0,3.33,1.491,3.33,3.334H90c0-5.521-4.482-10-10-10.004v-7.496h-6.667v7.5v-0.004c-5.521,0-10,4.477-10,10v0.004  c0,5.521,4.479,10,10,10H80c1.843,0,3.33,1.49,3.33,3.333h-0.003c0,1.843-1.491,3.333-3.334,3.333H73.33  c-1.839,0-3.33-1.49-3.33-3.333h-6.667c0,5.521,4.479,10,10,10V90H80v-7.5c5.518-0.007,9.997-4.479,9.997-10H90  C90,66.979,85.518,62.5,80,62.5z"/><path d="M71.175,34.16C66.917,23.894,56.807,16.667,45,16.667c-15.651,0-28.333,12.682-28.333,28.333  c0,15.647,12.682,28.333,28.333,28.333c3.841,0,7.5-0.771,10.843-2.158l2.553,6.159C54.268,79.046,49.746,80,45,80  c-19.329,0-35-15.671-35-35s15.671-35,35-35c14.583,0,27.073,8.926,32.334,21.608L71.175,34.16z"/></svg>
									<p className="hour-line"><span className="hour-rate">{empty(project.HOURS) ? '0' : project.HOURS}</span> hours at <span className="hour-rate">{empty(project.PAY) ? '' : `$${Number(project.PAY).toFixed(2)}`}</span> per hour</p>
								</div>
								<div className="hour-rate-description">
									<img className="hour-rate-icon" src={expenses} alt=""/>
									<p className="hour-line">{empty(project.EXPENSES_AMOUNT) ? '' : `Total expense allowance of $${Number(project.EXPENSES_AMOUNT).toFixed(2)}`}</p>
								</div>
							</div>
							<div className="assignment-btn-container">
								<div className="btn-group">
									{displayAmendment && (<DownloadBtn text="Amendments" onClick={() => this.setState({...this.state, downloadPopup: true})} color="#F6B924"/>)}
									{displayContract && (<DownloadBtn text="SOW" color="#01579B" onClick={() => this.props.get_contract(this.props.project.CONTRACT.DOCUSIGN_ID)} style={{marginLeft: "1.5rem"}}/>)}
									{!displayContract && !displayAmendment && (<p className="project-contract-none">You have no contract on file.</p>)}
								</div>
							</div>
						</Grid>
					</Card>)}
			</Fragment>
		);
	}
}

export default withWidth()(ProjectCard);

