import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';

import {removeError} from '../../store/actions/utils/errors';
import {db_get_projects, db_get_am_projects, db_get_consultant_projects, db_get_utilicast_projects} from '../../store/actions/tables/projects';
import {db_get_am_client_dropdown, db_get_client_dropdown} from '../../store/actions/tables/clients';
import {toggle, hide_panel} from '../../store/actions/utils/panel';
import {set_db_data} from '../../store/actions/utils/db_data';
import {get_contract} from '../../store/actions/peripherals/documents';
import {addCancel} from '../../store/actions/utils/addbtn';
import {set_page} from '../../store/actions/utils/page';

import {sortList} from '../../utils/sortList';
import {searchList} from '../../utils/searchList';
import {filter} from '../../utils/filters';

import ProjectRow from '../../components/Rows/ProjectRow';
import ProjectCard from '../../components/Rows/ProjectCard';
import NoProject from '../../components/Rows/NoProject';
import ProjectDetail from './ProjectDetail';
import Loading from '../../components/Graphics/Loading';

class ProjectData extends Component {
	constructor(props){
		super(props);
		this.state = {
			loaded: false,
			sort: 'CLIENT_ID',
			id: null
		}
	}

	mount = async () => {
		await this.props.hide_panel();

		switch(this.props.role) {
		  case "Admin":
		    this.props.set_page("admin-assignments");
		    this.props.set_db_data({...this.props.db_data, title: "Admin Assignments", sort_btn: true, add_btn: true, search_btn: true, export_btn: true, filter_btn: true});
		    await this.props.db_get_projects();
		    break;
		  case "Client Manager":
		    this.props.set_page("am-assignments");
		    this.props.set_db_data({...this.props.db_data, title: "My Assignments", sort_btn: true, add_btn: true, search_btn: true, export_btn: true, filter_btn: true});
		    await this.props.db_get_am_projects(this.props.user.id);
		    break;
		  case "Consultant":
		    this.props.set_page("consultant-assignments");
		    this.props.set_db_data({...this.props.db_data, title: "My Assignments", sort_btn: false, add_btn: false, search_btn: false, export_btn: true, filter_btn: false});
		    await this.props.db_get_consultant_projects(this.props.user.id);
		    break;
		  case "Utilicast":
		    this.props.set_page("utilicast-assignments");
		    this.props.set_db_data({...this.props.db_data, title: "Internal Assignments", sort_btn: true, add_btn: true, search_btn: true, export_btn: true, filter_btn: true});
		    await this.props.db_get_utilicast_projects();
		    break;
		  case "Reports":
		    this.props.set_page("company-assignments");
		    this.props.set_db_data({...this.props.db_data, title: "Company Assignments", sort_btn: true, add_btn: false, search_btn: true, export_btn: true, filter_btn: true});
		    await this.props.db_get_projects();
		    break;
		  default:
		    // code block
		}

		this.props.role === "Client Manager" ? await this.props.db_get_am_client_dropdown(this.props.user.id) : await this.props.db_get_client_dropdown();
		this.setState({loaded: true});
	}

	async componentDidMount() {
		this.mount();
	}

	async componentDidUpdate(prevProps, prevState) {
		if(prevProps.role !== this.props.role) {
			this.mount();
		}
	}

	async handleRowClicked(data) {

		await this.setState({
			...this.state,
			id: this.props.role === "Reports" ? null : data.ID === this.state.id ? null : data.ID
		});

		this.props.hide_panel();
	}

	handleChange = e => {
		this.setState({
			...this.state,
			[e.target.name]: e.target.value
		});
	}

	handleSortChange = e => {
		this.setState({
			...this.state,
			'sort': e.target.id
		});
	}

	render() {
		const {errors, projects, currentProject, user, role, db_data} = this.props;
		const {id} = this.state;

		let list = sortList(this.state.sort, db_data.asc, searchList(db_data.search, filter(this.props.filters, projects, "CONSULTANT_ID")));
		let projectList = list.map(project => 
			<Fragment key={project.ID}>
				{role !== "Consultant" && (<ProjectRow
					project={project}
					handleRowClicked={this.handleRowClicked.bind(this, project)}
					id={id}
					role={role}
					user={user}
				/>)}
				{role === "Consultant" && (
					<ProjectCard
						project={project}
						currentProject={currentProject}
						get_contract={this.props.get_contract}
						role={role}
						user={user}
					/>
				)}
				{id === project.ID && (
					<tr>
						<td colSpan="10" className="editForm">
							<ProjectDetail 
								user={user} 
								role={role} 
								currentProject={project} 
								onHide={() => this.setState({...this.state, id: null})} 
								edit
							/>
						</td>
					</tr>
				)}
			</Fragment>
		);

		return (
			<Fragment>
				{!this.state.loaded && (
					<Loading />
				)}
				{errors.message && (
					<div className="alert alert-danger">
						{errors.message}
					</div>
				)}
				{this.state.loaded && role !== "Consultant" && (
					<div className="container1">
						{this.props.panel && (
							<div className="container-add">
								<ProjectDetail 
									user={this.props.user} 
									role={this.props.role} 
									handleCancel={this.props.addCancel}
								/>
							</div>
						)}
						<div className="project-table">
							<table className="table">
								<thead>
									<tr className="table-head">
										<th scope="col" className="table-title leftrow"><div onClick={this.handleSortChange} id="CLIENT_ID" className={this.state.sort === "CLIENT_ID" ? "sortSelect" : "sortTitle"}>Client</div></th>
										<th scope="col" className="table-title"><div onClick={this.handleSortChange} id="PROJECT_ID" className={this.state.sort === "PROJECT_ID" ? "sortSelect" : "sortTitle"}>Project Name</div></th>
										<th scope="col" className="table-title delete"><div onClick={this.handleSortChange} id="CONSULTANT_ID" className={this.state.sort === "CONSULTANT_ID" ? "sortSelect" : "sortTitle"}>Consultant</div></th>
										<th scope="col" className="table-title"><div onClick={this.handleSortChange} id="START_DATE" className={this.state.sort === "START_DATE" ? "sortSelect" : "sortTitle"}>Start Date</div></th>
										<th scope="col" className="table-title"><div onClick={this.handleSortChange} id="END_DATE" className={this.state.sort === "END_DATE" ? "sortSelect" : "sortTitle"}>End Date</div></th>
										<th scope="col" className="table-title">Hours</th>
										{role === "Reports" && (<th scope="col" className="table-title">Utilization</th>)}
										{role !== "Reports" && (<Fragment><th scope="col" className="table-title">Bill</th>
										<th scope="col" className="table-title delete">Pay</th>
										<th scope="col" className="table-title delete"><div onClick={this.handleSortChange} id="SOW" className={this.state.sort === "SOW" ? "sortSelect" : "sortTitle"}>SOW?</div></th></Fragment>)}
										<th scope="col" className="table-title status">Status</th>
									</tr>
								</thead>
								<tbody className="table-body">
									{projectList}
								</tbody>
							</table>
						</div>
						{role === "Consultant" && (<Fragment>{projectList}</Fragment>)}
					</div>
				)}
				{this.state.loaded && role === "Consultant" && (
					<div className="container2">
						{projectList}
						{projectList.length === 0 && (
							<NoProject user={this.props.user} />
						)}
					</div>
				)}
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		projects: state.projects,
		errors: state.errors,
		panel: state.panel,
		db_data: state.db_data,
		user: state.user
	};
}

export default connect(mapStateToProps, {
		db_get_projects,
		db_get_am_projects,
		db_get_consultant_projects, 
		db_get_utilicast_projects,
		db_get_am_client_dropdown, 
		db_get_client_dropdown,
		removeError,
		toggle,
		hide_panel,
		set_db_data,
		get_contract,
		addCancel,
		set_page
	})(ProjectData);






