import {GET_SPONSORS, UPDATE_SPONSOR, REMOVE_SPONSOR, ADD_SPONSOR} from '../../actionTypes';

const sponsors = (state = [], action) => {
	switch(action.type) {
		case GET_SPONSORS:
			return [...action.payload];
		case UPDATE_SPONSOR:
			return state.map(sponsor => {
				if(action.payload.ID === sponsor.ID) {
					return action.payload;
				} else {
					return sponsor;
				}
			});
		case ADD_SPONSOR:
			return [...state, action.payload];
		case REMOVE_SPONSOR:
			return state.filter(sponsor => {
				return sponsor.ID !== action.payload.ID;
			});
		default:
			return state;
	}
}

export default sponsors;